
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: sessionStorage.getItem("userInfo") || {},
    token: sessionStorage.getItem("token") || {},
    Refresh: true,

    contentlist: [],//页面装修成什么样子数据源
    renovationitem: {},//装修页面缓存
    quality: {},//装修页面属性参数\
    foundation:{},//页面基础数据
  },
  mutations: {
     //控制界面刷新问题
     setRefresh(state, data) {
      state.Refresh = data
    },
       //装修页面布局缓存问题
       addcontentlist(state, item) {
        state.contentlist = JSON.parse(JSON.stringify(item))
      },
      //装修页面缓存的数据
      addrenovationitem(state, item) {
        state.renovationitem = JSON.parse(JSON.stringify(item))
      },
      //当前选中属性数据
      addquality(state, item) {
        state.quality = JSON.parse(JSON.stringify(item))
      },
      //页面基础数据
      foundation(state, item) {
        state.foundation = JSON.parse(JSON.stringify(item))
      }
  },
  actions: {
  },
  modules: {
  }
})
