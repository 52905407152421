<template>
  <div class="moduleView">
    <div class="moduleView-right">
      <draggable
      class="listcnt"
        v-model="modulelist"
        :options="{
          group: {
            name: 'itxst', // 可拖拽列组，相同表名可相互推拽
            pull: 'clone', // 拖拽模板物料，复制到目标列表
            put:false
          },
          sort: false, // 是否可推拽排序
        }"
        :clone="handleClone"
        animation="300"
      >
          <div
            class="itemcss"
            v-for="item in modulelist"
            :key="item.id"
            :data-id="item.id"
          >
            {{ item.title }}
          </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import draggable from "vuedraggable";
import moduleJson from "./module.json";
import _ from 'lodash' //导入loadsh插件
export default {
  name: "moduleView",
  components: {
    draggable,

  },
  props: {},
  data() {
    return {
      moduleJson,
      modulelist: [],
      tabmoduleindex: "foundation",
  
    };
  },
  computed: {
    sortmodule() {
      return this.modulelist;
    },
  },
  watch: {},
  created() {
    this.modulelist = this.moduleJson[this.tabmoduleindex].list;
  },
  mounted() {
    // this.dragTable();
  },
  methods: {
    //克隆的方法
    handleClone(obj) {
      console.log(obj,'克隆前置')

      const newObj = Object.assign(_.cloneDeep(obj), {
        fieldId: `${obj.name}_${new Date().getTime()}`,
      });
      return newObj;
    },
    //组件主类别切换
    tabswitcht(e) {
      this.tabmoduleindex = e.name;
      this.modulelist = this.moduleJson[this.tabmoduleindex].list;
    },
    dragTable() {
      this.modulelist.forEach((e) => {
        let el = document.getElementById(`moduleView${e.id}`);
        Sortable.create(el, {
          group: {
            name: "shared",
            pull: "clone", // To clone: set pull to 'clone'
            put: false,
          },

          sort: false, // boolean 定义是否列表单元是否可以在列表容器内进行拖拽排序
          // clone一个元素的时候触发
          onClone: function (/**Event*/ evt) {
            // var origEl = evt.item;
            // var cloneEl = evt.clone;
            // console.log(origEl,cloneEl,evt,'复制了那些东西过去')
          },
        });
      });
    },
  },
};
</script>

<style  lang="scss">
.moduleView {
  width: 100%;
  height: 100%;
  display: flex;

  .moduleView-right {
    background: #ffffff;
    border-radius: 6px;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding: 17px 0 0 13px;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .listcnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px 0 0;
      box-sizing: border-box;
      .itemcss {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e6f7ff;
        border-radius: 4px;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 15px;
        border: 1px solid #e6f7ff;
      }
      .itemcss:hover {
        border: 1px dashed #1e87f0;
      }
    }
  }
  /* 隐藏滚动条 */
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
