<template>
  <div class="Backgroundimage">
    <img :src="quality.bg_url ? quality.bg_url : main_url" alt="" />
  </div>
</template>
    
    <script>
export default {
  name: "Backgroundimage",
  components: {},
  props: {
    // 表格展示
    quality: {
      type: [Object, Array],
      default: {
        head_url: "", //顶部背景图片
        main_url: "", //按钮背景图
        more_url: "", //查看更多按钮
        region_name: "", //标题
        id: "",
        bg_url: "",
      },
    },
  },
  data() {
    return {
      main_url:
        "https://file-purchase.hbxinfadi.com/general/public/addPicture.jpg",
    };
  },
  computed: {},
  watch: {
    quality: {
      handler: function (newValue, oldValue) {
        console.log("quality参数", newValue);
        // this.main_url=newValue.bg_url;
      },
      deep: true,
    },
  },
  created() {
    // this.main_url=this.quality.bg_url?this.quality.bg_url:'https://file-purchase.hbxinfadi.com/general/public/addPicture.jpg';
    // this.tableDatacolumn = this.quality.tableDatacolumn;
  },
  mounted() {},
  methods: {},
};
</script>
    
    <style lang="scss">
.Backgroundimage {
  width: 100%;
  height: 286px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
    