<template>
  <div class="PageConfiguration">
    <el-form
      :model="childForm"
      ref="childForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="选择集配中心:" prop="logistic_business_id">
        <el-select
          v-model="childForm.logistic_business_id"
          filterable
          placeholder="请选择集配中心"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称:" prop="name">
        <el-input
          v-model="childForm.name"
          placeholder="请输入模板名称"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="整体背景色:" prop="global_color">
        <el-color-picker v-model="childForm.global_color"></el-color-picker>
      </el-form-item>
      <el-form-item label="标题背景图：" prop="bg_url">
        <b-file-upload
          key="bg_url"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="detailPicList"
        ></b-file-upload>
      </el-form-item>
      <el-form-item label="模板主图：" prop="main_image">
        <b-file-upload
          key="main_image"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="main_image_list"
        ></b-file-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
  
  <script>
import BFileUpload from "@/components/business/BFileUpload/index.vue";
export default {
  name: "PageConfiguration",
  components: { BFileUpload },
  props: {
    // 字符串
    obj_item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      quality: {},
      childForm: {
        main_image: "",
        bg_url: "", //顶部背景图片
        global_color: "", //
        name: "", //
        logistic_business_id: "",
      },
      detailPicList: [], // 标题背景图
      main_image_list: [], // 模板主图
      options: [],
    };
  },
  computed: {
    foundationitem() {
      return this.$store.state.foundation;
    },
  },
  watch: {
    /**
     * 图片发生改变
     */
    main_image_list() {
      const res = this.main_image_list?.map((item) => item.url).join(",");
      this.childForm.main_image = res;
    },
    /**
     * 图片发生改变
     */
    detailPicList() {
      const res = this.detailPicList?.map((item) => item.url).join(",");
      this.childForm.bg_url = res;
    },
    childForm: {
      handler: function (newValue, oldValue) {
        console.log("childForm  页面配置参数改动", newValue);
        this.$store.commit("foundation", this.childForm);
      },
      deep: true,
    },
    obj_item: {
      handler: function (newValue, oldValue) {
        console.log("obj_item参数", newValue);
        this.childForm = {
          main_image: newValue.main_image,
          bg_url: newValue.bg_url, //顶部背景图片
          global_color: newValue.global_color, //
          name: newValue.name, //
          logistic_business_id: newValue.logistic_business_id,
        };
        this.main_image_list = newValue.main_image
          ? [{ url: newValue.main_image, name: "food.jpeg" }]
          : [];
        this.detailPicList = newValue.bg_url
          ? [{ url: newValue.bg_url, name: "food.jpeg" }]
          : [];
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.childForm = {
      main_image: this.obj_item.main_image,
      bg_url: this.obj_item.bg_url, //顶部背景图片
      global_color: this.obj_item.global_color, //
      name: this.obj_item.name, //
      logistic_business_id: this.obj_item.logistic_business_id,
    };
    this.main_image_list = this.obj_item.main_image
      ? [{ url: this.obj_item.main_image, name: "food.jpeg" }]
      : [];
    this.detailPicList = this.obj_item.bg_url
      ? [{ url: this.obj_item.bg_url, name: "food.jpeg" }]
      : [];
    this.hqlist();
  },
  methods: {
    hqlist() {
      this.$api.decoration.collective().then((res) => {
        this.options = res.data;
      });
    },
    // this.$emit("quality", {
    //   btnlist: arr,
    //   formList: arr1,
    // });
  },
};
</script>
  
  <style  lang="scss">
.PageConfiguration {
}
</style>
  