<template>
  <div class="activitycontent"  :style="[{ background }]">
    <div class="navbar">
      <navbar></navbar>
    </div>
    <div style="padding-top: 60px;">
      <div
      class="componentborder"
      v-for="(item, index) in contentlist"
      :key="item.fieldId"
    >
      <component
        :is="item.name"
        v-bind="item"
        :quality="item.configuration.quality"
      ></component>
    </div>
    </div>
 
  </div>
</template>

<script>
import Sortable from "sortablejs";
import draggable from "vuedraggable";
import template1 from "@/components/renovation/template1.vue";
import template2 from "@/components/renovation/template2.vue";
import navbar from "@/components/renovation/navbar.vue";
import Backgroundimage from "@/components/renovation/Backgroundimage.vue";
export default {
  name: "activitycontent",
  components: {
    draggable,
    template1,
    template2,
    navbar,
    Backgroundimage,
  },
  props: {
    receivedList: {
      type: Array,
      default: [],
    },
    obj_item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      contentlist: [],
      selectedId: null,

      quality: {},
      background: "#fff",
      url: "",
    };
  },

  computed: {
    foundationitem() {
      return this.$store.state.foundation;
    },
    sortcontent() {
      return this.contentlist;
    },
    qualityitem() {
      return this.$store.state.quality;
    },
  },
  watch: {
    receivedList(newV, oldV) {
      this.contentlist = newV;
    },
    obj_item(newV, oldV) {
      this.background = newV.global_color ? newV.global_color : "#fff";
      console.log(newV, "全局参数变化global_color");
      this.$store.commit("foundation", {
        main_image: newV.main_image,
        bg_url: newV.bg_url, //顶部背景图片
        global_color: newV.global_color, //
        name: newV.name, //
      });
    },
    foundationitem(newV, oldV) {
      this.background = newV.global_color ? newV.global_color : "#fff";
    },
    sortcontent(newV, oldV) {
      console.log(newV, "新的数据过来了");
      this.$store.commit("addcontentlist", newV);
    },
    qualityitem(newV, oldV) {
      console.log(newV, "属性参数变化中间页面");
      this.quality = newV;
      this.setInof();
    },
  },
  created() {
    this.contentlist = this.receivedList;
  },
  mounted() {},
  methods: {
    setInof() {
      this.contentlist.forEach((el) => {
        if (el.fieldId == this.selectedId) {
          el.configuration.quality = this.quality;
        }
      });
      // console.log(this.contentlist,'属性数据变化中间组件变化内容')
      this.$store.commit("addcontentlist", this.contentlist);
    },

  },
};
</script>

<style  lang="scss">
.activitycontent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 20px;
  padding-top: 0;
  // margin-top: 20px;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  width: 458px;
  .cnt {
    padding-top: 80px;
    width: 450px;
  }
  .navbar {
    position: fixed;
    width: 450px;
    z-index: 2;
  }
  .hg60{
    height: 60px;
    width: 100%;
  }
  .componentborder {
      // border: 1px dashed #000;
      
      position: relative;
      // margin: 2px 0;
      .componentbtn {
        position: absolute;
        top: 0;
        right: -100px;
        width: 100px;
        background: $theme-color;
      }
    }
    .componentborderactive {
      border: 1px solid $theme-color;
    }
}
</style>
