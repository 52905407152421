<template>
  <div class="orderlist bj order-container">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from1.fullname"
            placeholder="请输入客户名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs" v-if="role == 'tdc'">
          <el-select
            v-model="from1.delivery_id"
            clearable
            placeholder="请选择自提点"
          >
            <el-option
              :key="item.id"
              v-for="item in arrList"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.order_no"
            placeholder="请输入订单编号"
            clearable
          ></el-input>
        </div>
        <div class="mr10">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            range-separator="—"
            start-placeholder="支付时间起"
            end-placeholder="支付时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable placeholder="请选择状态">
            <el-option label="全部订单状态" value=""></el-option>
            <el-option label="待付款" value="1"></el-option>
            <el-option label="已付款" value="2"></el-option>
            <el-option label="已取消" value="3"></el-option>
            <el-option label="已生成供货单" value="4"></el-option>
            <el-option label="已完成" value="5"></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="订单编号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="订单创建时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="客户名称">
          <template slot-scope="scope" v-if="scope.row.member">
            {{ $empty.empty(scope.row.member.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="支付时间">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.pay_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待付款</span>
            <span v-else-if="scope.row.state == 2">已付款</span>
            <span v-else-if="scope.row.state == 3">已取消</span>
            <span v-else-if="scope.row.state == 4">已生成供货单</span>
            <span v-else-if="scope.row.state == 5">已完成</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="订单金额">
          <template slot-scope="scope">
            {{ $empty.empty(tool.format_money(scope.row.order_amt)) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="城市仓">
          <template
            slot-scope="scope"
            v-if="
              scope.row.store_delivery && scope.row.store_delivery.logistics
            "
          >
            {{ $empty.empty(scope.row.store_delivery.logistics.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="自提点">
          <template slot-scope="scope" v-if="scope.row.store_delivery">
            {{ $empty.empty(scope.row.store_delivery.name) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'ReceivingOrderDetail',
                query: { id: scope.row.id },
              }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import { BASE } from "@/api";
import url from "url";
export default {
  name: "orderlist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      pay_at: "",
      from: {
        delivery_id: "",
        fullname: "",
        order_no: "",
        pay_start: "",
        pay_end: "",
        state: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        delivery_id: "",
        fullname: "",
        order_no: "",
        pay_start: "",
        pay_end: "",
        state: "",
        page: 1,
        pageSize: 10,
      },
      role: sessionStorage.getItem("manageIsdentity"),
      tableData: [],
      arrList: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      storelist: [],
      selflist: [],
      logistics_id: "",
      list: [],
      list1: [],
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.pay_at = [this.from.pay_start, this.from.pay_end];
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
    }
    if (this.role == "tdc") {
      this.from.delivery_id = Number(this.from.delivery_id)
        ? Number(this.from.delivery_id)
        : "";
      this.from1.delivery_id = Number(this.from1.delivery_id)
        ? Number(this.from1.delivery_id)
        : "";
      this.getDeliveryList();
    }
    this.hqlist();
  },
  methods: {
    getDeliveryList() {
      this.$api.delivery.deliverylist().then((res) => {
        console.log(res, "获取数据");
        this.arrList = res.data;
      });
    },
    exportHandle() {
      let tmpForm = this.tool.DeepCopy({}, this.from1);

      const query = { ...tmpForm, token: sessionStorage.getItem("token") };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });

      if (this.role == "tdc") {
        const path = BASE.PRO4 + "/order/list/export/new";
        window.open(path + params);
        // window.open(
        //   BASE.PRO4 +
        //     `/order/list/export?order_no=` +
        //     form.order_no +
        //     "&delivery_id=" +
        //     form.delivery_id +
        //     "&fullname=" +
        //     form.fullname +
        //     "&pay_end=" +
        //     form.pay_end +
        //     "&pay_start=" +
        //     form.pay_start +
        //     "&state=" +
        //     form.state +
        //     "&token=" +
        //     sessionStorage.getItem("token"),
        //   "_blank"
        // );
      } else {
        const path = BASE.PRO3 + "/delivery/order/list/export";
        window.open(path + params);
        // window.open(
        //   BASE.PRO3 +
        //     `/delivery/order/list/export?order_no=` +
        //     form.order_no +
        //     "&fullname=" +
        //     form.fullname +
        //     "&pay_end=" +
        //     form.pay_end +
        //     "&pay_start=" +
        //     form.pay_start +
        //     "&state=" +
        //     form.state +
        //     "&token=" +
        //     sessionStorage.getItem("token"),
        //   "_blank"
        // );
      }
    },
    search() {
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.tool.DeepCopy({}, this.from1);
      let form = this.tool.DeepCopy({}, this.from1);
      if (this.role == "delivery" && form.hasOwnProperty("delivery_id")) {
        delete form.delivery_id;
      }
      this.tool.getResult(form, window.location.href);
      this.hqlist();
    },
    Refresh() {
      this.pay_at = "";
      this.from = {
        delivery_id: "",
        fullname: "",
        order_no: "",
        pay_start: "",
        pay_end: "",
        state: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        delivery_id: "",
        fullname: "",
        order_no: "",
        pay_start: "",
        pay_end: "",
        state: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      let form = this.tool.DeepCopy({}, this.from1);
      if (this.role == "delivery" && form.hasOwnProperty("delivery_id")) {
        delete form.delivery_id;
      }
      this.tool.getResult(form, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      let form = this.tool.DeepCopy({}, this.from1);
      if (this.role == "delivery" && form.hasOwnProperty("delivery_id")) {
        delete form.delivery_id;
      }
      this.tool.getResult(form, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      let form = this.tool.DeepCopy({}, this.from1);
      if (this.role == "delivery" && form.hasOwnProperty("delivery_id")) {
        delete form.delivery_id;
      }
      this.tool.getResult(form, window.location.href);
      this.hqlist();
    },
    payChange(val) {
      console.log(val);
      if (val) {
        this.from1.pay_start = val[0];
        this.from1.pay_end = val[1];
      } else {
        this.from1.pay_start = "";
        this.from1.pay_end = "";
      }
    },
    hqlist() {
      this.loading = true;
      let form = this.tool.DeepCopy({}, this.from);
      if (this.role == "delivery" && form.hasOwnProperty("delivery_id")) {
        delete form.delivery_id;
      }
      this.$api.delivery.deliveryOrderlist(form).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.order-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .colors {
    color: #fa884c;
  }

  .times {
    margin-right: 8px;
  }

  // .el-picker-panel {
  //   left: 1245px !important;
  // }
}
</style>
