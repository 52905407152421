var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c(
                "el-form",
                {
                  ref: "platForm",
                  attrs: {
                    model: _vm.platForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "集配业务中心",
                        prop: "logistic_business_id",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "选择集配业务中心",
                          },
                          model: {
                            value: _vm.platForm.logistic_business_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.platForm,
                                "logistic_business_id",
                                $$v
                              )
                            },
                            expression: "platForm.logistic_business_id",
                          },
                        },
                        _vm._l(_vm.businessList, function (item) {
                          return _c("el-option", {
                            key: item.logisticBusinessId,
                            attrs: {
                              label: item.logisticBusinessName,
                              value: item.logisticBusinessId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分区", prop: "region_sort" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "选择分区" },
                          on: { change: _vm.changeRegion },
                          model: {
                            value: _vm.platForm.region_sort,
                            callback: function ($$v) {
                              _vm.$set(_vm.platForm, "region_sort", $$v)
                            },
                            expression: "platForm.region_sort",
                          },
                        },
                        _vm._l(_vm.partitionList, function (item) {
                          return _c("el-option", {
                            key: item.regionSort,
                            attrs: {
                              label: item.regionName,
                              value: item.regionSort,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标题", clearable: "" },
                            model: {
                              value: _vm.platForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.platForm, "title", $$v)
                              },
                              expression: "platForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分区图片", prop: "image" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex a-center" },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingImage,
                                  expression: "loadingImage",
                                },
                              ],
                              class: { hide: _vm.image.length == 1 },
                              attrs: {
                                "element-loading-text": "正在上传...",
                                action: "#",
                                "on-preview": _vm.handlePictureCardPreview,
                                "file-list": _vm.image,
                                "list-type": "picture-card",
                                "http-request": (file) => {
                                  return _vm.uploadFile(file, "image")
                                },
                                "before-remove": (file, fileList) => {
                                  return _vm.deleteFile(file, fileList, "image")
                                },
                                limit: 1,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "trigger" },
                                slot: "trigger",
                              }),
                            ]
                          ),
                          _c("span", { staticClass: "tipWrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.partitionList.find(
                                    (item) =>
                                      item.regionSort ==
                                      _vm.platForm.region_sort
                                  )?.description || ""
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "url_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "选择类型" },
                          on: { change: _vm.changeUrlType },
                          model: {
                            value: _vm.platForm.url_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.platForm, "url_type", $$v)
                            },
                            expression: "platForm.url_type",
                          },
                        },
                        _vm._l(_vm.jumpTypeList, function (item) {
                          return _c("el-option", {
                            key: item.urlType,
                            attrs: { label: item.urlName, value: item.urlType },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.platForm.url_type ==
                  _vm.jumpTypeList.find((item) => item.urlName == "自定义")
                    ?.urlType
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "banner图片", prop: "banner_url" } },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingBanner,
                                  expression: "loadingBanner",
                                },
                              ],
                              class: { hide: _vm.banner_url.length == 1 },
                              attrs: {
                                "element-loading-text": "正在上传...",
                                action: "#",
                                "on-preview": _vm.handlePictureCardPreview,
                                "file-list": _vm.banner_url,
                                "list-type": "picture-card",
                                "http-request": (file) => {
                                  return _vm.uploadFile(file, "banner_url")
                                },
                                "before-remove": (file, fileList) => {
                                  return _vm.deleteFile(
                                    file,
                                    fileList,
                                    "banner_url"
                                  )
                                },
                                limit: 1,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "trigger" },
                                slot: "trigger",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.platForm.url_type ==
                  _vm.jumpTypeList.find((item) => item.urlName == "长图")
                    ?.urlType
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "长图片", prop: "banner_url" } },
                        [
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingBanner,
                                  expression: "loadingBanner",
                                },
                              ],
                              class: { hide: _vm.banner_url.length == 1 },
                              attrs: {
                                "element-loading-text": "正在上传...",
                                action: "#",
                                "on-preview": _vm.handlePictureCardPreview,
                                "file-list": _vm.banner_url,
                                "list-type": "picture-card",
                                "http-request": (file) => {
                                  return _vm.uploadFile(file, "banner_url")
                                },
                                "before-remove": (file, fileList) => {
                                  return _vm.deleteFile(
                                    file,
                                    fileList,
                                    "banner_url"
                                  )
                                },
                                limit: 1,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "trigger" },
                                slot: "trigger",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "参数", prop: "url_param" } },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticStyle: { width: "200px" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入参数",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.platForm.url_param,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.platForm, "url_param", $$v)
                                    },
                                    expression: "platForm.url_param",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "tipWrap" }, [
                              _vm._v(
                                " 类型选择商品详情页,则参数填写需要跳转的货号;如果类型选择分类页,则填写对应的品类ID "
                              ),
                            ]),
                          ]),
                        ]
                      ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.platForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.platForm, "status", $$v)
                            },
                            expression: "platForm.status",
                          },
                        },
                        _vm._l(_vm.stateList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitFrom } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }