<template>
  <div class="entryWrap">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="formData.industry"
            clearable
            placeholder="选择从业经验"
          >
            <el-option
              v-for="item of industry"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.investment"
            clearable
            placeholder="选择投入预算"
          >
            <el-option
              v-for="item of investment"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.advantage"
            clearable
            placeholder="选择优势"
            multiple
          >
            <el-option
              v-for="item of advantage"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.education"
            clearable
            placeholder="选择文化程度"
          >
            <el-option
              v-for="item of education"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.logistic_business_id"
            clearable
            placeholder="选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.province_code"
            clearable
            filterable
            placeholder="所属地区-省"
          >
            <el-option
              v-for="item of province_list"
              :key="item.area_code"
              :label="item.name"
              :value="item.area_code"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.city_code"
            clearable
            filterable
            placeholder="所属地区-市"
          >
            <el-option
              v-for="item of city_list"
              :key="item.area_code"
              :label="item.name"
              :value="item.area_code"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.area_code"
            clearable
            filterable
            placeholder="所属地区-区"
          >
            <el-option
              v-for="item of district_list"
              :key="item.area_code"
              :label="item.name"
              :value="item.area_code"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.name"
            clearable
            placeholder="请输入联系人"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.mobile"
            clearable
            placeholder="请输入电话"
          >
          </el-input>
        </div>
        <div class="input-item-tow">
          <el-input
            v-model="formData.score_start"
            placeholder="得分始"
            clearable
          ></el-input>
          <span class="span">至</span>
          <el-input
            v-model="formData.score_end"
            placeholder="得分止"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.state"
            clearable
            placeholder="请选择状态"
          >
            <el-option
              :label="item.val"
              :value="item.index"
              v-for="item in typeList"
              :key="item.index"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            :disabled="!tableData.length || disabledExport"
            @click="exportHandle"
            icon="el-icon-download"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="name" align="center" label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="预留电话"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="province"
          align="center"
          label="所属地区"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ `${scope.row.province}${scope.row.city}${scope.row.area}` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="industry_arr"
          align="center"
          label="从业经验"
          min-width="150"
        >
          <template slot-scope="scope">
            {{
              scope.row.industry_arr.length > 0
                ? scope.row.industry_arr.map((item) => item.name).join(", ")
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="investment_arr"
          align="center"
          label="投入预算"
          min-width="150"
        >
          <template slot-scope="scope">
            {{
              scope.row.investment_arr && scope.row.investment_arr.name
                ? scope.row.investment_arr.name
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="education_arr"
          align="center"
          label="文化程度"
          min-width="150"
        >
          <template slot-scope="scope">
            {{
              scope.row.education_arr && scope.row.education_arr.name
                ? scope.row.education_arr.name
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="advantage_arr"
          align="center"
          label="优势"
          min-width="150"
        >
          <template slot-scope="scope">
            {{
              scope.row.advantage_arr.length > 0
                ? scope.row.advantage_arr.map((item) => item.name).join(", ")
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="img_url"
          align="center"
          label="资质信息"
          min-width="150"
        >
          <template slot-scope="scope">
            <div>
              <div>
                <el-button
                  type="text"
                  @click="showImg(scope.row.img_url.split(','))"
                  >查看</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          label="提交时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="score"
          align="center"
          label="得分"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          align="center"
          label="备注"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="audit_remark"
          align="center"
          label="审核备注"
          min-width="150"
        >
        </el-table-column>
        <el-table-column prop="state_line" align="center" label="状态">
        </el-table-column>
        <el-table-column
          prop="op_name"
          align="center"
          label="操作"
          fixed="right"
          min-width="120"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">
              <el-button
                type="text"
                @click="
                  openShow(scope.row, STATE_ENUM['pass'].value, '审核通过')
                "
                >通过</el-button
              >
              <el-button
                type="text"
                @click="
                  openShow(scope.row, STATE_ENUM['refuse'].value, '审核拒绝')
                "
                >拒绝</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 文件预览 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      :fileIndex="previewIndex"
      :fileType="fileType"
    ></c-preview-file>
    <!-- 文件预览 end -->

    <el-dialog
      :title="title"
      :visible.sync="tipShow"
      width="30%"
      :before-close="handleClose"
      center=""
    >
      <div>
        <div class="mb-10">备注:</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入备注"
          v-model="remark"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tipShow = false">取 消</el-button>
        <el-button type="primary" @click="sureHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { STATE_ENUM } from "./utils/enum";
import {
  postTdcJoiSelectItem,
  postTdcJoiSelectArea,
  postTdcJoinList,
  postTdcJoinAudit,
} from "@/api/general/city-store.js";
import { postTdcJoinExport } from "@/api/export/center.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";

export default {
  name: "entry-management",
  components: {
    CPreviewFile,
  },
  data() {
    return {
      STATE_ENUM,
      title: "",
      tipShow: false,
      dialogVisible: false,
      fileList: [],
      previewIndex: 0, //预览文件下标
      fileType: "", // 预览文件类型
      options: [],
      typeList: [],
      loading: false,
      disabledExport: false, // 导出禁用
      formData: {
        page: 1, //	number	页码
        pageSize: 20, //	number	页大小
        industry: "",
        investment: "",
        education: "",
        advantage: [],
        logistic_business_id: "",
        province_code: "",
        city_code: "",
        area_code: "",
        name: "",
        mobile: "",
        score_start: "",
        score_end: "",
        state: "",
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      detail: {},
      remark: "",
      type: "",
      industry: [], // 从业经验
      investment: [], // 预算
      education: [], // 文化程度
      advantage: [], // 优势
      province_list: [], // 省
      city_list: [], // 市
      district_list: [], // 区
    };
  },
  computed: {
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.url.lastIndexOf(".");
        const fileType = file.url.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    // 下拉选项
    async postAjaxTdcJoiSelectItem() {
      try {
        const res = await postTdcJoiSelectItem();
        this.industry = res.data.industry || [];
        this.investment = res.data.investment || [];
        this.education = res.data.education || [];
        this.advantage = res.data.advantage || [];
        this.typeList = res.data.state || [];
      } catch (error) {
        console.log(error, "postTdcJoiSelectItem");
      }
    },
    // 省市区
    async postAjaxTdcJoiSelectArea() {
      try {
        const res = await postTdcJoiSelectArea();
        console.log(res, "res==");
        this.province_list = res.data.province_list || []; // 省
        this.city_list = res.data.city_list || []; // 市
        this.district_list = res.data.district_list || []; // 区
      } catch (error) {
        console.log(error, "postTdcJoiSelectArea");
      }
    },
    // 通过 ｜ 拒绝
    openShow(e, type, title) {
      console.log(e, type, title, "eee");
      this.title = title;
      this.type = type;
      this.detail = e;
      this.tipShow = true;
      this.remark = "";
    },
    // 确定提交
    async sureHandle() {
      if (!this.remark) {
        this.$message({
          message: "请填写备注",
          type: "error",
        });
        return;
      }
      try {
        const res = await postTdcJoinAudit({
          id: this.detail.id,
          state: this.type,
          remark: this.remark,
        });
        this.$message({
          message: `已${
            this.type == this.STATE_ENUM["refuse"].value ? "拒绝" : "通过"
          }`,
          type: "success",
        });
        this.tipShow = false;
        this.formData.page = 1;
        this.hqlist();
      } catch (error) {
        console.log(error, "postTdcJoinAudit");
      }
    },
    // 关闭弹窗
    handleClose() {
      this.tipShow = false;
    },
    // 查看 预览图片
    showImg(arr) {
      let tmpImageList = [];
      arr.map((mItem) => {
        tmpImageList.push({
          label: "图片",
          url: mItem,
        });
      });
      this.fileList = tmpImageList;
      this.dialogVisible = true;
      this.fileType = this.getFileType(this.fileList[0]);
    },
    // 获取集配中心
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    // 初始化
    onInitData() {
      this.postAjaxTdcJoiSelectArea();
      this.postAjaxTdcJoiSelectItem();
      this.getBusinessList();
      this.hqlist();
    },
    // 获取列表
    async hqlist() {
      this.loading = true;
      try {
        const res = await postTdcJoinList(this.formData);
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postSupplierBankChangeList");
      }
    },
    /**
     * 导出
     */
    async exportHandle() {
      let params = {
        ...this.formData,
      };
      delete params.page;
      delete params.pageSize;
      this.disabledExport = true;
      try {
        const res = await postTdcJoinExport(params);
        this.goExportCenter();
      } catch (error) {
        console.log(error, "postTdcJoinExport");
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-image {
  width: 70px;
  height: 70px;
}

.input-item-tow {
  width: 300px;
  display: flex;
  align-items: center;
  .span {
    margin: 0 10px;
    color: #888;
    font-size: 12px;
  }
}

.entryWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
}
</style>
