var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "编辑商品",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _c("div", { staticClass: "infodata" }, [
                _c("div", [_vm._v("货号：" + _vm._s(_vm.formData.sku_code))]),
                _c("div", [
                  _vm._v("供应商：" + _vm._s(_vm.formData?.supplier?.title)),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品名称", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", "show-word-limit": "" },
                        model: {
                          value: _vm.formData.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "title", $$v)
                          },
                          expression: "formData.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品品类", prop: "category_tag_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "selectRef",
                          attrs: {
                            filterable: "",
                            remote: "",
                            placeholder: "请选择商品品类",
                            "remote-method": _vm.remoteMethod,
                            loading: _vm.loading,
                            clearable: "",
                            "popper-class": "custom-select-dropdown",
                            "popper-append-to-body": false,
                            disabled: _vm.formData.is_sales_cycle == 1,
                          },
                          on: {
                            "visible-change": (val) => _vm.onChangeVisible(val),
                            change: _vm.change,
                          },
                          model: {
                            value: _vm.formData.cateName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "cateName", $$v)
                            },
                            expression: "formData.cateName",
                          },
                        },
                        [
                          _c(
                            "div",
                            _vm._l(_vm.list1, function (item) {
                              return _c("el-option", {
                                key: item.tag_id,
                                attrs: {
                                  label: `${item.p_name}-${item.c_name}-${item.tag_name}`,
                                  value: `${item.p_name}-${item.c_name}-${item.tag_name}`,
                                },
                              })
                            }),
                            1
                          ),
                          _vm.showNext
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.textwarn) + " ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌", prop: "brand_name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.formData.is_sales_cycle == 1,
                          placeholder: "请输入品牌",
                        },
                        model: {
                          value: _vm.formData.brand_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "brand_name", $$v)
                          },
                          expression: "formData.brand_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "包装方式", prop: "package_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择包装方式",
                            disabled: _vm.formData.is_sales_cycle == 1,
                          },
                          on: { change: _vm.changePackage },
                          model: {
                            value: _vm.formData.package_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "package_type", $$v)
                            },
                            expression: "formData.package_type",
                          },
                        },
                        _vm._l(_vm.packageTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "采购员", prop: "buyer_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              _vm.formData.buyer_id == 0
                                ? false
                                : _vm.formData.cid_is_audit == 1 ||
                                  _vm.formData.buy_way == 1 ||
                                  _vm.formData.is_sales_cycle == 1,
                            placeholder: "请选择采购员",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formData.buyer_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "buyer_id", $$v)
                            },
                            expression: "formData.buyer_id",
                          },
                        },
                        _vm._l(_vm.buyer_list, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.fullname, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.cid_type == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "规格", prop: "spec" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              disabled: _vm.formData.is_sales_cycle == 1,
                              "show-word-limit": "",
                              placeholder: "请输入规格",
                            },
                            model: {
                              value: _vm.formData.spec,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "spec", $$v)
                              },
                              expression: "formData.spec",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.cid_type == 2 && _vm.formData.package_type
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "规格", prop: "spec" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "80px",
                                    "margin-right": "20px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 每" +
                                      _vm._s(_vm.formData.package_type_text) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "30%",
                                    "margin-right": "10px",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      disabled:
                                        _vm.formData.is_sales_cycle == 1,
                                      placeholder: "请输入规格",
                                    },
                                    model: {
                                      value: _vm.formData.spec_l1_num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "spec_l1_num",
                                          $$v
                                        )
                                      },
                                      expression: "formData.spec_l1_num",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { width: "15%" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        disabled:
                                          _vm.formData.is_sales_cycle == 1,
                                      },
                                      on: {
                                        change: (val) => _vm.changeSpec(val, 1),
                                      },
                                      model: {
                                        value: _vm.formData.spec_l1_unit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "spec_l1_unit",
                                            $$v
                                          )
                                        },
                                        expression: "formData.spec_l1_unit",
                                      },
                                    },
                                    _vm._l(
                                      _vm.specsList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.formData.spec_l1_unit
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "80px",
                                        "margin-right": "20px",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " 每" +
                                          _vm._s(
                                            _vm.formData.spec_l1_unit_text
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "30%",
                                        "margin-right": "10px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.formData.is_sales_cycle == 1,
                                          "show-word-limit": "",
                                          placeholder: "请输入规格",
                                        },
                                        model: {
                                          value: _vm.formData.spec_l2_num,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "spec_l2_num",
                                              $$v
                                            )
                                          },
                                          expression: "formData.spec_l2_num",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "15%" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择",
                                            disabled:
                                              _vm.formData.is_sales_cycle == 1,
                                          },
                                          on: {
                                            change: (val) =>
                                              _vm.changeSpec(val, 2),
                                          },
                                          model: {
                                            value: _vm.formData.spec_l2_unit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "spec_l2_unit",
                                                $$v
                                              )
                                            },
                                            expression: "formData.spec_l2_unit",
                                          },
                                        },
                                        _vm._l(
                                          _vm.specsSubList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产地", prop: "origin_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择产地",
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formData.is_sales_cycle == 1,
                          },
                          on: { change: _vm.changeOrigin },
                          model: {
                            value: _vm.formData.origin_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "origin_id", $$v)
                            },
                            expression: "formData.origin_id",
                          },
                        },
                        _vm._l(_vm.originList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "等级", prop: "grade" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择等级",
                            disabled: _vm.formData.is_sales_cycle == 1,
                          },
                          model: {
                            value: _vm.formData.grade,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "grade", $$v)
                            },
                            expression: "formData.grade",
                          },
                        },
                        _vm._l(_vm.gradeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.type == 1 &&
                  _vm.formData.creator_type != 1 &&
                  _vm.formData.creator_type != 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标签", prop: "promotion_tag_id" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择标签",
                                multiple: "",
                              },
                              model: {
                                value: _vm.promotion_tag_id,
                                callback: function ($$v) {
                                  _vm.promotion_tag_id = $$v
                                },
                                expression: "promotion_tag_id",
                              },
                            },
                            _vm._l(
                              _vm.promotion_tag_list,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.name,
                                    value: item.id + "",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.promotion_tag_id_change(
                                        $event,
                                        item
                                      )
                                    },
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-alert", {
                attrs: {
                  title: "请确保采购知晓更改事项，后台数据更改均会记录日志",
                  type: "warning",
                  closable: false,
                  "show-icon": "",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingSubmit },
                  on: { click: _vm.onHandleSubmit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }