<template>
  <div class="sale-wrapper bj">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from1.logistics_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.supplier_id"
            :loading="loadingSupplierList"
            filterable
            clearable
            placeholder="选择供应商"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="`${item.title}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.buyer_id"
            :loading="loadingBuyerList"
            filterable
            clearable
            placeholder="选择采购"
          >
            <el-option
              v-for="item in buyerList"
              :key="item.id"
              :label="`${item.fullname}`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.supplier_order_no"
            placeholder="请输入供货单号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-date-picker
            @change="(val) => timeChange(val, 1)"
            v-model="supplier_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="供货日期起"
            end-placeholder="供货日期止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            @change="(val) => timeChange(val, 2)"
            v-model="settlement_at"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="结算日期起"
            end-placeholder="结算日期止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-button type="primary" icon="el-icon-search" @click="search">
            查询
          </el-button>
          <el-button
            type="warning"
            icon="el-icon-upload2"
            @click="onHandleExport"
            :disabled="!tableData.length || disabledExport"
            >导出
          </el-button>
          <el-button
            type="warning"
            icon="el-icon-upload2"
            @click="onHandleDetailExport"
            :disabled="!tableData.length || disabledDetailExport"
            >导出明细
          </el-button>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
    </div>
    <div class="auto-table-flex">
      <table-list
        v-loading="loading"
        :tableData="tableData"
        @submit-form="submitForm"
      ></table-list>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="50"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import TableList from "./modules/table-list/index";
import {
  postSettlementSupplierServiceChargesSkuList,
  getSupplierSelectList,
  postBuyerSelectList,
} from "@/api/general/operation-center/index.js";
import {
  postSettlementSupplierServiceChargesSkuExport,
  postSettlementSupplierServiceChargesSkuDetailExport,
} from "@/api/export/center.js";

export default {
  name: "warehouseGoods",
  components: { TableList },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      supplier_at: [],
      settlement_at: [],
      loadingSupplierList: false,
      loadingBuyerList: false,
      supplierList: [],
      buyerList: [],
      from: {
        logistics_business_id: "",
        supplier_id: "",
        buyer_id: "",
        supplier_order_no: "",
        supply_time_start: "",
        supply_time_end: "",
        settlement_time_start: "",
        settlement_time_end: "",
        page: 1,
        pageSize: 50,
      },
      from1: {
        logistics_business_id: "",
        supplier_id: "",
        buyer_id: "",
        supplier_order_no: "",
        supply_time_start: "",
        supply_time_end: "",
        settlement_time_start: "",
        settlement_time_end: "",
        page: 1,
        pageSize: 50,
      },
      tableData: [],
      options: [],
      currentPage: 1,
      total: 0,
      disabledExport: false, // 导出按钮
      disabledDetailExport: false, // 导出明细按钮
    };
  },
  created() {
    this.getBusinessList();
    this.ajaxGetSupplierSelectList();
    this.postAjaxBuyerSelectList();
    this.hqlist();
  },
  watch: {},
  methods: {
    /**
     * 时间选择
     */
    timeChange(val, type) {
      console.log(val, type);
      if (type == 1) {
        if (val) {
          this.from1.supply_time_start = val[0];
          this.from1.supply_time_end = val[1];
        } else {
          this.from1.supply_time_start = "";
          this.from1.supply_time_end = "";
        }
      } else {
        if (val) {
          this.from1.settlement_time_start = val[0];
          this.from1.settlement_time_end = val[1];
        } else {
          this.from1.settlement_time_start = "";
          this.from1.settlement_time_end = "";
        }
      }
    },
    /**
     * 获取供应商下拉列
     */
    async ajaxGetSupplierSelectList() {
      this.loadingSupplierList = true;
      try {
        const { data } = await getSupplierSelectList();
        this.supplierList = data;
      } catch (err) {
        console.log("ajax getSupplierSelectList err", err);
      } finally {
        this.loadingSupplierList = false;
      }
    },
    /**
     * 获取采购下拉列
     */
    async postAjaxBuyerSelectList() {
      this.loadingBuyerList = true;
      try {
        const { data } = await postBuyerSelectList();
        this.buyerList = data;
      } catch (err) {
        console.log("ajax postBuyerSelectList err", err);
      } finally {
        this.loadingBuyerList = false;
      }
    },
    /**
     * 导出
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.from,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postSettlementSupplierServiceChargesSkuExport(query);
        this.goExportCenter();
      } catch (err) {
        this.disabledExport = false;
        console.log(
          "ajax postSettlementSupplierServiceChargesSkuExport err",
          err
        );
      }
    },
    /**
     * 导出明细
     */
    async onHandleDetailExport() {
      this.disabledDetailExport = true;
      try {
        const query = {
          ...this.from,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postSettlementSupplierServiceChargesSkuDetailExport(query);
        this.goExportCenter();
      } catch (err) {
        this.disabledDetailExport = false;
        console.log(
          "ajax postSettlementSupplierServiceChargesSkuDetailExport err",
          err
        );
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
      this.disabledDetailExport = false;
    },
    // 获取集配中心
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    // 搜索查询
    search() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    // 操作后 刷新列表
    submitForm() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    // 重置列表
    Refresh() {
      this.from = {
        logistic_business_id: "",
        supplier_id: "",
        buyer_id: "",
        supplier_order_no: "",
        supply_time_start: "",
        supply_time_end: "",
        settlement_time_start: "",
        settlement_time_end: "",
        page: 1,
        pageSize: 50,
      };
      this.from1 = {
        logistic_business_id: "",
        supplier_id: "",
        buyer_id: "",
        supplier_order_no: "",
        supply_time_start: "",
        supply_time_end: "",
        settlement_time_start: "",
        settlement_time_end: "",
        page: 1,
        pageSize: 50,
      };
      this.supplier_at = [];
      this.settlement_at = [];
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    async hqlist() {
      this.loading = true;
      try {
        const res = await postSettlementSupplierServiceChargesSkuList(
          this.from
        );
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      } catch (error) {
        console.log(error, "postSettlementSupplierServiceChargesSkuList");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sale-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .goods-image {
    width: 50px;
    height: 50px;
  }

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    // display: flex;

    .seach {
      // flex: 1;
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-end;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
      margin-bottom: 8px;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
