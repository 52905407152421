/**  类型  */
export const TYPE_ENUM = {
  pending: {
    label: "待审批",
    value: "0",
  },
  cancel: {
    label: "取消",
    value: "-1",
  },
  reject: {
    label: "驳回",
    value: "1",
  },
  pass: {
    label: "通过",
    value: "2",
  },
};

/**  类型  */
export const STATE_ENUM = {
  1: {
    label: "拒绝",
    value: 1,
  },
  2: {
    label: "通过",
    value: "2",
  },
};
