<template>
  <div class="cardHolderWrap">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="formData.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.supplier_title"
            clearable
            placeholder="请输入供货商名称"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.supplier_fullname"
            clearable
            placeholder="请输入联系人"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.mobile"
            clearable
            placeholder="请输入电话"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.username"
            clearable
            placeholder="请输入持卡人姓名"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.buyer_name"
            clearable
            placeholder="请输入对接采购"
          >
          </el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.status"
            clearable
            placeholder="请选择状态"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="change_time"
            start-placeholder="变更时间起"
            end-placeholder="变更时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'change_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="submission_time"
            start-placeholder="提交时间起"
            end-placeholder="提交时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'submission_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            :disabled="!tableData.length"
            @click="exportHandle"
            icon="el-icon-download"
            >导 出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="supplier_id" align="center" label="ID">
        </el-table-column>
        <el-table-column
          prop="supplier_title"
          align="center"
          label="供应商名称"
          min-width="150"
        >
        </el-table-column>
        <el-table-column prop="name" align="center" label="集配中心">
          <template slot-scope="scope">
            {{
              scope.row.business && scope.row.business.name
                ? scope.row.business.name
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="fullname"
          align="center"
          label="对接采业务采购"
          min-width="150"
        >
          <template slot-scope="scope">
            {{
              scope.row.buyer && scope.row.buyer.fullname
                ? scope.row.buyer.fullname
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="username"
          align="center"
          label="持卡人姓名"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="card_code"
          align="center"
          label="持卡人身份证号"
          min-width="150"
        >
          <template slot-scope="scope">
            <div>
              <div>{{ scope.row.card_code }}</div>
              <div>
                <el-button type="text" @click="seeInfoPop(scope.row, 1)"
                  >查看</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="bank_name" align="center" label="所属银行">
        </el-table-column>
        <el-table-column
          prop="bank_deposit"
          align="center"
          label="开户行"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="old_username"
          align="center"
          label="原持卡人姓名"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          prop="old_card_code"
          align="center"
          label="原持卡人身份证号"
          min-width="150"
        >
          <template slot-scope="scope">
            <div>
              <div>{{ scope.row.old_card_code }}</div>
              <div>
                <el-button type="text" @click="seeInfoPop(scope.row, 2)"
                  >查看</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="old_bank_name"
          align="center"
          label="原所属银行"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="old_bank_deposit"
          align="center"
          label="原开户行"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="pact_url"
          align="center"
          label="变更协议"
          min-width="120"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.pact_url">
              <el-button type="text" @click="showImg([scope.row.pact_url])"
                >查看协议</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          label="提交时间"
          min-width="150"
        >
        </el-table-column>
        <el-table-column
          prop="status_line"
          align="center"
          label="状态"
          min-width="100"
        >
        </el-table-column>
        <el-table-column prop="audit_time" align="center" label="审核时间">
        </el-table-column>
        <el-table-column prop="op_name" align="center" label="操作人">
        </el-table-column>
        <el-table-column
          prop="op_name"
          align="center"
          label="操作"
          fixed="right"
          min-width="120"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">
              <el-button
                type="text"
                @click="openShow(scope.row, STATE_ENUM[2].value)"
                >通过</el-button
              >
              <el-button
                type="text"
                @click="openShow(scope.row, STATE_ENUM[1].value)"
                >拒绝</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 文件预览 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      :fileIndex="previewIndex"
      :fileType="fileType"
    ></c-preview-file>
    <!-- 文件预览 end -->

    <informationPop ref="infoPop" />

    <el-dialog
      title=" "
      :visible.sync="tipShow"
      width="30%"
      :before-close="handleClose"
    >
      <span>
        您将{{ type == STATE_ENUM[2].value ? "通过" : "拒绝"
        }}{{ detail.supplier_title }}
        供应商的银行账号变更申请，是否确认？
      </span>
      <!--  拒绝显示 start -->
      <div v-if="type == STATE_ENUM[1].value">
        <div class="mt-10 mb-10">拒绝原因:</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入拒绝原因"
          v-model="remark"
        >
        </el-input>
      </div>
      <!--  拒绝显示 end -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="tipShow = false">取 消</el-button>
        <el-button type="primary" @click="sureHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TYPE_ENUM, STATE_ENUM } from "./utils/enum";
import {
  postSupplierBankChangeList,
  postSupplierBankChangeListExport,
  postSupplierBankChange,
} from "@/api/general/supplier.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";
import informationPop from "./modules/informationPop.vue";

export default {
  name: "cardholder-change",
  components: {
    CPreviewFile,
    informationPop,
  },
  data() {
    return {
      STATE_ENUM,
      tipShow: false,
      dialogVisible: false,
      fileList: [],
      previewIndex: 0, //预览文件下标
      fileType: "", // 预览文件类型
      options: [],
      typeList: Object.values(TYPE_ENUM),
      change_time: [],
      submission_time: [],
      loading: false,
      disabledExport: false, // 导出禁用
      formData: {
        page: 1, //	number	页码
        pageSize: 20, //	number	页大小
        logistic_business_id: "",
        supplier_title: "",
        supplier_fullname: "",
        mobile: "",
        username: "",
        buyer_name: "",
        status: "",
        audit_time_start: "",
        audit_time_end: "",
        create_time_start: "",
        create_time_end: "",
        supplier_id: "",
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      detail: {},
      remark: "",
      type: "",
    };
  },
  computed: {
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.url.lastIndexOf(".");
        const fileType = file.url.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
  },
  mounted() {
    console.log(this.$route.query.id);
    this.formData.supplier_id = this.$route.query.id
      ? this.$route.query.id
      : "";
    this.onInitData();
  },
  methods: {
    seeInfoPop(row, type) {
      console.log(row, "row===");
      this.$refs.infoPop.onInitData(row, type);
    },
    // 通过 ｜ 拒绝
    openShow(e, type) {
      console.log(e, type, "eee");
      this.type = type;
      this.detail = e;
      this.tipShow = true;
      this.remark = "";
    },
    // 确定提交
    async sureHandle() {
      if (this.type == this.STATE_ENUM[1].value && !this.remark) {
        this.$message({
          message: "请填写原因",
          type: "error",
        });
        return;
      }
      try {
        const res = await postSupplierBankChange({
          id: this.detail.id,
          status: this.type,
          remark: this.remark,
        });
        this.$message({
          message: `已${
            this.type == this.STATE_ENUM[1].value ? "拒绝" : "通过"
          }`,
          type: "success",
        });
        this.tipShow = false;
        this.formData.page = 1;
        this.hqlist();
      } catch (error) {
        console.log(error, "postSupplierBankChange");
      }
    },
    // 关闭弹窗
    handleClose() {
      this.tipShow = false;
    },
    // 查看 预览图片
    showImg(arr) {
      let tmpImageList = [];
      arr.map((mItem) => {
        tmpImageList.push({
          label: "图片",
          url: mItem,
        });
      });
      this.fileList = tmpImageList;
      this.dialogVisible = true;
      this.fileType = this.getFileType(this.fileList[0]);
    },
    // 获取集配中心
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    // 初始化
    onInitData() {
      this.getBusinessList();
      this.hqlist();
    },
    // 获取列表
    async hqlist() {
      this.loading = true;
      try {
        const res = await postSupplierBankChangeList(this.formData);
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postSupplierBankChangeList");
      }
    },
    /**
     * 导出
     */
    async exportHandle() {
      let params = {
        ...this.formData,
      };
      delete params.page;
      delete params.pageSize;

      try {
        const res = await postSupplierBankChangeListExport(params);
        downLoadFlowBlob(res, res?.filename);
      } catch (error) {
        console.log(error, "postSupplierBankChangeListExport");
      }
    },

    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      this.submission_time = [];
      this.change_time = [];
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
    // 修改时间
    addTimeChange(val, name) {
      if (name == "change_time") {
        if (val) {
          this.formData.audit_time_start = val[0];
          this.formData.audit_time_end = val[1];
        } else {
          this.formData.audit_time_start = "";
          this.formData.audit_time_end = "";
        }
      } else {
        if (val) {
          this.formData.create_time_start = val[0];
          this.formData.create_time_end = val[1];
        } else {
          this.formData.create_time_start = "";
          this.formData.create_time_end = "";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-image {
  width: 70px;
  height: 70px;
}

.cardHolderWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      // justify-content: flex-start !important;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
        //.divname {
        //    width: 100px;
        //   }
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
