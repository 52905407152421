var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "decoration" },
    [
      _c("moduleView", { staticClass: "decoration-left" }),
      _c("contentView", {
        staticClass: "decoration-cnt",
        attrs: { receivedList: _vm.modulelist, obj_item: _vm.obj_item },
      }),
      _c(
        "div",
        { staticClass: "decoration-right" },
        [_c("qualityView", { attrs: { id: _vm.id, obj_item: _vm.obj_item } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }