var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Backgroundimage" }, [
    _c("img", {
      attrs: {
        src: _vm.quality.bg_url ? _vm.quality.bg_url : _vm.main_url,
        alt: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }