<template>
  <div class="Backgroundimage">
    <el-form
      :model="quality"
      ref="childForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="图片：" prop="bg_url">
        <b-file-upload
          key="bg_url"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="detailPicList"
        ></b-file-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
  
  <script>
import BFileUpload from "@/components/business/BFileUpload/index.vue";
export default {
  name: "Backgroundimage",
  components: { BFileUpload },
  props: {
    // 字符串
    configuration: {
      type: Object,
      default: {
        quality: {
          bg_url: "",
        },
      },
    },
  },
  data() {
    return {
      quality: {
        bg_url: "",
      },
      detailPicList: [],
    };
  },
  computed: {},
  watch: {
    /**
     * 图片发生改变
     */
    detailPicList() {
      const res = this.detailPicList?.map((item) => item.url).join(",");
      this.quality.bg_url = res;
    },
    quality: {
      handler: function (newValue, oldValue) {
        console.log("quality参数", newValue);
        this.$emit("quality", this.quality);
      },
      deep: true,
    },
    configuration: {
      handler: function (newValue, oldValue) {
        console.log("configuration参数", newValue);
        this.detailPicList = e.quality.bg_url
          ? [{ url: e.quality.bg_url, name: "food.jpeg" }]
          : [];
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.detailPicList = this.configuration.quality.bg_url
      ? [{ url: this.configuration.quality.bg_url, name: "food.jpeg" }]
      : [];
  },
  methods: {},
};
</script>
  
  <style  lang="scss">
.Backgroundimage {
}
</style>
  