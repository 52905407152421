import { BASE } from "../index";
import axios from "../http";
const base = BASE.EXPORT_CENTER;

/**
 * 总控-下载中心列表
 * @param {*} params
 * @returns
 */
export function postExportCenterList(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/download/center/list`,
    data: params,
  });
}

/**
 * 第三方服务费列表-导出
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceChargesSkuExport(params = {}) {
  return axios({
    method: "post",
    url: `${base}/bi/settlement/supplier/serviceCharges/sku/list/export`,
    data: params,
  });
}

/**
 * 第三方服务费列表-导出明细
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceChargesSkuDetailExport(
  params = {}
) {
  return axios({
    method: "post",
    url: `${base}/bi/settlement/supplier/serviceCharges/sku/detail/export`,
    data: params,
  });
}

/**
 * 第三方服务费明细列表-导出
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceChargesOrderListExport(
  params = {}
) {
  return axios({
    method: "post",
    url: `${base}/bi/settlement/supplier/serviceCharges/order/list/export`,
    data: params,
  });
}

/**
 * 总控-下载中心列表-更新下载人
 * @param {*} params
 * @returns
 */
export function postSetDownloadUser(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/update/download/user`,
    data: params,
  });
}

/**
 * 总控-会员管理-普通会员-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postMemberListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/member/list/export`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postLogisticsListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/logistics/list/export`,
    data: params,
  });
}

/**
 * 总控-财务管理-结算单管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postSupplierOrderSettleExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/supplier/order/settle/list/export`,
    data: params,
  });
}

/**
 * 总控-财务管理-打款管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postSupplierWithdrawExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/supplier/withdraw/list/export`,
    data: params,
  });
}

/**
 * 总控-财务管理-运费结算管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postFreightSettleExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/freight/settle/list/export`,
    data: params,
  });
}

/**
 * 总控-财务管理-代采费结算管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postPurchaseSettleExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/purchase/settle/list/export`,
    data: params,
  });
}

/**
 * 总控-财务管理-城市仓打款管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postLogisticsWithdrawExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/logistics/withdraw/list/export`,
    data: params,
  });
}

/**
 * 总控-售后单管理-平台介入-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postAfterSaleExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/aftersale/new/list/export`,
    data: params,
  });
}

/**
 * 总控-售后单管理-非平台介入-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postUnAfterSaleExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/aftersale/new/un/list/export`,
    data: params,
  });
}

/**
 * 总控-订单管理-订单列表-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postLogisticsOrderListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/order/list/exportNew`,
    data: params,
  });
}

/**
 * 总控-会员管理-会员充值记录-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postMemberTopUpLogExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/memberRecharge/list/export`,
    data: params,
  });
}

/**
 * 总控-会员管理-消费明细-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postConsumeLogisticsListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/vip/consume/logistics/list/export`,
    data: params,
  });
}

/**
 * 总控-运营中心-复核缺货管理-列表-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postAfterSaleQcExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/aftersale/qc/export`,
    data: params,
  });
}

/*
 * 总控-运营中心-优惠券发放记录-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postCouponRecordsExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/coupon/records/export`,
    data: params,
  });
}

/*
 * 总控-运营中心-城市仓扣款管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postLogisticDeductionExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/aftersale/deduction/logistic`,
    data: params,
  });
}

/*
 * 总控-运营中心-供应商扣款管理-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postSupplierDeductionExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/aftersale/deduction/supplier`,
    data: params,
  });
}

/*
 * 总控-订单管理-商品跟踪-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postOrderDetailFlowListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/order/detail/flow/list/export`,
    data: params,
  });
}

/*
 * 总控-运营中心-城市仓流水导出（BI）-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postLogisticsWalletFlowExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/logistics/wallet/flow/export`,
    data: params,
  });
}

/*
 * 总控-运营中心-供应商流水导出（BI）-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postSupplierWalletFlowExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/supplier/wallet/flow/export`,
    data: params,
  });
}

/*
 * 总控-商品管理-在售/下架及审核/仓主专区商品导出（BI）-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postProductListExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/product/list/export`,
    data: params,
  });
}

/*
 * 总控热词导出-导出(上传oss)
 * @param {*} params
 * @returns
 */
export function postMemberSearchTotalExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/member/search/total/export`,
    data: params,
  });
}

/**
 * 总控-城市仓管理-城市入驻(上传oss)
 * @param {*} params
 * @returns
 */
export function postTdcJoinExport(params = {}) {
  return axios({
    method: "POST",
    url: `${base}/bi/tdc/join/export`,
    data: params,
  });
}
