<template>
  <div class="qualityView">
    <el-button type="primary" @click="save" :disabled="btndisable"
      >保存</el-button
    >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="页面配置" name="1">
        <PageConfiguration :obj_item="obj_item"></PageConfiguration>
      </el-tab-pane>
      <el-tab-pane label="组件配置" name="2">
        <component
          :is="componentname"
          @quality="qualitychange"
          :configuration="configuration"
        ></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import template1 from "@/components/attribute/template1.vue";
import Backgroundimage from "@/components/attribute/Backgroundimage.vue";
import PageConfiguration from "@/components/attribute/PageConfiguration.vue";
export default {
  name: "",

  props: {
    // 字符串
    id: {
      type: String,
      default: "",
    },
    // 字符串
    obj_item: {
      type: Object,
      default: {},
    },
  },
  components: {
    template1,
    Backgroundimage,
    PageConfiguration,
  },
  data() {
    return {
      activeName: "1",
      componentname: "",
      configuration: {},
      foundation: {},
      btndisable: false,
      checkname: false,
    };
  },
  computed: {
    foundationitem() {
      return this.$store.state.foundation;
    },
    renovationitem() {
      return this.$store.state.renovationitem;
    },
  },
  watch: {
    foundationitem(newV, oldV) {
      this.foundation = newV;
    },
    renovationitem(newVal, oldVal) {
      console.log("renovationitem changed: ", newVal);

      this.activeName = "2";
      this.componentname = newVal.quality;
      this.configuration = newVal.configuration;
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    qualitychange(e) {
      console.log(e, "切换了东西了");
      this.$store.commit("addquality", e);
    },
    check() {
      this.checkname = false;
      if (!this.foundation.main_image) {
        this.$message({
          message: "请上传模板主图",
          type: "error",
        });
        this.checkname = true;
      } 
      else if (!this.foundation.bg_url) {
        this.$message({
          message: "请上传顶部背景图片",
          type: "error",
        });
        this.checkname = true;
      } 
      else if (!this.foundation.logistic_business_id) {
        this.$message({
          message: "请选择集配中心",
          type: "error",
        });
        this.checkname = true;
      } 
      
      else if (!this.foundation.global_color) {
        this.$message({
          message: "请上传背景颜色",
          type: "error",
        });
        this.checkname = true;
      } else if (!this.foundation.name) {
        this.$message({
          message: "请输入模板名称",
          type: "error",
        });
        this.checkname = true;
      } else {
        try {
          console.log(this.$store.state.contentlist, "保存的所有数据")
          this.$store.state.contentlist.forEach((el) => {
            if (el.pattern == 1) {
              if (!el.configuration.quality.head_url) {
                this.$message({
                  message: "请上传分区顶部背景图片",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              } else if (!el.configuration.quality.main_url) {
                this.$message({
                  message: "请上传分区按钮背景图片",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              }else if (!el.configuration.quality.btn_color) {
                this.$message({
                  message: "请上传分区按钮文字颜色",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              } else if (!el.configuration.quality.more_url) {
                this.$message({
                  message: "请上传分区标题跳转按钮图",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              } else if (!el.configuration.quality.region_name) {
                this.$message({
                  message: "请选择分区",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              }else if (!el.configuration.quality.banner_url) {
                this.$message({
                  message: "请选择分区详情banner图片",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              }
            } else {
              if (!el.configuration.quality.bg_url) {
                this.$message({
                  message: "请上传主图",
                  type: "error",
                });
                this.checkname = true;
                throw Error(`必填项报错`);
              }
            }
            if (this.checkname == true) {
              return; //Exits only the current iteration
            }
          });
        } catch (err) {}
      }
    },
    save() {
      this.check();
      if (this.checkname) {
        return;
      }
      console.log(
        this.$store.state.contentlist,
        "保存的所有数据",
        this.foundation
      );

      this.btndisable = true;
      let content = [];
      this.$store.state.contentlist.forEach((el) => {
        content.push({
     
          ...el.configuration.quality,
          region_type: el.id,
          id: el.configuration.quality.id,
        });
      });
      let data = {
        ...this.foundation,
        id: this.id,
        obj_item: JSON.stringify(this.$store.state.contentlist),
        content: content,
      };

      if(this.id){
        
        this.$api.decoration
        .template_edit(data)
        .then((res) => {
          console.log(res, "编辑成功");
          this.$message({
                  message: "编辑成功",
                  type: "success",
                });
          this.btndisable = false;
        })
        .catch((err) => {
          this.btndisable = false;
        });
      }else{
        this.$api.decoration
        .template_add(data)
        .then((res) => {
          console.log(res, "保存成功");
          this.$message({
                  message: "保存成功",
                  type: "success",
                });
          this.btndisable = false;
        })
        .catch((err) => {
          this.btndisable = false;
        });
      }
    
    },
  },
};
</script>

<style  lang="scss">
.qualityView {
  background-color: #fff;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
</style>
