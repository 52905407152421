<template>
  <div>
    <el-dialog
      title="营业执照关联记录"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onHandleClose"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-table
          :data="listData"
          style="width: 100%"
          v-loading="loading"
          :border="true"
        >
          <el-table-column
            prop="supplier.fullname"
            align="center"
            label="联系人"
          >
          </el-table-column>
          <el-table-column
            prop="img"
            align="center"
            label="营业执照图片"
            min-width="100"
          >
            <template slot-scope="scope" v-if="scope.row.img">
              <el-image
                style="width: 30px; height: 30px"
                :src="scope.row.img"
                :preview-src-list="[scope.row.img]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="关联时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleCancel(scope.row)"
                v-if="supplierInfo.id == scope.row.supplier_id"
                >取消关联
              </el-button>
              <el-button type="text" @click="authentication(scope.row)"
                >{{ scope.row.is_verify == 1 ? "取消认证" : "认证" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onHandleClose"> 关闭 </el-button>
      </span>
    </el-dialog>
    <!--  取消关联/取消认证弹窗 -->
    <el-dialog
      :title="tipContent"
      :visible.sync="tipShow"
      width="30%"
      :before-close="handleClose"
      center
    >
      <div>
        <div class="mt-10 mb-10">备注:</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入备注"
          v-model="remark"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tipShow = false">取 消</el-button>
        <el-button type="primary" @click="sureHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSupplierLicense,
  getSupplierLicenseDel,
  supplierlicenseverify,
} from "@/api/generalControl/index.js";
export default {
  name: "costConfigDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loading: true,
      listData: [],
      supplierInfo: {}, // 选中该条供应商信息
      tipShow: false,
      remark: "",
      tipContent: "",
      type: "",
      detail: {},
    };
  },
  methods: {
    // 关闭弹窗
    handleClose() {
      this.tipShow = false;
      this.remark = "";
    },
    // 确认提交
    sureHandle() {
      if (!this.remark) {
        this.$message({
          message: "请填写备注",
          type: "error",
        });
        return;
      }
      if (this.type == 1) {
        // 取消关联
        this.getAjaxSupplierLicenseDel();
      } else {
        this.supplierAjaxlicenseverify();
      }
    },
    //认证取消认证
    authentication(e) {
      this.detail = e;
      if (e.is_verify == 1) {
        // 取消认证
        this.tipShow = true;
        this.tipContent = "是否确认取消认证?";
        this.type = 2;
      } else {
        // 认证
        this.$confirm(
          `是否${
            e.is_verify == 2
              ? `确认认证供货商${e.supplier.fullname}`
              : `取消认证供货商${e.supplier.fullname}`
          }？`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }
        )
          .then(() => {
            supplierlicenseverify({
              id: e.id,
              status: e.is_verify == 1 ? 2 : 1,
            }).then((res) => {
              this.$message({
                message: `已${e.is_verify == 1 ? "取消认证" : "认证成功"}`,
                type: "success",
                showClose: true,
              });
              e.is_verify = e.is_verify == 1 ? 2 : 1;
              this.$emit("on-refresh-list"); // 刷新列表
            });
          })
          .catch(() => {});
      }
    },
    // 取消认证
    supplierAjaxlicenseverify() {
      supplierlicenseverify({
        id: this.detail.id,
        status: this.detail.is_verify == 1 ? 2 : 1,
        remark: this.remark || "",
      }).then((res) => {
        this.$message({
          message: `已${this.detail.is_verify == 1 ? "取消认证" : "认证成功"}`,
          type: "success",
          showClose: true,
        });
        this.detail.is_verify = this.detail.is_verify == 1 ? 2 : 1;
        this.tipShow = false;
        this.$emit("on-refresh-list"); // 刷新列表
      });
    },
    /**
     * 取消关联
     */
    async handleCancel(row) {
      this.tipShow = true;
      this.tipContent = "是否确认取消关联?";
      this.type = 1;
      this.detail = row;
      this.remark = "";
    },
    async getAjaxSupplierLicenseDel() {
      try {
        const res = await getSupplierLicenseDel({
          id: this.detail.id,
          remark: this.remark,
        });
        this.tipShow = false;
        this.$message.success("取消关联成功");
        this.$emit("on-refresh-list"); // 刷新列表
      } catch (error) {
        console.log(error, "getSupplierLicenseDel");
      } finally {
        this.onHandleClose(); // 关闭弹窗
      }
    },
    /**
     * 初始化
     */
    onInitData(data) {
      this.supplierInfo = data;
      this.dialogVisible = true;
      this.getAjaxSupplierLicense(data);
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.tipShow = false;
      this.remark = "";
    },
    /**
     * 获取营业执照关联记录
     */
    async getAjaxSupplierLicense(e) {
      this.loading = true;
      try {
        const { data } = await getSupplierLicense({
          reg_num: e.license.reg_num,
          supplier_id: e.id,
        });
        this.listData = data;
      } catch (error) {
        console.log(error, "getSupplierLicense");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
    .config {
      &-base,
      &-before,
      &-after {
        display: flex;
        justify-content: center;
        .el-select,
        .el-input {
          flex: 1;
        }
        .label {
          width: 40px;
          padding-left: 20px;
        }
      }
      &-before {
        margin: 20px 0;
      }
    }
  }
}
</style>
