<template>
  <div class="template2">
    <div
      class="template2-title"
      :style="{ backgroundImage: `url(${objitem.head_url?objitem.head_url:objitemcopy.head_url})` }"
    >
      <div
        class="template2-title-btn"
        :style="{ backgroundImage: `url(${objitem.main_url?objitem.main_url:objitemcopy.main_url})` }"
      >
        <div class="text" :style="{color:`${objitem.btn_color?objitem.btn_color:objitemcopy.btn_color}`}">{{ objitem.region_name?objitem.region_name:'更多好物' }}</div>
      </div>
      <img class="infobtn"  v-if="objitem.more_url" :src="objitem.more_url" alt="" />
    </div>
    <div class="template2-cnt-list">
      <div class="left">
        <div v-for="(item, index) in listleft" :key="item.id">
          <goodsItem
            :indexkey="index"
            :additem="item"
            :list="listleft"
          ></goodsItem>
        </div>
      </div>
      <div class="right">
        <div v-for="(item, index) in listleft" :key="item.id">
          <goodsItem
            :indexkey="index"
            :additem="item"
            :list="listleft"
          ></goodsItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodsItem from "./Modules/goodsItem.vue";
export default {
  name: "",
  components: { goodsItem },
  props: {
    // 配置筛选项
    quality: {
      type: [Object, Array],
      default: function () {
        //这里默认return一个数组，即可解决上述的报错问题
        return {
          head_url: "", //顶部背景图片
          main_url: "", //按钮背景图
          more_url: "", //查看更多按钮
          region_name: "", //标题
          id: "",
        };
      },
    },
    //下拉框数据值

    selected: {
      type: Object,
      default: function () {
        //这里默认return一个数组，即可解决上述的报错问题
        return {};
      },
    },
  },

  data() {
    return {
      objitem:{
        head_url:'',
        main_url:'',
        region_name:'专区名称',
        more_url:'',
           id:''
      },
      objitemcopy:{
      
      },
      listleft: [
        {
          id: 1,
          kv: "https://file-purchase.hbxinfadi.com/supplier/common/2412/202412031755069BQX17DIPHTK1N2V5W.jpg",
          min_num: 1,
          title: "测试供应商上架审核时间-2",
          price: "90.00",
          weight: "17.00",
          weight_price: "5.29",
        },
      ],
    };
  },
  computed: {},
  watch: {
    quality: {
      handler: function (newValue, oldValue) {
        console.log("quality参数", newValue);
        this.objitem=newValue;
      },
      deep: true,
    },
   
  },
  created() {
    this.objitem=this.quality;
    this.objitemcopy = JSON.parse(JSON.stringify(this.objitem));
    // if (!Array.isArray(this.quality)) {
    //   this.formList = this.quality.formList;
    // } else {
    //   this.formList = [];
    // }
    // this.btnlist = this.quality.btnlist ? this.quality.btnlist : [];
    // this.selectedname = this.selected;
    // console.log(this.selectedname,'sadsadasdasd1')
    // this.btnlist = [{ type: "edit", name: "编辑", icon: "" }];
  },
  mounted() {},
  methods: {
 
  },
};
</script>

<style lang="scss">
.template2 {
  margin-bottom: 34;
  padding: 0 13px;
  .template2-title {
    width: 100%;
    height: 85px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .infobtn {
      width: 71px;
      height: 20px;
      position: absolute;
      right: 18px;
      bottom: 22px;
    }
    .template2-title-btn {
      min-width: 130px;
      padding: 0 10px;
      height: 42px;
      display: flex;
      // align-items: center;
      padding-top: 5px;
      box-sizing: border-box;
      justify-content: center;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;

      .text {
        font-family: Alibaba PuHuiTi;
        font-weight: 800;
        font-size: 20px;
        color: #ffffff;
        // text-shadow: 0rpx 2rpx 0rpx #0e8cfe,
        //   0rpx 8rpx 6rpx rgba(0, 63, 214, 0.95);
        // background: linear-gradient(0deg, #cbeaff 0.146484375%, #fefff2 100%);
        // -webkit-background-clip: text;
      }
    }
  }
  .template2-cnt-list {
    display: flex;
    .left {
      width: 50%;
      margin-right: 11px;
    }
    .right {
      width: 50%;
    }
  }
}
</style>
