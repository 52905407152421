var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "AddActivity" }, [
    _vm._m(0),
    _c("div", { staticClass: "AddActivity-cnt" }, [
      _c(
        "div",
        { staticClass: "AddActivity-cnt-left" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "集配中心", prop: "logistic_business_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "选择集配中心",
                        clearable: "",
                        filterable: "",
                        disabled: _vm.$route.query.id,
                      },
                      on: { change: _vm.hqlist },
                      model: {
                        value: _vm.ruleForm.logistic_business_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "logistic_business_id", $$v)
                        },
                        expression: "ruleForm.logistic_business_id",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("大促活动"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("专题活动"),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("秒杀活动"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "name", $$v)
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动时间", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "time-arrow-control": true,
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "—",
                      "start-placeholder": "活动时间起",
                      "end-placeholder": "活动时间止",
                    },
                    on: { change: _vm.startchanghe },
                    model: {
                      value: _vm.start_time_arr,
                      callback: function ($$v) {
                        _vm.start_time_arr = $$v
                      },
                      expression: "start_time_arr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预提报时间", prop: "sign_start_time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "time-arrow-control": true,
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "—",
                      "start-placeholder": "预提报时间起",
                      "end-placeholder": "预提报时间止",
                    },
                    on: { change: _vm.sign_startchanghe },
                    model: {
                      value: _vm.sign_start_time_arr,
                      callback: function ($$v) {
                        _vm.sign_start_time_arr = $$v
                      },
                      expression: "sign_start_time_arr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动描述", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "desc", $$v)
                      },
                      expression: "ruleForm.desc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动banner图", prop: "banner" } },
                [
                  _c("b-file-upload", {
                    key: "banner",
                    attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                    model: {
                      value: _vm.head_url_List,
                      callback: function ($$v) {
                        _vm.head_url_List = $$v
                      },
                      expression: "head_url_List",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否需采购报品", prop: "is_sign" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_sign,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_sign", $$v)
                        },
                        expression: "ruleForm.is_sign",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("需要")]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不需要"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否需后台审核", prop: "is_audit" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_audit,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "is_audit", $$v)
                        },
                        expression: "ruleForm.is_audit",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("需要")]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不需要"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "采购限制提报数量", prop: "limit_num" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      step: 1,
                      "step-strictly": "",
                      label: "采购限制提报数量",
                    },
                    model: {
                      value: _vm.ruleForm.limit_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "limit_num", $$v)
                      },
                      expression: "ruleForm.limit_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动模版", prop: "template_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "选择活动模版",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.tableDatachange },
                      model: {
                        value: _vm.ruleForm.template_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "template_id", $$v)
                        },
                        expression: "ruleForm.template_id",
                      },
                    },
                    _vm._l(_vm.tableData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动状态", prop: "open_status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.open_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "open_status", $$v)
                        },
                        expression: "ruleForm.open_status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("启用")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁用")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("立即创建")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "AddActivity-cnt-right" },
        [
          _c("activitycontent", {
            attrs: { receivedList: _vm.modulelist, obj_item: _vm.obj_item },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("h3", [_vm._v("创建活动")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }