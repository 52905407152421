<template>
  <section class="page-wrap">
    <!-- 表单 start -->
    <div class="search">
      <div class="search-item">
        <el-input
          v-model="fromData.supplierOrderNo"
          placeholder="输入供货单号"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-date-picker
          v-model="fromData.deliveryTime"
          type="date"
          placeholder="选择供货日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <el-input
          v-model="fromData.qcCode"
          placeholder="输入复核单号"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-select
          filterable
          multiple
          v-model="fromData.storeLogistics"
          clearable
          placeholder="选择城市仓"
          @change="onChangeCityStore"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select
          filterable
          multiple
          v-model="fromData.storeDelivery"
          clearable
          placeholder="选择自提点"
        >
          <el-option
            v-for="item in selfTakeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select
          v-model="fromData.reviewUserId"
          clearable
          placeholder="选择品控员"
        >
          <el-option
            v-for="item of qualityList"
            :key="item.id"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select
          v-model="fromData.reviewStatus"
          clearable
          placeholder="选择品控状态"
        >
          <el-option
            v-for="(item, index) in qualityControlList"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select
          v-model="fromData.isReturn"
          clearable
          placeholder="是否品控退回"
        >
          <el-option
            v-for="(rItem, rIndex) in isReturnList"
            :key="rIndex"
            :label="rItem.name"
            :value="rItem.value"
          ></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-date-picker
          @input="changeReviewTime"
          v-model="fromData.reviewTime"
          type="date"
          placeholder="选择品控日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <el-input
          v-model="fromData.productName"
          placeholder="输入品名"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-input
          v-model="fromData.productSkuCode"
          placeholder="输入货号"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-input
          v-model="fromData.supplierName"
          placeholder="输入供货商"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-input
          v-model="fromData.qcUserName"
          placeholder="输入复核员"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-input
          v-model="fromData.buyerName"
          placeholder="输入采购员"
          clearable
        ></el-input>
      </div>
      <div class="search-item">
        <el-select
          v-model="fromData.outStockStatus"
          clearable
          placeholder="选择是否缺货"
        >
          <el-option label="全部" value="0"></el-option>
          <el-option label="缺货" value="1"></el-option>
          <el-option label="不缺货" value="2"></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-select
          v-model="fromData.qcStatus"
          clearable
          placeholder="选择复核状态"
        >
          <el-option label="全部" value="0"></el-option>
          <el-option label="未复核" value="1"></el-option>
          <el-option label="复核中" value="3"></el-option>
          <el-option label="已复核" value="2"></el-option>
        </el-select>
      </div>
      <div class="search-item">
        <el-date-picker
          @input="changeQcTime"
          v-model="fromData.qcTime"
          type="date"
          placeholder="选择复核完成日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <el-button
          class="bjsearch"
          type="primary"
          icon="el-icon-refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
      <div class="search-item">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
      <div class="search-item">
        <el-button @click="onHandleExport" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
      <div class="search-item">
        <el-button
          type="warning"
          @click="onClickReview"
          class="checkButton"
          icon="el-icon-s-check"
          >一键复核</el-button
        >
      </div>
      <div class="tipText">
        一键复核必须为品控状态为超时未送货的状态，操作一键复核之后五分钟之后复核退款完成
      </div>
    </div>
    <!-- 表单 end -->
    <!-- table start -->
    <div class="table-wrap">
      <el-table
        v-loading="loading"
        border
        :data="dataList"
        height="100%"
        style="width: 100%;"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        tooltip-effect="dark"
      >
        <el-table-column type="index" width="80" align="center" label="序号">
        </el-table-column>
        <el-table-column label="整单复核码" prop="entruckQcCode" align="center">
        </el-table-column>
        <el-table-column label="单品复核码" prop="skuQcCode" align="center">
        </el-table-column>
        <el-table-column label="供货日期" prop="deliveryDate" align="center">
        </el-table-column>
        <el-table-column label="采购数量" prop="purchaseNum" align="center">
        </el-table-column>
        <el-table-column label="复核数量" prop="qcReviewNum" align="center">
        </el-table-column>
        <el-table-column label="是否缺货" prop="isOutStock" align="center">
          <template slot-scope="scope">
            {{
              Object.values(OUT_STOCK_ENUM).find(
                (item) => item.value == scope.row.isOutStock
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column label="缺货数量" prop="qcNum" align="center">
        </el-table-column>
        <el-table-column label="缺货原因" prop="qcReason" align="center">
        </el-table-column>
        <el-table-column label="供应商" prop="supplierName" align="center">
        </el-table-column>
        <el-table-column label="品名" prop="productName" align="center">
        </el-table-column>
        <el-table-column label="品类" prop="productCategoryName" align="center">
        </el-table-column>
        <el-table-column label="货号" prop="productSkuCode" align="center">
        </el-table-column>
        <el-table-column label="采购员" prop="buyerName" align="center">
        </el-table-column>
        <el-table-column label="城市仓" prop="storeLogistics" align="center">
        </el-table-column>
        <el-table-column label="自提点" prop="storeDelivery" align="center">
        </el-table-column>
        <el-table-column label="品控状态" prop="reviewStatus" align="center">
          <template slot-scope="scope">
            {{
              Object.values(REVIEW_STATUS_ENUM).find(
                (item) => item.value == scope.row.reviewStatus
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="是否品控退回"
          prop="reviewStatus"
          align="center"
        >
          <template slot-scope="scope">
            {{
              Object.values(IS_RETURN_ENUM).find(
                (item) => item.value == scope.row.isReturn
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column label="品控时间" prop="reviewTime" align="center">
        </el-table-column>
        <el-table-column label="品控员" prop="reviewUser" align="center">
        </el-table-column>
        <el-table-column label="复核状态" prop="qcStatus" align="center">
          <template slot-scope="scope">
            {{
              Object.values(IS_CHECK_ENUM).find(
                (item) => item.value == scope.row.qcStatus
              ).label
            }}
          </template>
        </el-table-column>
        <el-table-column label="复核完成时间" prop="qcTime" align="center">
        </el-table-column>
        <el-table-column label="复核员" prop="qcUser" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        align="right"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="fromData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- table end -->
  </section>
</template>

<script>
import url from "url";
import { formatDate } from "../../../utils//tools/date.js";
import { BASE } from "@/api";
import {
  qualityControlReview,
  getOneClickReview,
} from "@/api/sheet-to-review/index";
import {
  OUT_STOCK_ENUM,
  REVIEW_STATUS_ENUM,
  IS_RETURN_ENUM,
  IS_CHECK_ENUM,
} from "../utils/enum";
export default {
  name: "ToReviewLog",
  data() {
    return {
      qualityControlList: [
        { name: "全部", value: "0" },
        { name: "未品控", value: "1" },
        { name: "已品控", value: "2" },
        { name: "品控超时", value: "3" },
        { name: "未过品控", value: "4" },
      ], // 选择品控状态参数列表
      isReturnList: [
        { name: "全部", value: "0" },
        { name: "是", value: "2" },
        { name: "否", value: "1" },
      ], // 是否品控退回参数列表
      fromData: {
        supplierOrderNo: "",
        qcCode: "",
        deliveryTime: formatDate(),
        reviewUserId: "",
        storeLogistics: [],
        storeDelivery: [],
        reviewStatus: "",
        isReturn: "", // 是否品控退回
        reviewTime: "",
        productName: "",
        productSkuCode: "",
        supplierName: "",
        buyerName: "",
        outStockStatus: "",
        qcUserName: "",
        qcStatus: "",
        qcTime: "",
        page: 1,
        pageSize: 10,
      },
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      qualityList: [], // 品控员列表
      dataList: [],
      currentPage: 1,
      total: 0,
      loading: false,
      OUT_STOCK_ENUM,
      REVIEW_STATUS_ENUM,
      IS_RETURN_ENUM,
      IS_CHECK_ENUM,
    };
  },
  created() {
    this.getQualityList();
    this.onHandleSearch();
    this.getAjaxCityStoreList();
    this.getAjaxSelfTakeList();
  },
  methods: {
    // 品控日期 为null 转换空字符串
    changeReviewTime(val) {
      if (!val) {
        this.fromData.reviewTime = "";
      }
    },
    // 复核日期 为null 转换空字符串
    changeQcTime(val) {
      if (!val) {
        this.fromData.qcTime = "";
      }
    },
    // 获取城市仓列表
    getAjaxCityStoreList: async function () {
      const params = {
        name: "",
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckLogisticslist(params);
        this.cityStoreList = data.data;
      } catch (err) {
        console.log("ajx getAjaxCityStoreList err", err);
      }
    },
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: async function (logisticsId) {
      //城市仓为必传参数
      if (!logisticsId?.length) {
        return;
      }
      const params = {
        logistics_id: logisticsId,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.logistics.entruckDeliverylist(params);
        this.selfTakeList = data.data;
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      }
    },
    /*
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      this.selfTakeList = []; // 重置自提点
      this.fromData.storeDelivery = []; // 重置自提点搜索
      if (data?.length) {
        this.getAjaxSelfTakeList(data);
      }
    },
    /**
     * 刷新数据
     */
    onHandleRefresh() {
      // 清空搜索
      this.fromData = {
        supplierOrderNo: "",
        qcCode: "",
        deliveryTime: formatDate(),
        reviewUserId: "",
        storeLogistics: [],
        storeDelivery: [],
        reviewStatus: "",
        reviewTime: "",
        productName: "",
        productSkuCode: "",
        supplierName: "",
        buyerName: "",
        outStockStatus: "",
        qcUserName: "",
        qcStatus: "",
        qcTime: "",
        page: 1,
        pageSize: 10,
      };
      this.onHandleSearch();
    },
    // 获取品控员列表
    async getQualityList() {
      try {
        const res = await this.$api.logistics.getControlList({
          page: 1,
          pageSize: 1000,
        });
        this.qualityList = res?.data?.data || [];
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 搜索
     */
    onHandleSearch() {
      try {
        // TODO
        this.fromData.page = 1;
        this.currentPage = 1;
        this.onHandleData();
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 请求列表数据
     */
    async onHandleData() {
      if (!this.fromData.deliveryTime) {
        this.tool.message("请选择供货日期", "error");
        return;
      }
      try {
        this.loading = true;
        const res = await qualityControlReview(this.fromData);
        this.dataList = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 导出
     */
    onHandleExport() {
      if (!this.fromData.deliveryTime) {
        this.tool.message("请选择供货日期", "error");
        return;
      }
      try {
        // 组合需要的参数
        const query = {
          // deliveryTime,
          // supplierOrderNo,
          // qcCode,
          // reviewUserId,
          // storeLogistics,
          // storeDelivery,
          // reviewStatus,
          // reviewTime,
          // productName,
          // productSkuCode,
          // supplierName,
          // buyerName,
          // outStockStatus,
          // qcUserName,
          // qcStatus,
          // qcTime,
          ...this.fromData,
          token: sessionStorage.getItem("token"),
        };
        query.storeDelivery = JSON.parse(
          JSON.stringify(this.fromData.storeDelivery)
        ).join(",");
        query.storeLogistics = JSON.parse(
          JSON.stringify(this.fromData.storeLogistics)
        ).join(",");
        let path = BASE.PRO2 + "/entruck/getEntruckQcListExport";
        let params = url.format({ query });
        console.log("🆒", path + params);
        window.open(path + params);
      } catch (err) {
        console.log("ajax err", err);
      }
    },
    /**
     * 更新了每页数据
     */
    onHandleSizeChange(val) {
      this.fromData.pageSize = val;
      this.onHandleData();
    },
    /**
     * 更新了页码
     */
    onHandleCurrentChange(val) {
      this.fromData.page = val;
      this.currentPage = val;
      this.onHandleData();
    },
    /**
     * 一键复核接口
     */
    async onHandleReview() {
      try {
        this.loading = true;
        const res = await getOneClickReview({
          deliveryTime: this.fromData.deliveryTime,
        });
        this.onHandleSearch();
        this.tool.message(`复核成功`, "success");
      } catch (err) {
        console.log("一键复核失败", err);
      }
    },
    /**
     * 一键复核弹窗
     */
    onClickReview(val) {
      if (!this.fromData.deliveryTime) {
        this.tool.message("一键复核销需要选择供货日期！", "error");
        return;
      } else {
        if (
          this.fromData.reviewStatus != 3
          // this.fromData.storeLogistics.length == 0 &&
          // this.fromData.storeDelivery.length == 0
        ) {
          this.tool.message("一键复核需要选择品控状态为品控超时！", "error");
          return;
        }
      }
      this.$confirm(
        "操作一键复核之后未复核的供货单将全部操作复核退款，操作不可撤销修改,是否确认?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "jn-confirm-message-style",
          iconClass: "el-icon-warning",
        }
      )
        .then(() => {
          try {
            this.onHandleReview();
          } catch (err) {
            console.log("ajax err", err);
          }
        })
        .catch(() => {
          this.tool.message("取消成功", "info");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  padding: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  .search {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background: #fff;
  }
  .bjsearch {
    background: #333333;
    border-color: #333333;
  }
  .checkButton {
    background: #1389ae;
    border-color: #1389ae;
  }
  .el-button--warning.is-disabled {
    background: #1389ae;
    border-color: #1389ae;
  }
  // .checkButton .is_disabled {
  //   background: #1389ae;
  //   border-color: #1389ae;
  // }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .search-item {
    .el-input {
      width: 207px;
    }
    .el-select {
      width: 207px;
    }
  }
  .tipText {
    display: flex;
    align-items: center;
    color: red;
  }

  .table-wrap {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    flex-grow: 1;
    overflow-y: scroll;
  }
}
</style>
