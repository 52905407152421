<template>
  <div class="table-cnt">
    <el-table
      :data="tableData"
      height="100%"
      :border="true"
      :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    >
      <el-table-column prop="business" align="center" label="集配中心">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.business.name) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="supplier_order_no"
        align="center"
        label="供货单编号"
      >
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.supplier_order.supplier_order_no) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" align="center" label="供货商名称">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.supplier.title) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" align="center" label="采购名称">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.buyer.fullname) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" align="center" label="商品名称(货号)">
        <template slot-scope="scope">
          {{ `${scope.row.product.title}(${scope.row.product.sku_code})` }}
        </template>
      </el-table-column>
      <el-table-column prop="name" align="supplier_order" label="创建时间">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.supplier_order.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="product_amt" align="center" label="商品总金额">
      </el-table-column>
      <el-table-column prop="qc_out_amt" align="center" label="复核缺货金额">
      </el-table-column>
      <el-table-column
        prop="aftersale_amt"
        align="center"
        label="供应商售后扣款金额"
      >
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.aftersale_amt) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="结算状态">
        <template slot-scope="scope">
          {{ "已结算" }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" align="center" label="结算日期">
      </el-table-column>
      <el-table-column
        prop="financial_fee"
        align="center"
        label="金融手续费(0.4%)"
      >
      </el-table-column>
      <el-table-column
        prop="service_charges_fee_real"
        align="center"
        label="第三方服务费"
      >
      </el-table-column>
      <el-table-column prop="settlement_amt" align="center" label="结算金额">
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
        min-width="130"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="onHandelDetails(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "TableList",
  props: ["tableData"],
  components: {},

  data() {
    return {
      detailsVisible: false,
      detailsLoading: false,
      goodsDetail: {}, // 商品详情
    };
  },
  methods: {
    /**
     * 展示详情
     */
    onHandelDetails(row) {
      this.$router.push({
        path: `/third-service-fee-detail`,
        query: {
          sku_code: row.sku_code,
          supplier_order_no: row.supplier_order.supplier_order_no,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-cnt {
  height: 100%;
  .dialogbj {
    // text-align: right;
    i {
      font-size: 30px;
    }
    width: 375px;
    height: 820px;
    position: fixed;
    background-color: #fff;
    top: 50%;
    left: 50%;
    // background-color: #000;

    transform: translateX(-50%) translateY(-50%);
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
</style>
