var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activitycontent", style: [{ background: _vm.background }] },
    [
      _c("div", { staticClass: "navbar" }, [_c("navbar")], 1),
      _c(
        "div",
        { staticStyle: { "padding-top": "60px" } },
        _vm._l(_vm.contentlist, function (item, index) {
          return _c(
            "div",
            { key: item.fieldId, staticClass: "componentborder" },
            [
              _c(
                item.name,
                _vm._b(
                  {
                    tag: "component",
                    attrs: { quality: item.configuration.quality },
                  },
                  "component",
                  item,
                  false
                )
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }