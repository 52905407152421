<template>
  <div class="goodsItem" v-if="additem" >
    
    <div >
        <div class="image">
     <img :src="additem.kv" mode="scaleToFill" style="width: 100%;height: 100%;object-fit: cover;" alt="">
    </div>

    <div class="titles"> {{ additem.title }}</div>
    <div class="numqut"> {{ additem.min_num }}件起售 </div>
    <div class="num-weight-bocak">
      净重约{{ additem.weight }}斤丨约￥{{ additem.weight_price }}/斤</div
    >
    </div>
    <div class="add">
      <div class="num-price"
      
        ><span class="num-price-left">￥</span> {{ additem.price.split(".")[0]
        }}<span class="num-price-last">.{{ additem.price.split(".")[1] }}</span></div
      >

    </div>
  </div>
</template>
      
      <script>

export default {
  name: "goodsItem",
  components: {  },
  props: {
    additem: {
      type: Object,
      default() {
        return {};
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    indexkey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },

  onLoad() {},
  onShow() {},
  mounted() {},
  methods: {
    goodsdetails(e) {
      console.log("11111111");
      uni.navigateTo({
        url: `/pagesB/goodsdetails?id=${e.id}&price=1&sku_code=${e.sku_code}`,
      });
    },
  },
};
</script>
      
      <style lang="scss" >
.goodsItem {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 19px;
  box-sizing: border-box;
  margin-bottom: 11px;
  .image {
    // margin: 10rpx;
    box-sizing: border-box;
    width: 100%;
    height: 195px;
    border-radius: 10px;
  }
  .titles {
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    box-sizing: border-box;
    margin-top: 10px;
  }
  .numqut {
    width: 100%;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #ee7115;
    margin: 7px 0;
    padding: 0 12px;
    box-sizing: border-box;
  }

  .num-weight-bocak {
    margin: 0 12px;

    // height: 36rpx;
    background: #f4f5f6;
    border-radius: 5px;
    padding: 4px;
    box-sizing: border-box;
    background: #f4f5f6;
    font-weight: 400;
    font-size: 13px;
    color: #333333;
  }
  .add {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    flex: 1;
    // .addjia {
    //   width: 52rpx;
    //   height: 52rpx;
    // }
  }
  .num-price {
    margin: 0 5rpx;
    margin-right: 0;
    font-weight: Bold;
    font-size: 24px;
    color: #f35314;
    flex: 1;
    .num-price-left {
      font-size: 12px;
    }
    .num-price-last {
      font-size: 19px;
    }
  }
}
</style>
      