var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-wrap" },
    [
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c("el-button", {
            staticClass: "refresh",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.onHandleRefresh },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleAdd } },
            [_vm._v("新增")]
          ),
          _c(
            "div",
            { staticClass: "seach" },
            [
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择集配中心" },
                      model: {
                        value: _vm.formData.logisticBusinessId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "logisticBusinessId", $$v)
                        },
                        expression: "formData.logisticBusinessId",
                      },
                    },
                    _vm._l(_vm.logisticsList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入城市仓名称", clearable: "" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择状态" },
                      model: {
                        value: _vm.formData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status",
                      },
                    },
                    _vm._l(_vm.DISABLED_LIST, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "inputs" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "选择提现状态" },
                      model: {
                        value: _vm.formData.is_withdrawal,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "is_withdrawal", $$v)
                        },
                        expression: "formData.is_withdrawal",
                      },
                    },
                    _vm._l(_vm.withdrawalList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.tableData.length || _vm.disabledExport,
                    type: "warning",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.onHandleExport },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  width: "100px",
                  align: "center",
                  label: "序号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  width: "100px",
                  align: "center",
                  label: "ID",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  label: "城市仓名称",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.name || "-") + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  align: "center",
                  label: "集配业务中心",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (scope.row.business && scope.row.business.name) ||
                                "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_number",
                  align: "center",
                  label: "自提点数量",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.delivery_number) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", align: "center", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === _vm.DISABLED_ENUM.unDisabled.value
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用"),
                            ])
                          : _vm._e(),
                        scope.row.status === _vm.DISABLED_ENUM.disabled.value
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("禁用"),
                            ])
                          : _vm._e(),
                        scope.row.status === _vm.DISABLED_ENUM.closed.value
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("已关仓"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_withdrawal",
                  align: "center",
                  label: "提现状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status !== _vm.DISABLED_ENUM.closed.value
                          ? _c(
                              "span",
                              {
                                staticClass: "text-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleWithdrawal(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.withdrawalList.find(
                                      (item) =>
                                        item.value ===
                                        (scope.row.is_withdrawal ||
                                          _vm.IS_WITHDRAWAL.no.value)
                                    ).label
                                  )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  label: "创建时间",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.upgrade_position ===
                              _vm.UPGRADE_POSITION_ENUM.common.value &&
                            scope.row.status !== _vm.DISABLED_ENUM.closed.value
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleUpStorage(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("升仓")]
                                )
                              : _vm._e(),
                            scope.row.status !== _vm.DISABLED_ENUM.closed.value
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleEditData(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            scope.row.status !== _vm.DISABLED_ENUM.closed.value
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleDisabled(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.status ===
                                          _vm.DISABLED_ENUM.unDisabled.value
                                          ? "禁用"
                                          : "启用"
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.status !== _vm.DISABLED_ENUM.closed.value
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleClose(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("关仓")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleDeliverDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看自提点")]
                            ),
                            scope.row.status !== _vm.DISABLED_ENUM.closed.value
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleAddDeliver(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("新增自提点")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            scope.row.status !== _vm.DISABLED_ENUM.closed.value
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onHandleMove(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("迁移用户")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("upgrade-store-dialog", {
        ref: "refUpgradeStore",
        attrs: { bankList: _vm.bankList },
        on: {
          "on-show-image": _vm.onHandleChangeData,
          "on-refresh-list": _vm.ajaxGetTableList,
        },
      }),
      _c("edit-store-dialog", {
        ref: "refEditStore",
        attrs: { logisticsList: _vm.logisticsList, bankList: _vm.bankList },
        on: {
          "on-show-image": _vm.onHandleChangeData,
          "on-refresh-list": _vm.ajaxGetTableList,
        },
      }),
      _c("edit-delivery-dialog", {
        ref: "refEditDelivery",
        attrs: { bankList: _vm.bankList },
        on: {
          "on-add-delivery": _vm.onHandleAddDelivery,
          "on-edit-delivery": _vm.onHandleDeliverDetail,
        },
      }),
      _c("delivery-list-dialog", {
        ref: "refDeliveryList",
        on: {
          "on-edit-delivery": _vm.onHandleEditDelivery,
          "on-edit-log": _vm.onHandleEditLog,
        },
      }),
      _c("product-supplement-dialog", {
        ref: "refProductSupplement",
        on: { "on-refresh-list": _vm.ajaxGetTableList },
      }),
      _c("delivery-edit-log-dialog", { ref: "refDeliveryEditLog" }),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showImage },
          on: {
            "update:visible": function ($event) {
              _vm.showImage = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "showImg",
            attrs: { src: _vm.bigImage, alt: "" },
          }),
        ]
      ),
      _c("move-user-dialog", {
        ref: "refMoveUserDialog",
        on: { "on-refresh-list": _vm.ajaxGetTableList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }