<template>
  <div class="Selected-products bj">
    <div class="title">
      <div class="title-query" v-if="hideshow">
        <div class="title-query-input">
          <div class="font">集配中心：</div>
          <div class="input-item">
            <el-select
              v-model="from.logistic_business_id"
              clearable
              filterable
              placeholder="选择集配中心"
            >
              <el-option
                v-for="item of businessList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">一级品类：</div>
          <div class="input-item">
            <el-select
              v-model="from.first_cid"
              clearable
              filterable
              placeholder="选择一级品类"
            >
              <el-option
                v-for="item of CategoriesList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">二级品类：</div>
          <div class="input-item">
            <el-select
              v-model="from.cid"
              filterable
              clearable
              @change="subListcate"
              placeholder="选择二级品类"
            >
              <el-option
                v-for="item of subList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">品种：</div>
          <div class="input-item">
            <el-select
              v-model="from.category_tag_id"
              clearable
              filterable
              placeholder="选择品种"
            >
              <el-option
                v-for="item of catrglist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">商品名称：</div>
          <div class="input-item">
            <el-input
              v-model="from.goodsName"
              placeholder="请输入商品名称"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">规格：</div>
          <div class="input-item">
            <el-input
              v-model="from.specName"
              placeholder="请输入规格"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">商品等级：</div>
          <div class="input-item">
            <el-select
              v-model="from.grade"
              clearable
              placeholder="选择商品等级"
            >
              <el-option
                v-for="item of GRADE_TYPE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">来源：</div>
          <div class="input-item">
            <el-select
              v-model="from.buyer_type"
              clearable
              placeholder="选择来源"
            >
              <el-option
                v-for="item of BUYER_TYPE"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">供应商：</div>
          <div class="input-item">
            <el-input
              v-model="from.supplier_name"
              placeholder="请输入供应商"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">采购：</div>
          <div class="input-item">
            <el-input
              v-model="from.buyer_name"
              placeholder="请输入采购"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">开市价：</div>
          <div class="input-item-tow">
            <el-input
              v-model="from.price_min"
              @blur="handleInputMoney(from.price_min, 'price_min')"
              placeholder="开市价始"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.price_max"
              @blur="handleInputMoney(from.price_max, 'price_max')"
              placeholder="开市价止"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">净重：</div>
          <div class="input-item-tow">
            <el-input
              v-model="from.weight_min"
              @blur="handleInputMoney(from.weight_min, 'weight_min')"
              placeholder="净重始"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.weight_max"
              @blur="handleInputMoney(from.weight_max, 'weight_max')"
              placeholder="净重止"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">毛重：</div>
          <div class="input-item-tow">
            <el-input
              v-model="from.gross_weight_min"
              @blur="
                handleInputMoney(from.gross_weight_min, 'gross_weight_min')
              "
              placeholder="毛重始"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.gross_weight_max"
              @blur="
                handleInputMoney(from.gross_weight_max, 'gross_weight_max')
              "
              placeholder="毛重止"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">5天售后率：</div>
          <div class="input-item-tow">
            <el-input
              v-model="from.aftersale_rate_5_min"
              @blur="
                handleInputMoney(
                  from.aftersale_rate_5_min,
                  'aftersale_rate_5_min'
                )
              "
              placeholder="售后率始"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.aftersale_rate_5_max"
              @blur="
                handleInputMoney(
                  from.aftersale_rate_5_max,
                  'aftersale_rate_5_max'
                )
              "
              placeholder="售后率止"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">3天售后率：</div>
          <div class="input-item-tow">
            <el-input
              v-model="from.aftersale_rate_3_min"
              @blur="
                handleInputMoney(
                  from.aftersale_rate_3_min,
                  'aftersale_rate_3_min'
                )
              "
              placeholder="售后率始"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.aftersale_rate_3_max"
              @blur="
                handleInputMoney(
                  from.aftersale_rate_3_max,
                  'aftersale_rate_3_max'
                )
              "
              placeholder="售后率止"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">产地发货时间：</div>
          <div class="input-item-tow">
            <el-date-picker
              type="datetimerange"
              clearable
              v-model="delivery_time"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="(val) => timechange(val, 'delivery_time')"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">新货到市场时间：</div>
          <div class="input-item-tow">
            <el-date-picker
              type="datetimerange"
              clearable
              v-model="arrival_time"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="(val) => timechange(val, 'arrival_time')"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">剩余货架期/保质期：</div>
          <!-- <div class="input-item-tow">
            <el-date-picker
              type="datetimerange"
              clearable
              v-model="shelf_life"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="(val) => timechange(val, 'shelf_life')"
            >
            </el-date-picker>
          </div> -->
          <div class="input-item-tow">
            <el-input
              v-model="from.shelf_life_min"
              @blur="handleInputMoney(from.shelf_life_min, 'shelf_life_min')"
              placeholder="剩余货架期/保质期"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.shelf_life_max"
              @blur="handleInputMoney(from.shelf_life_max, 'shelf_life_max')"
              placeholder="剩余货架期/保质期"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">库存：</div>
          <div class="input-item-tow">
            <el-input
              v-model="from.stock_min"
              @blur="handleInputMoney(from.stock_min, 'stock_min')"
              placeholder="库存始"
              clearable
            ></el-input>
            <span class="span">至</span>
            <el-input
              v-model="from.stock_max"
              @blur="handleInputMoney(from.stock_max, 'stock_max')"
              placeholder="库存止"
              clearable
            ></el-input>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">提审时间：</div>
          <div class="input-item-tow">
            <el-date-picker
              type="datetimerange"
              clearable
              v-model="audit_time"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="(val) => timechange(val, 'audit_time')"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="title-query-input">
          <div class="font">产地：</div>
          <div class="input-item">
            <el-input
              v-model="from.origin_name"
              placeholder="请输入产地"
              clearable
            ></el-input>
          </div>
        </div>
      </div>
      <div class="title-query-btn">
        <div class="title-query-btn-left">
          <el-button
            v-for="item in taglist"
            :key="item.id"
            :type="from.audit_status == item.id ? 'primary' : ''"
            @click="tagquery(item)"
            >{{ item.name }}({{ tagnum[item.num] }})</el-button
          >
        </div>
        <div class="title-query-btn-right">
          <el-button
            icon="el-icon-s-fold"
            plain
            @click="hideshow = !hideshow"
            >{{ hideshow ? "隐藏" : "展开" }}</el-button
          >
          <el-button type="primary" @click="querylist">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="Reset"
            >重置</el-button
          >
        </div>
      </div>
      <div class="title-query-item">
        <el-tag
          closable
          v-for="(item, index) in tag_list"
          :key="index"
          @close="close(item, index)"
          >{{ item.name }}</el-tag
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="table"
        tooltip-effect="dark"
        style="width: 100%"
        :key="tableKey"
      >
        <el-table-column
          v-for="column in columns"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          align="center"
          :fixed="column.fixed"
          :min-width="column.minWidth"
        >
          <template slot="header" slot-scope="scope">
            <div
              class="title-item"
              @click="sortChange(1)"
              v-if="column.prop == 'price'"
            >
              <div>开市价</div>
              <div class="title-item-icon">
                <i
                  :class="
                    from.sort_key == 'price' && from.sort == 'asc'
                      ? 'el-icon-caret-top active'
                      : 'el-icon-caret-top '
                  "
                ></i>
                <i
                  :class="
                    from.sort_key == 'price' && from.sort == 'desc'
                      ? 'el-icon-caret-bottom active'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </div>
            </div>
            <div
              class="title-item"
              @click="sortChange(2)"
              v-else-if="column.prop == 'weight'"
            >
              <div>净重</div>
              <div class="title-item-icon">
                <i
                  :class="
                    from.sort_key == 'weight' && from.sort == 'asc'
                      ? 'el-icon-caret-top active'
                      : 'el-icon-caret-top '
                  "
                ></i>
                <i
                  :class="
                    from.sort_key == 'weight' && from.sort == 'desc'
                      ? 'el-icon-caret-bottom active'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </div>
            </div>
            <div
              v-else-if="column.prop == 'gross_weight'"
              class="title-item"
              @click="sortChange(3)"
            >
              <div>毛重</div>
              <div class="title-item-icon">
                <i
                  :class="
                    from.sort_key == 'gross_weight' && from.sort == 'asc'
                      ? 'el-icon-caret-top active'
                      : 'el-icon-caret-top '
                  "
                ></i>
                <i
                  :class="
                    from.sort_key == 'gross_weight' && from.sort == 'desc'
                      ? 'el-icon-caret-bottom active'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </div>
            </div>
            <div v-else>{{ column.label }}</div>
          </template>

          <template slot-scope="scope">
            <span v-if="column.prop == 'title'">
              <el-button type="text" @click="onHandelDetails(scope.row)">{{
                scope.row.title
              }}</el-button>
            </span>
            <span v-else-if="column.prop == 'service_charges'">
              {{ $empty.empty(scope.row.service_charges) }}
            </span>
            <span v-else-if="column.prop == 'categories_name'">
              {{ scope.row.categories_name
              }}{{
                scope.row.category_tag_name
                  ? `-${scope.row.category_tag_name}`
                  : ""
              }}
            </span>
            <div
              v-else-if="column.prop == 'kv'"
              @click="onHandelDetails(scope.row)"
              class="imgs"
            >
              <img class="img-box" :src="scope.row.kv" alt="" />
              <span v-if="scope.row.kv_last_time">{{
                timename(scope.row.kv_last_time)
              }}</span>
            </div>
            <div v-else-if="column.prop == 'audit_status'">
              <el-button type="text" 
                >{{scope.row.audit_status_txt}}</el-button
              >
              <!-- <el-button type="text" v-if="scope.row.audit_status == 105"
                >上架</el-button
              >
              <el-button
                type="text"
                v-else-if="
                  scope.row.audit_status == 101 || scope.row.audit_status == 104
                "
                >未审核</el-button
              >
              <el-button type="text" v-else-if="scope.row.audit_status == 106"
                >已下架</el-button
              > -->
            </div>
            <span v-else>
              {{ scope.row[`${column.prop}`] }}
            </span>
          </template>
        </el-table-column>
        <!--
         <el-table-column
          prop="title"
          align="center"
          label="商品名称"
          min-width="200"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="onHandelDetails(scope.row)">{{
              scope.row.title
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="grade" align="center" label="等级">
        </el-table-column>
        <el-table-column prop="spec" align="center" label="规格">
        </el-table-column>
        <el-table-column
          prop="sku_code"
          align="center"
          label="商品编码"
          min-width="150"
        >
        </el-table-column>
        <el-table-column prop="categories_name" align="center" label="品类">
        </el-table-column>
        <el-table-column
          prop="category_tag_name"
          align="center"
          label="品种"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="origin_name"
          align="center"
          label="产地"
          min-width="200"
        >
        </el-table-column>

        <el-table-column
          prop="kv"
          align="center"
          label="商品主图"
          min-width="150"
        >
          <template slot-scope="scope">
            <div @click="onHandelDetails(scope.row)" class="imgs">
              <img class="img-box" :src="scope.row.kv" alt="" />
              <span v-if="scope.row.kv_last_time">{{
                timename(scope.row.kv_last_time)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          align="center"
          label="开市价"
          min-width="150"
        >
          <template slot="header" slot-scope="scope">
            <div class="title-item" @click="sortChange(1)">
              <div>开市价</div>
              <div class="title-item-icon">
                <i
                  :class="
                    from.sort_key == 'price' && from.sort == 'asc'
                      ? 'el-icon-caret-top active'
                      : 'el-icon-caret-top '
                  "
                ></i>
                <i
                  :class="
                    from.sort_key == 'price' && from.sort == 'desc'
                      ? 'el-icon-caret-bottom active'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="weight"
          align="center"
          label="净重"
          min-width="100"
        >
          <template slot="header" slot-scope="scope">
            <div class="title-item" @click="sortChange(2)">
              <div>净重</div>
              <div class="title-item-icon">
                <i
                  :class="
                    from.sort_key == 'weight' && from.sort == 'asc'
                      ? 'el-icon-caret-top active'
                      : 'el-icon-caret-top '
                  "
                ></i>
                <i
                  :class="
                    from.sort_key == 'weight' && from.sort == 'desc'
                      ? 'el-icon-caret-bottom active'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="weight_price"
          align="center"
          label="净单价"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="gross_weight"
          align="center"
          label="毛重"
          min-width="100"
        >
          <template slot="header" slot-scope="scope">
            <div class="title-item" @click="sortChange(3)">
              <div>毛重</div>
              <div class="title-item-icon">
                <i
                  :class="
                    from.sort_key == 'gross_weight' && from.sort == 'asc'
                      ? 'el-icon-caret-top active'
                      : 'el-icon-caret-top '
                  "
                ></i>
                <i
                  :class="
                    from.sort_key == 'gross_weight' && from.sort == 'desc'
                      ? 'el-icon-caret-bottom active'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="gross_weight_price"
          min-width="100"
          align="center"
          label="毛单价"
        >
        </el-table-column>
        <el-table-column
          prop="service_charges"
          align="center"
          label="第三方服务费"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.service_charges) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="source_txt"
          align="center"
          label="来源"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="percent_3_rate"
          align="center"
          label="3天售后率"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="percent_5_rate"
          align="center"
          label="5天售后率"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="delivery_time"
          align="center"
          min-width="200"
          label="产地发货时间"
        >
        </el-table-column>
        <el-table-column
          prop="arrival_time"
          align="center"
          min-width="200"
          label="新货到场时间"
        >
        </el-table-column>
        <el-table-column
          prop="remaining_shelf_life"
          min-width="200"
          align="center"
          label="剩余货架期/保质期"
        >
        </el-table-column>
        <el-table-column prop="stock" align="center" label="库存">
        </el-table-column>
        <el-table-column
          prop="audit_time"
          align="center"
          label="提审时间"
          min-width="200"
        >
        </el-table-column>

        <el-table-column label="状态" align="center" min-width="200">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.audit_status == 105"
              >上架</el-button
            >
            <el-button
              type="text"
              v-else-if="
                scope.row.audit_status == 101 || scope.row.audit_status == 104
              "
              >未审核</el-button
            >
            <el-button type="text" v-else-if="scope.row.audit_status == 106"
              >已下架</el-button
            >
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作"
          align="center"
          min-width="200"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="adopt(scope.row)"
              size="mini"
              v-if="
                scope.row.audit_status == 101 || scope.row.audit_status == 104
              "
              >通过</el-button
            >
            <el-button
              type="danger"
              @click="reject(scope.row)"
              size="mini"
              v-if="
                scope.row.audit_status == 101 || scope.row.audit_status == 104
              "
              >驳回</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div v-if="detailsVisible" class="dialogbj">
      <div style="text-align: right">
        <i class="el-icon-circle-close" @click="detailsVisible = false"></i>
      </div>

      <goods-details
        :loading="detailsLoading"
        :details="goodsDetail"
      ></goods-details>
    </div>

    <el-dialog
      title="是否驳回"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="500px"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="驳回原因" prop="reason">
            <div style="width: 302px">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="ruleForm.reason"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="图片上传">
            <b-file-upload
              key="reject_img"
              :accepts="['jpg', 'jpeg', 'png']"
              :limit="4"
              v-model="detailPicList"
            ></b-file-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ruleFormqr">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GoodsDetails from "../modules/goods-change-dialog/modules/goods-details.vue";
import { getCategoriesList } from "@/api/generalControl/category";
import { getSubList } from "@/api/generalControl/index.js";
import { GRADE_TYPE, BUYER_TYPE, QUERY_TAG_LIST } from "./utils/enum/index.js";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import Sortable from "sortablejs";
export default {
  name: "Selected-products",
  components: { GoodsDetails, BFileUpload },
  data() {
    return {
      tableKey: 0, // 用于强制重新渲染表格
      sortableInstance: null, // Sortable 实例
      columns: [
        { label: "商品名称", prop: "title", fixed: "left", minWidth: "200" },
        { label: "等级", prop: "grade", fixed: "left", minWidth: "150" },
        { label: "规格", prop: "spec", fixed: "left", minWidth: "150" },
        { label: "开市价", prop: "price", minWidth: "150" },
        { label: "净单价", prop: "weight_price", minWidth: "100" },
        { label: "7天售后率", prop: "percent_7_rate", minWidth: "200" },
        { label: "30天售后率", prop: "percent_30_rate", minWidth: "200" },
        { label: "来源", prop: "source_txt", minWidth: "200" },
        { label: "产地", prop: "origin_name", minWidth: "200" },
        { label: "库存", prop: "stock", minWidth: "200" },
        { label: "第三方服务费", prop: "service_charges", minWidth: "150" },
        { label: "品类", prop: "categories_name", minWidth: "150" },
        { label: "商品主图", prop: "kv", minWidth: "150" },
        { label: "商品编码", prop: "sku_code", minWidth: "150" },
        { label: "净重", prop: "weight", minWidth: "100" },
        { label: "毛重", prop: "gross_weight", minWidth: "100" },
        { label: "毛单价", prop: "gross_weight_price", minWidth: "100" },

        { label: "产地发货时间", prop: "delivery_time", minWidth: "200" },
        { label: "新货到场时间", prop: "arrival_time", minWidth: "200" },
        {
          label: "剩余货架期/保质期",
          prop: "remaining_shelf_life",
          minWidth: "200",
        },
        { label: "提审时间", prop: "audit_time", minWidth: "200" },
        { label: "状态", prop: "audit_status", minWidth: "200" },
      ],
      detailsVisible: false,
      detailsLoading: false,
      centerDialogVisible: false,
      goodsDetail: {}, // 商品详情
      ruleForm: {
        product_id: "", // 商品id
        status: 2, // 状态 1 通过 2 驳回
        reason: "", // 驳回原因
        reject_img: "", // 驳回图片数组
      },
      rules: {
        reason: [
          { required: true, message: "请输入驳回原因", trigger: "blur" },
        ],
        // reject_img: [
        //   { required: true, message: "请上传图片", trigger: "change" },
        // ],
      },
      GRADE_TYPE,
      BUYER_TYPE,
      //展开收起
      hideshow: true,
      total: 0,
      currentPage: 0,
      loading: false,
      tableData: [],
      audit_status: "",
      tagnum: {},
      taglist: [
        {
          name: "全部",
          id: "",
          num: "all",
        },
        {
          name: "待审核",
          id: "104",
          num: "no_audit",
        },
        {
          name: "已驳回",
          id: "106",
          num: "reject",
        },
        {
          name: "已通过",
          id: "105",
          num: "audited",
        },
      ],
      logisticList: [],
      from: {
        audit_status: "", //	number	审批状态 104待审核  105通过 106驳回 传空或空字符串为全部
        logistic_business_id: "", //	number	集配中心id
        first_cid: "", //	number	一级品类id
        cid: "", //	number	二级品类id
        category_tag_id: "", //	number	品种id
        grade: "", //	string	等级 A、B、C、D
        buyer_type: "", //	number来源 2招商 3买手
        supplier_name: "", //	string	供应商名称
        goodsName: "", // 商品名称
        specName: "", // 规格
        buyer_name: "", //	string	采购名称
        price_min: "", //	number	开市价 始
        price_max: "", //	number	开市价 终
        weight_min: "", //	number	净重
        weight_max: "", //	number	净重
        gross_weight_min: "", //	number	毛重
        gross_weight_max: "", //	number	毛重
        aftersale_rate_3_min: "", //	number	3天售后率
        aftersale_rate_3_max: "", //	number	3天售后率
        aftersale_rate_5_min: "", //	number	5天售后率
        aftersale_rate_5_max: "", //	number	5天售后率
        delivery_time_min: "", //	string	产地发货时间
        delivery_time_max: "", //	string	产地发货时间
        arrival_time_min: "", //	string	新货到市场时间
        arrival_time_max: "", //	string	新货到市场时间
        shelf_life_min: "", //	string	保质期
        shelf_life_max: "", //	string	保质期
        stock_min: "", //	number	库存
        stock_max: "", //	number	库存
        audit_time_min: "", //	string	提审时间
        audit_time_max: "", //	string	提审时间
        origin_name: "", //	string	产地
        sort_key: "", //	string	排序字段 price weight gross_weight 默认空
        sort: "", //	string	排序方式 asc desc 默认空
        page: 1,
        pageSize: 50,
      },
      delivery_time: [],
      arrival_time: [],
      audit_time: [],
      shelf_life: [],
      businessList: [],
      CategoriesList: [],
      subList: [],
      catrglist: [],
      tag_list: [],
      detailPicList: [], // 图片列表
    };
  },
  watch: {
    /**
     * 图片发生改变
     */
    detailPicList() {
      const res = this.detailPicList?.map((item) => item.url).join(",");
      this.ruleForm.reject_img = res;
      console.log(this.ruleForm, "11111111111");
    },
  },
  mounted() {
    this.initSortable();
    this.hqlist();
    this.getproducttotal();
    this.getSelection();
  },
  methods: {
    // 拖拽表格列
    initSortable() {
      this.$nextTick(() => {
        if (this.sortableInstance) {
          this.sortableInstance.destroy(); // 销毁之前的实例
        }
        const el = this.$refs.table.$el.querySelector(
          ".el-table__header-wrapper tr"
        );

        this.sortableInstance = Sortable.create(el, {
          onEnd: (evt) => {
            console.log(evt, this.columns, "ve---");

            const movedItem = this.columns.splice(evt.oldIndex, 1)[0];
            console.log(movedItem, "eee");
            this.columns.splice(evt.newIndex, 0, movedItem);

            // 强制重新渲染表格
            this.tableKey += 1;

            // 重新初始化 Sortable
            this.initSortable();
          },
          animation: 150,
          ghostClass: "sortable-ghost",
          onStart: (evt) => {
            if (this.columns[evt.oldIndex].fixed) {
              return false; // 禁止固定列的拖动
            }
          },
        });
      });
    },
    /**
     * 余额 文本框 正则表达式来限制输入，只允许负数、正数、0以及小数
     */
    handleInputMoney(value, name) {
      console.log(value, name, "==");
      this.from[name] = value.replace(/[^\d.]/g, "");
      console.log(value, this.from[name], "==");
    },

    close(e, index) {
      this.tag_list.splice(index, 1);
      if (e.type == 2) {
        this.from[e.value[0]] = "";
        this.from[e.value[1]] = "";
      } else {
        this.from[e.value] = "";
      }
      if (e.time) {
        this[e.time] = [];
      }
      this.currentPage = 1;
      this.from.page = 1;
      this.hqlist();
      this.getproducttotal();
    },
    timechange(val, name) {
      if (val) {
        this.from[name + "_min"] = val[0];
        this.from[name + "_max"] = val[1];
      } else {
        this.from[name + "_min"] = "";
        this.from[name + "_max"] = "";
      }
    },
    async getSelection() {
      // 获取集配中心数据
      this.$api.general.businessList().then((res) => {
        this.businessList = res.data;
      });
      this.getAjaxCategoriesList();
      this.getAjaxSubList();
    },
    async getAjaxCategoriesList() {
      let { data } = await getCategoriesList();
      this.CategoriesList = data.filter(
        (item) => item.is_audit_gao != 0 || item.is_audit_xiao != 0
      ); //  数据过滤后端没有筛选条件自己手动过滤
    },
    async getAjaxSubList() {
      try {
        const { data } = await getSubList({ is_audit: 1 });
        this.subList = data;
        console.log("ajax getAjaxSubList", data);
      } catch (err) {
        console.log("ajax getAjaxSubList err", err);
      }
    },
    getproducttotal() {
      this.$api.examine.producttotal(this.from).then((res) => {
        console.log(res);
        this.tagnum = res.data;
      });
    },
    timename(time) {
      // 把开始时间   转为  时间戳
      let times = time + "000"; //后端返回时间戳是秒，没办法比对只能手动拼接三个零
      let result;
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let month = day * 30;
      let now = new Date().getTime();
      let diffValue = now - times;

      if (diffValue < 0) {
        return;
      }
      let monthC = diffValue / month;
      let weekC = diffValue / (7 * day);
      let dayC = diffValue / day;
      let hourC = diffValue / hour;
      let minC = diffValue / minute;
      if (monthC >= 1) {
        if (monthC <= 12) result = "" + parseInt(monthC) + "月前";
        else {
          result = "" + parseInt(monthC / 12) + "年前";
        }
      } else if (weekC >= 1) {
        result = "" + parseInt(weekC) + "周前";
      } else if (dayC >= 1) {
        result = "" + parseInt(dayC) + "天前";
      } else if (hourC >= 1) {
        result = "" + parseInt(hourC) + "小时前";
      } else if (minC >= 1) {
        result = "" + parseInt(minC) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
    subListcate() {
      this.from.category_tag_id = "";
      let list = this.subList.filter((item) => item.id == this.from.cid);
      let listitem = list.length > 0 ? list[0] : "";
      this.catrglist = listitem.tags ? listitem.tags : [];
    },
    Reset() {
      this.delivery_time = [];
      this.arrival_time = [];
      this.audit_time = [];
      this.shelf_life = [];
      this.catrglist = [];
      Object.assign(this.$data.from, this.$options.data().from);
      this.querylist();
      this.getproducttotal();
    },
    querylist() {
      this.taglistname();
      this.currentPage = 1;
      this.from.page = 1;
      this.hqlist();
      this.getproducttotal();
    },
    //匹配展示筛选条件
    taglistname() {
      this.tag_list = [];
      QUERY_TAG_LIST.forEach((el) => {
        // console.log(el, "111111111");
        let name = "";
        if (el.type == 2) {
          if (this.from[el.value[0]] || this.from[el.value[1]]) {
            name =
              el.label +
              ":" +
              this.from[el.value[0]] +
              "至" +
              this.from[el.value[1]];
            this.tag_list.push({
              name: name,
              value: el.value,
              type: el.type,
              time: el.time,
            });
          }
        } else if (el.type == 1) {
          if (this.from[el.value]) {
            name = el.label + ":" + this.from[el.value];
            this.tag_list.push({
              name: name,
              value: el.value,
              type: el.type,
              time: el.time,
            });
          }
        } else {
          // 循环匹配值
          if (this.from[el.value]) {
            if (el.listname) {
              this[el.listname].forEach((item) => {
                if (item.id == this.from[el.value]) {
                  name = el.label + ":" + item.name;
                }
              });
            } else {
              BUYER_TYPE.forEach((item) => {
                if (item.id == this.from[el.value]) {
                  name = el.label + ":" + item.name;
                }
              });
            }
            this.tag_list.push({
              name: name,
              value: el.value,
              type: el.type,
              time: el.time,
            });
          }
        }
        //
      });
    },

    sortChange(e) {
      if (e == 1) {
        this.from.sort_key = "price";
      } else if (e == 2) {
        this.from.sort_key = "weight";
      } else {
        this.from.sort_key = "gross_weight";
      }
      this.from.sort = this.from.sort == "asc" ? "desc" : "asc";
      this.hqlist();
    },
    /**
     * 展示详情
     */
    onHandelDetails(row) {
      this.detailsVisible = true;
      this.detailsLoading = true;
      this.goodsDetail = row;
      // 模拟loading
      setTimeout(() => {
        this.detailsLoading = false;
      }, 500);
    },
    //状态筛选
    tagquery(item) {
      this.from.audit_status = item.id;
      this.hqlist();
    },
    adopt(el) {
      this.$confirm(
        `${el.title}[等级${el.grade}]  [${el.sku_code}]`,
        "是否确定审核通过？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      ).then(() => {
        this.$api.examine
          .producthandle({
            product_id: el.id, // 商品id
            status: 1, // 状态 1 通过 2 驳回
            reason: "", // 驳回原因
            reject_img: "", // 驳回图片数组
          })
          .then((res) => {
            this.getproducttotal();
            this.hqlist();
          })
          .catch(() => {});
      });
    },
    reject(item) {
      this.ruleForm.product_id = item.id;
      this.ruleForm.reason = "";
      this.ruleForm.reject_img = "";
      this.detailPicList = [];
      this.centerDialogVisible = true;
    },
    ruleFormqr() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.$api.examine.producthandle(this.ruleForm).then((res) => {
            this.getproducttotal();
            this.hqlist();
            this.centerDialogVisible = false;
          });
        }
      });
    },

    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.examine.productlist(this.from).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
  created() {},
};
</script>

<style lang="scss">
.Selected-products {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .contents {
    padding-top: 20px;
  }
  .active {
    color: #42be85;
  }
  .title {
    background-color: #fff;
    padding: 10px;
    .title-query {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #999;
      &-input {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        .font {
        }
        .input-item {
          width: 200px;
        }
        .input-item-tow {
          width: 300px;
          display: flex;
          align-items: center;
          .span {
            margin: 0 10px;
            color: #888;
            font-size: 12px;
          }
        }
      }
    }
    .title-query-btn {
      display: flex;

      padding-top: 10px;
      &-left {
        flex: 1;
      }
      &-right {
      }
    }
    .title-query-item {
      margin: 10px 0;
      .el-tag {
        margin-right: 10px;
      }
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .imgs {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-box {
    width: 20px;
    height: 20px;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .title-item {
    display: flex;
    align-items: center;
    justify-content: center;
    .title-item-icon {
      display: flex;
      flex-direction: column;
    }
  }
  .dialogbj {
    z-index: 999 !important;
    // text-align: right;
    i {
      font-size: 30px;
    }
    width: 375px;
    height: 820px;
    position: fixed;
    background-color: #fff;
    top: 50%;
    left: 50%;
    // background-color: #000;

    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
