var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template2" }, [
    _c(
      "div",
      {
        staticClass: "template2-title",
        style: {
          backgroundImage: `url(${
            _vm.objitem.head_url
              ? _vm.objitem.head_url
              : _vm.objitemcopy.head_url
          })`,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "template2-title-btn",
            style: {
              backgroundImage: `url(${
                _vm.objitem.main_url
                  ? _vm.objitem.main_url
                  : _vm.objitemcopy.main_url
              })`,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "text",
                style: {
                  color: `${
                    _vm.objitem.btn_color
                      ? _vm.objitem.btn_color
                      : _vm.objitemcopy.btn_color
                  }`,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.objitem.region_name
                      ? _vm.objitem.region_name
                      : "更多好物"
                  )
                ),
              ]
            ),
          ]
        ),
        _vm.objitem.more_url
          ? _c("img", {
              staticClass: "infobtn",
              attrs: { src: _vm.objitem.more_url, alt: "" },
            })
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "template2-cnt-list" }, [
      _c(
        "div",
        { staticClass: "left" },
        _vm._l(_vm.listleft, function (item, index) {
          return _c(
            "div",
            { key: item.id },
            [
              _c("goodsItem", {
                attrs: { indexkey: index, additem: item, list: _vm.listleft },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "right" },
        _vm._l(_vm.listleft, function (item, index) {
          return _c(
            "div",
            { key: item.id },
            [
              _c("goodsItem", {
                attrs: { indexkey: index, additem: item, list: _vm.listleft },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }