var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Backgroundimage" },
    [
      _c(
        "el-form",
        {
          ref: "childForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.quality, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "图片：", prop: "bg_url" } },
            [
              _c("b-file-upload", {
                key: "bg_url",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.detailPicList,
                  callback: function ($$v) {
                    _vm.detailPicList = $$v
                  },
                  expression: "detailPicList",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }