<template>
  <el-dialog
    title="身份信息"
    :visible.sync="showInfo"
    v-if="showInfo"
    width="500px"
    class="dialog"
    center
  >
    <div class="infoWrap">
      <div class="itemWrap">
        <div class="cellWrap">身份证正面</div>
        <div>
          <el-image
            class="imgWrap"
            :src="type == 1 ? detailInfo.idcard_a : detailInfo.old_idcard_a"
            :preview-src-list="[
              type == 1 ? detailInfo.idcard_a : detailInfo.old_idcard_a,
            ]"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div
          class="cellWrap handWrap"
          @click="
            downLoad(
              type == 1
                ? detailInfo.idcard_a_download
                : detailInfo.old_idcard_a_download
            )
          "
        >
          点击下载
        </div>
      </div>
      <div class="itemWrap">
        <div class="cellWrap">身份证反面</div>
        <div>
          <el-image
            class="imgWrap"
            :src="type == 1 ? detailInfo.idcard_b : detailInfo.old_idcard_b"
            :preview-src-list="[
              type == 1 ? detailInfo.idcard_b : detailInfo.old_idcard_b,
            ]"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div
          v-if="detailInfo.idcard_b_download"
          class="cellWrap handWrap"
          @click="
            downLoad(
              type == 1
                ? detailInfo.idcard_b_download
                : detailInfo.old_idcard_b_download
            )
          "
        >
          点击下载
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "EditDataDialog",
  props: {},
  data() {
    return {
      showInfo: false,
      detailInfo: {},
      type: "",
    };
  },
  methods: {
    async downLoad(url) {
      let aLink = document.createElement("a");
      aLink.style.display = "none";
      const arr = url.split(".");
      aLink.href = url;
      aLink.download = `身份信息.${arr[arr.length - 1]}`;
      document.body.appendChild(aLink);
      console.log(aLink, arr, "==----");
      aLink.click();
      document.body.removeChild(aLink);
    },
    /**
     * 初始化
     */
    async onInitData(row, type) {
      this.detailInfo = row;
      this.type = type;
      this.showInfo = true;
    },

    /**
     * 关闭操作
     */
    onHandleClose() {
      this.showInfo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoWrap {
  display: flex;
  .itemWrap {
    width: 50%;
    text-align: center;
    .cellWrap {
      margin: 10px 0px;
    }
    .imgWrap {
      width: 150px;
      height: 150px;
    }
    .handWrap {
      cursor: pointer;
    }
    .image-slot {
      text-align: center;
      background: #f5f7fa;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
    }
  }
}
</style>
