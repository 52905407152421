var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "productsStatistics" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "seach" },
        [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    filterable: "",
                    clearable: "",
                    placeholder: "选择推荐人所在仓",
                  },
                  model: {
                    value: _vm.from.logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "logistics_id", $$v)
                    },
                    expression: "from.logistics_id",
                  },
                },
                _vm._l(_vm.memberlist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入推荐人手机号", clearable: "" },
                model: {
                  value: _vm.from.member_mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "member_mobile", $$v)
                  },
                  expression: "from.member_mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入推荐人姓名", clearable: "" },
                model: {
                  value: _vm.from.member_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "member_name", $$v)
                  },
                  expression: "from.member_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "span" }, [_vm._v("供货周期：")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  clearable: false,
                  "value-format": "yyyy-MM-dd",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                on: { change: _vm.createChange },
                model: {
                  value: _vm.datevalue,
                  callback: function ($$v) {
                    _vm.datevalue = $$v
                  },
                  expression: "datevalue",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    "collapse-tags": "",
                    filterable: "",
                    clearable: "",
                    placeholder: "选择标签",
                  },
                  model: {
                    value: _vm.from.member_label_ids,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "member_label_ids", $$v)
                    },
                    expression: "from.member_label_ids",
                  },
                },
                _vm._l(_vm.memberlabelalllist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", icon: "el-icon-upload2" },
              on: { click: _vm.exportHandle },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "share_date", align: "center", label: "供货周期" },
            }),
            _c("el-table-column", {
              attrs: { prop: "member_name", align: "center", label: "推荐人" },
            }),
            _c("el-table-column", {
              attrs: { prop: "mobile", align: "center", label: "推荐人手机号" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logistics_name",
                align: "center",
                label: "推荐人所在仓",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "share_user_num",
                align: "center",
                label: "查看人次",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchase_user_num",
                align: "center",
                label: "实际购买人次",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "qty", align: "center", label: "实际购买件数" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "share_rate",
                align: "center",
                label: "推品成功率",
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _vm._v(" 推品成功率 "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "实际购买人次/查看人次*100%；",
                            placement: "top",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning" })]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logistics_qty_txt",
                align: "center",
                label: "下单城市仓",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.seeinfo(scope.row)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }