<template>
  <div class="productsDetails">
    <div class="title">
      <div style="margin-right: 20px">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from.logistics_id"
            multiple
            collapse-tags
            filterable
            clearable
            placeholder="选择推荐人所在仓"
          >
            <el-option
              v-for="item in memberlist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.member_mobile"
            placeholder="请输入推荐人手机号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.member_name"
            placeholder="请输入推荐人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.product_name"
            placeholder="请输入推荐人商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.sku_code"
            placeholder="请输入推荐商品货号"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <el-select
            v-model="from.member_label_ids"
            multiple
            collapse-tags
            filterable
            clearable
            placeholder="选择标签"
          >
            <el-option
              v-for="item in memberlabelalllist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.first_cid"
            clearable
            filterable
            placeholder="请选择一级品类"
          >
            <el-option
              v-for="item of CategoriesList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.cid"
            clearable
            filterable
            placeholder="请选择二级品类"
          >
            <el-option
              v-for="item of subList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <span class="span">供货周期：</span>
          <el-date-picker
            v-model="datevalue"
            :clearable="false"
            type="daterange"
            @change="createChange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="share_date" align="center" label="供货周期">
        </el-table-column>
        <el-table-column prop="member_name" align="center" label="推荐人">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="推荐人手机号">
        </el-table-column>
        <el-table-column
          prop="logistics_name"
          align="center"
          label="推荐人所在仓"
        >
        </el-table-column>
        <el-table-column prop="first_cid_name" align="center" label="一级品类">
        </el-table-column>
        <el-table-column prop="cid_name" align="center" label="二级品类">
        </el-table-column>
        <el-table-column prop="product_name" align="center" label="商品名称">
        </el-table-column>
        <el-table-column prop="sku_code" align="center" label="商品货号">
        </el-table-column>
        <el-table-column prop="share_user_num" align="center" label="查看人次">
        </el-table-column>
        <el-table-column
          prop="purchase_user_num"
          align="center"
          label="实际购买人次"
        >
        </el-table-column>
        <el-table-column prop="qty" align="center" label="实际购买件数">
        </el-table-column>
        <el-table-column prop="share_rate" align="center" label="推品成功率">
          <template slot="header" slot-scope="scope">
            推品成功率
            <el-tooltip
              class="item"
              effect="dark"
              content="实际购买人次/查看人次*100%；"
              placement="top"
            >
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="logistics_qty_txt"
          align="center"
          label="下单城市仓"
        >
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
      <script>
import { postExportgroupdetaildata } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { formatDate } from "@/utils/tools/date.js";
import { getCategoriesList } from "@/api/generalControl/category";
import { getSubList } from "@/api/generalControl/index.js";
export default {
  name: "productsDetails",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        start_date: formatDate(), //	string	开始日期
        end_date: formatDate(), //	string	结束日期
        member_name: "", //	string	推荐人姓名
        member_mobile: "", //	string	推荐人手机号
        member_label_ids: "", //	string []用户标签	 string
        sku_code: "", //	string	商品sku
        product_name: "", //	string	商品名称
        cid: "", //	number	二级品类id
        first_cid: "", //	number	一级品类id
        page: 1,
        pageSize: 50,
        logistics_id:[]
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      options: [],
      optionsUserd: [],
      memberlabelalllist: [],
      memberlist: [],
      datevalue: [formatDate(), formatDate()],
      subList: [],
      CategoriesList: [],
    };
  },
  created() {
    if (this.$route.query.mobile) {
      this.from.member_mobile = this.$route.query.mobile;
      this.from.start_date = this.$route.query.share_date;
      this.from.end_date = this.$route.query.share_date;
      this.datevalue = [this.from.start_date, this.from.end_date];
    }
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getAjaxCategoriesList();
    this.getAjaxSubList();
    this.getmemberlabelall();
    this.getlogisticsselect();
  },
  methods: {
    getlogisticsselect() {
      this.$api.Followup.logisticsselect().then((res) => {
        this.memberlist = res.data;
      });
    },
    async getAjaxCategoriesList() {
      let { data } = await getCategoriesList();
      this.CategoriesList = data;
    },
    // 二级分类列表
    async getAjaxSubList() {
      try {
        const { data } = await getSubList();
        this.subList = data;
        console.log("ajax getAjaxSubList", data);
      } catch (err) {
        console.log("ajax getAjaxSubList err", err);
      }
    },
    createChange(val) {
      console.log(val);
      if (val) {
        this.from.start_date = val[0];
        this.from.end_date = val[1];
      } else {
        this.from.start_date = formatDate();
        this.from.end_date = formatDate();
        this.datevalue = [formatDate(), formatDate()];
      }
    },
    getmemberlabelall() {
      this.$api.member.memberlabelall().then((res) => {
        console.log(res);
        this.memberlabelalllist = res.data;
      });
    },
    //导出
    exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      postExportgroupdetaildata(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },

    hqlist() {
      this.loading = true;
      this.$api.share
        .getsharemembergroupdetaillist(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    addclick() {
      console.log("新增");
    },

    Refresh() {
      this.datevalue = [formatDate(), formatDate()];
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
      <style lang="scss">
.productsDetails {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      // justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .span {
          width: 100px;
        }
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
      
    