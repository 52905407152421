import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-商品管理-在售商品-查看变更记录
 * @param {*} params
 * @returns
 */
export function getProductChangeRecord(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/change/record/list`,
    data: params,
  });
}

/**
 * 总控-商品管理-编辑商品-选择品类-所有三级
 * @param {*} params
 * @returns
 */
export function postProductCategoryList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/category/list`,
    data: params,
  });
}

/**
 * 总控-商品管理-编辑商品-商品规格单位列表
 * @param {*} params
 * @returns
 */
export function postProductUnitList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/unit/list`,
    data: params,
  });
}

/**
 * 总控-商品管理-编辑商品-产地列表
 * @param {*} params
 * @returns
 */
export function postProductAreaList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/area/list`,
    data: params,
  });
}
