var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template1" },
    [
      _c(
        "el-form",
        {
          ref: "childForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.quality, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分区：", prop: "region_name" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入分区",
                  maxlength: "6",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.quality.region_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.quality, "region_name", $$v)
                  },
                  expression: "quality.region_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分区标题背景图：", prop: "head_url" } },
            [
              _c("b-file-upload", {
                key: "head_url",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.head_url_List,
                  callback: function ($$v) {
                    _vm.head_url_List = $$v
                  },
                  expression: "head_url_List",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分区标题按钮背景图：", prop: "main_url" } },
            [
              _c("b-file-upload", {
                key: "main_url",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.main_url_List,
                  callback: function ($$v) {
                    _vm.main_url_List = $$v
                  },
                  expression: "main_url_List",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "按钮文字颜色：", prop: "main_url" } },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.quality.btn_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.quality, "btn_color", $$v)
                  },
                  expression: "quality.btn_color",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分区标题跳转按钮图：", prop: "more_url" } },
            [
              _c("b-file-upload", {
                key: "more_url",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.more_url_List,
                  callback: function ($$v) {
                    _vm.more_url_List = $$v
                  },
                  expression: "more_url_List",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "分区详情页面轮播图图片：", prop: "banner_url" },
            },
            [
              _c("b-file-upload", {
                key: "banner_url",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.banner_url_List,
                  callback: function ($$v) {
                    _vm.banner_url_List = $$v
                  },
                  expression: "banner_url_List",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }