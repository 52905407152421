<template>
  <div class="decoration">
    <moduleView class="decoration-left"></moduleView>

    <contentView
      class="decoration-cnt"
      :receivedList="modulelist"
      :obj_item="obj_item"
    ></contentView>

    <div class="decoration-right">
      <qualityView :id="id" :obj_item="obj_item"></qualityView>
    </div>
  </div>
</template>
  
  <script>
import contentView from "./components/content/index.vue";
import moduleView from "./components/module/index.vue";
import qualityView from "./components/quality/index.vue";
export default {
  name: "",
  components: { contentView, moduleView, qualityView },
  props: {},
  data() {
    return {
      modulelist: [],
      obj_item: {},
      id:''
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.hqlayout();
    }
  },
  mounted() {},
  methods: {
    //获取布局页面
    hqlayout() {
      this.$api.decoration
        .template_detail({ id: this.$route.query.id })
        .then((res) => {
          console.log(res, "装修数据返回");
          this.obj_item = res.data;
          this.modulelist = res.data.obj_item ? res.data.obj_item : [];
          this.modulelist.forEach((el, index) => {
            el.configuration.quality.id = res.data.content[index].id;
          });
        });
      // this.$q
      //   .get(`${this.$BASE.PRO}/api/bms/decoration/get`, {
      //     params: { const: this.const },
      //   })
      //   .then((res) => {
      //     console.log(res.setting_value, "装修数据返回");
      //     this.modulelist = res.setting_value;
      //   });
    },
  },
};
</script>
  
  <style  lang="scss">
.decoration {
  display: flex;
  height: 100%;
  // padding: 15px 0;
  box-sizing: border-box;
  .decoration-cnt {
    width: 50%;
    margin: 0 10px;
    box-sizing: border-box;
    // background: #FFFFFF;
  }
  .decoration-left {
    width: 20%;
    height: 100%;
  }
  .decoration-right {
    width: 30%;
    height: 100%;
  }
}
</style>
  