<template>
  <div class="productsStatistics">
    <div class="title">
      <div style="margin-right: 10px">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-date-picker
            v-model="from.supply_time"
            type="date"
            :clearable="false"
            value-format="yyyy-MM-dd"
            placeholder="选择供货日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.first_cid"
            clearable
            filterable
            placeholder="请选择一级品类"
          >
            <el-option
              v-for="item of searchdata.first_category_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.second_cid"
            clearable
            filterable
            placeholder="请选择二级品类"
          >
            <el-option
              v-for="item of searchdata.second_category_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.third_cid"
            clearable
            filterable
            placeholder="请选择三级品类"
          >
            <el-option
              v-for="item of searchdata.category_tag_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from.logistics_business_id"
            clearable
            filterable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of searchdata.business_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-date-picker
            v-model="from.create_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择创建日期"
          >
          </el-date-picker>
        </div>

        <div class="inputs">
          <el-input
            v-model="from.product_title"
            placeholder="请输入商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.sku_code"
            placeholder="请输入货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.supplier_title"
            placeholder="请输入供应商名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.buyer_name"
            placeholder="请输入采购名称"
            clearable
          ></el-input>
        </div>

        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="title" align="center" label="供货日">
            <template slot-scope="scope">
           {{  from1.supply_time}}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="商品名称">
            <template slot-scope="scope">
           {{  scope.row.title}}({{  scope.row.sku_code}})
          </template>
        </el-table-column>
        <el-table-column prop="first_cid_name" align="center" label="一级品类">
        </el-table-column>
        <el-table-column prop="cid_name" align="center" label="二级品类">
        </el-table-column>
        <el-table-column prop="tag_name" align="center" label="三级品类">
        </el-table-column>
        <el-table-column prop="price" align="center" label="开市价">
        </el-table-column>
        <el-table-column prop="supplier_name" align="center" label="供货商">
        </el-table-column>
        <el-table-column prop="buyer_name" align="center" label="采购员">
        </el-table-column>

        <el-table-column prop="create_at" align="center" label="创建时间">
        </el-table-column>
        <el-table-column prop="stock" align="center" label="库存数量">
        </el-table-column>
        <el-table-column prop="promotion_tag" align="center" label="标签">
        </el-table-column>
        <el-table-column prop="weight" align="center" label="净重 ">
        </el-table-column>
        <el-table-column prop="weight_price" align="center" label="净单价">
        </el-table-column>
        <el-table-column prop="score_30_day" align="center" label="总得分30天得分">
        </el-table-column>
        <el-table-column prop="qty" align="center" label="销售总件数">
        </el-table-column>
        <el-table-column prop="all_weight" align="center" label="销售总毛重（斤）">
        </el-table-column>
        <el-table-column prop="all_price" align="center" label="销售总金额">
        </el-table-column>
        <el-table-column prop="member_count" align="center" label="累次购买人次">
        </el-table-column>
        <el-table-column prop="tdc_count" align="center" label="辐射城市仓数量">
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
          <script>
import { postExportgroupdata } from "@/api/allExport";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { formatDate } from "@/utils/tools/date.js";

export default {
  name: "productsStatistics",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        supply_time: formatDate(), //	T文本	是	供货日时间
        first_cid: "", //	T文本	否	一级品类id
        second_cid: "", //	T文本	否	二级品类id
        third_cid: "", //	T文本	否	三级品类id
        logistics_business_id: "", //	T文本	否集配中心id
        create_at: "", //	T文本	否	创建时间
        product_title: "", //	T文本	否	产品名称搜索
        sku_code: "", //	T文本	否	sku_code搜索
        supplier_title: "", //	T文本	否	供货商名称搜索
        buyer_name: "", //	T文本	否	采购名称搜索
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      subList: [],
      CategoriesList: [],
      threelist: [],
      datevalue: [formatDate(), formatDate()],
      searchdata: "",
    };
  },
  created() {
    this.from1 = JSON.parse(JSON.stringify(this.from));
    
    this.getsearchdata();
  },
  methods: {
    getsearchdata() {
      this.$api.general.searchdata().then((res) => {
        console.log(res);
        this.searchdata = res.data;
     this.from.logistics_business_id=   this.searchdata.business_list.length>0?this.searchdata.business_list[0].id:''
     this.from1.logistics_business_id=   this.searchdata.business_list.length>0?this.searchdata.business_list[0].id:''
     this.hqlist();
      });
    },



    //导出
    exportHandle() {
        this.$api.Sales.sellDayexport(this.from1).then((res) => {
        console.log(res);
        this.goExportCenter()
      });
    },
    /**
     * 导出后引导弹框
     */
     goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    hqlist() {
      this.loading = true;
      this.$api.Sales.getsellstatday(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.datevalue = [formatDate(), formatDate()];
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
          <style lang="scss">
.productsStatistics {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        // width: 207px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .span {
          width: 100px;
        }
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
          
        