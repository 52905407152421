<template>
  <div
    class="navbar"
    :style="{
      backgroundImage: `url(${
        foundationitem.bg_url ? foundationitem.bg_url : main_url
      })`,
    }"
  >
    <i class="el-icon-arrow-left"></i>
    {{ title }}
  </div>
</template>
  
  <script>
export default {
  name: "navbar",
  components: {},
  props: {},
  data() {
    return {
      title: "活动名称",
      main_url: "",
    };
  },
  computed: {
    foundationitem() {
      return this.$store.state.foundation;
    },
  },
  watch: {
    // quality: {
    //   handler: function (newValue, oldValue) {
    //     console.log("quality参数", newValue);
    //     this.tableDatacolumn = newValue.tableDatacolumn;
    //   },
    //   deep: true,
    // },
  },
  created() {
    // this.tableDatacolumn = this.quality.tableDatacolumn;
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="scss">
.navbar {
  width: 100%;
  height: 80px;
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  i {
    font-weight: bold;
    font-size: 28px;
    color: #000000;
    position: absolute;
    left: 18px;
    top: 30px;
  }
}
</style>
  