<template>
  <div class="template1">
    <el-form
      :model="quality"
      ref="childForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="分区：" prop="region_name">
        <el-input
          type="text"
          placeholder="请输入分区"
          v-model="quality.region_name"
          maxlength="6"
          show-word-limit
        >
        </el-input>
        <!-- <el-select
          v-model="quality.id"
          filterable
          placeholder="请选择分区"
        >
          <el-option
            @click.native="quality.title = item.title"
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="分区标题背景图：" prop="head_url">
        <b-file-upload
          key="head_url"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="head_url_List"
        ></b-file-upload>
      </el-form-item>
      <el-form-item label="分区标题按钮背景图：" prop="main_url">
        <b-file-upload
          key="main_url"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="main_url_List"
        ></b-file-upload>
      </el-form-item>
      <el-form-item label="按钮文字颜色：" prop="main_url">
        <el-color-picker v-model="quality.btn_color"></el-color-picker>
      </el-form-item>
      <el-form-item label="分区标题跳转按钮图：" prop="more_url">
        <b-file-upload
          key="more_url"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="more_url_List"
        ></b-file-upload>
      </el-form-item>
      <el-form-item label="分区详情页面轮播图图片：" prop="banner_url">
        <b-file-upload
          key="banner_url"
          :accepts="['jpg', 'jpeg', 'png']"
          :limit="1"
          v-model="banner_url_List"
        ></b-file-upload>
      </el-form-item>
    </el-form>
  </div>
</template>
    
    <script>
import BFileUpload from "@/components/business/BFileUpload/index.vue";
export default {
  name: "template1",
  components: { BFileUpload },
  props: {
    // 字符串
    configuration: {
      type: Object,
      default: {
        quality: {
          head_url: "", //顶部背景图片
          main_url: "", //按钮背景图
          more_url: "", //查看更多按钮
          banner_url: "", //专区详情轮播图
          region_name: "", //标题
          id: "",
          btn_color: "",
        },
      },
    },
  },
  data() {
    return {
      options: [],
      quality: {
        head_url: "", //顶部背景图片
        main_url: "", //按钮背景图
        more_url: "", //查看更多按钮
        banner_url: "", //查看更多按钮
        // title: "", //标题
        id: "",
        region_name: "",
        btn_color: "",
      },
      region_name: "",
      head_url_List: [],
      main_url_List: [],
      more_url_List: [],
      banner_url_List: [],
    };
  },
  computed: {},
  watch: {
    /**
     * 图片发生改变
     */
    head_url_List() {
      const res = this.head_url_List?.map((item) => item.url).join(",");
      this.quality.head_url = res;
    },
    /**
     * 图片发生改变
     */
    main_url_List() {
      const res = this.main_url_List?.map((item) => item.url).join(",");
      this.quality.main_url = res;
    },
    /**
     * 图片发生改变
     */
    more_url_List() {
      const res = this.more_url_List?.map((item) => item.url).join(",");
      this.quality.more_url = res;
    },
    /**
     * 图片发生改变
     */
    banner_url_List() {
      const res = this.banner_url_List?.map((item) => item.url).join(",");
      this.quality.banner_url = res;
    },
    quality: {
      handler: function (newValue, oldValue) {
        console.log("quality参数属性", newValue);
        this.$emit("quality", this.quality);
      },
      deep: true,
    },
    configuration: {
      handler: function (newValue, oldValue) {
        console.log("configuration参数属性", newValue);
        this.hqinfo(newValue);
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.hqinfo(this.configuration);

    this.hqlist();
  },
  methods: {
    changename() {
      this.quality.region_name = this.region_name;
    },
    hqinfo(e) {
      this.quality = e.quality;
      // this.region_name= e.quality.region_name;
      this.head_url_List = e.quality.head_url
        ? [{ url: e.quality.head_url, name: "food.jpeg", uid: 1 }]
        : [];
      this.main_url_List = e.quality.main_url
        ? [{ url: e.quality.main_url, name: "food.jpeg", uid: 2 }]
        : [];
      this.more_url_List = e.quality.more_url
        ? [{ url: e.quality.more_url, name: "food.jpeg", uid: 3 }]
        : [];
      this.banner_url_List = e.quality.banner_url
        ? [{ url: e.quality.banner_url, name: "food.jpeg", uid: 4 }]
        : [];
    },
    hqlist() {
      // this.$api.decoration.region_list().then((res) => {
      //   this.options = res.data;
      // });
    },
  },
};
</script>
    
    <style  lang="scss">
.template1 {
}
</style>
    