<template>
  <div class="template1">
    <div
      class="template1-title"
      :style="{ backgroundImage: `url(${objitem.head_url?objitem.head_url:objitemcopy.head_url})` }"
    >
      <div
        class="template1-title-btn"
        :style="{ backgroundImage: `url(${objitem.main_url?objitem.main_url:objitemcopy.main_url})` }"
      >
        <div class="text" :style="{color:`${objitem.btn_color?objitem.btn_color:objitemcopy.btn_color}`}">{{ objitem.region_name?objitem.region_name:'专区名称' }}</div>
      </div>
      <img class="infobtn"  v-if="objitem.more_url" :src="objitem.more_url" alt="" />
    </div>


    <div class="template1-list-wrap">
    
          <div  style="display: flex;
            gap: 8px;" >
            <div class="template1-item" v-for="(item, childIndex) in listleft" :key="childIndex"  >
            <div class="image">
             <img :src="item.kv" style="width: 100%;height: 100%;object-fit: cover;" alt="">
            </div>
            <div class="titles"> {{ item.title }}</div>
            <div class="numqut"> {{ item.min_num }}件起售 </div>
            <div class="num-weight-bocak">约￥{{ item.gross_weight }}/斤</div>
            <div class="add">
              <div class="num-price"
                ><span class="left">￥</span> {{ item.price.split(".")[0]
                }}<span class="last"
                  >.{{ item.price.split(".")[1] }}</span
                ></div
              >
              
            </div>
          </div>
          </div>
       
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    // 配置筛选项
    quality: {
      type: [Object, Array],
      default: function() {
        //这里默认return一个数组，即可解决上述的报错问题
        return {
          head_url: "", //顶部背景图片
          main_url: "", //按钮背景图
          more_url: "", //查看更多按钮
          region_name: "", //标题
          id: "",
        };
      }
    },
    // //下拉框数据值

    // selected: {
    //   type: Object,
    //   default: function() {
    //     //这里默认return一个数组，即可解决上述的报错问题
    //     return {};
    //   }
    // }
  },

  data() {
    return {
      objitem:{
        head_url:'',
        main_url:'',
        region_name:'专区名称',
        more_url:'',
        id:''
      },
      objitemcopy:{
      
      },
      listleft: [
        {
          id: 1,
          kv: "https://file-purchase.hbxinfadi.com/supplier/common/2412/202412031755069BQX17DIPHTK1N2V5W.jpg",
          min_num: 1,
          title: "测试供应商上架审核时间-2",
          price: "90.00",
          weight: "17.00",
          weight_price: "5.29",
          gross_weight:'99.00',
        },
        {
          id: 2,
          kv: "https://file-purchase.hbxinfadi.com/supplier/common/2412/202412031755069BQX17DIPHTK1N2V5W.jpg",
          min_num: 1,
          title: "测试供应商上架审核时间-2",
          price: "90.00",
          weight: "17.00",
          weight_price: "5.29",
          gross_weight:'99.00',
        },
        {
          id: 3,
          kv: "https://file-purchase.hbxinfadi.com/supplier/common/2412/202412031755069BQX17DIPHTK1N2V5W.jpg",
          min_num: 1,
          title: "测试供应商上架审核时间-2",
          price: "90.00",
          weight: "17.00",
          weight_price: "5.29",
          gross_weight:'99.00',
        },
      ],
    };
  },
  computed: {},
  watch: {
    quality: {
      handler: function(newValue, oldValue) {
        console.log("quality参数", newValue);
      this.objitem=newValue;
      },
      deep: true
    },
    // selected: {
    //   handler: function(newValue, oldValue) {
    //     console.log(newValue, "sadsadasdasd1");
    //     this.selectedname = newValue;
    //   },
    //   deep: true
    // }
  },
  created() {
    this.objitem=this.quality;
    this.objitemcopy = JSON.parse(JSON.stringify(this.objitem));
    // if (!Array.isArray(this.quality)) {
    //   this.formList = this.quality.formList;
    // } else {
    //   this.formList = [];
    // }
    // this.btnlist = this.quality.btnlist ? this.quality.btnlist : [];
    // this.selectedname = this.selected;
    // console.log(this.selectedname,'sadsadasdasd1')
    // this.btnlist = [{ type: "edit", name: "编辑", icon: "" }];
  },
  mounted() {},
  methods: {
   
  }
};
</script>

<style lang="scss">
.template1 {
  margin-bottom: 34px;
  padding: 0 10px;
  box-sizing: border-box;
  .template1-title {
    width: 100%;
    height: 85px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .infobtn {
      width: 71px;
      height: 20px;
      position: absolute;
      right: 18px;
      bottom:22px;
    }
    .template1-title-btn {
      min-width: 130px;
      padding: 0 10px;
      height: 42px;
      display: flex;
      // align-items: center;
      padding-top: 5px;
      box-sizing: border-box;
      justify-content: center;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;

      .text {
        font-family: Alibaba PuHuiTi;
        font-weight: 800;
        font-size: 20px;
        color: #ffffff;
        // text-shadow: 0rpx 2rpx 0rpx #0e8cfe,
        //   0rpx 8rpx 6rpx rgba(0, 63, 214, 0.95);
        // background: linear-gradient(0deg, #cbeaff 0.146484375%, #fefff2 100%);
        // -webkit-background-clip: text;
      }
    }
  }


  .template1-item {
    width: 136px;
    min-height: 276px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 5px;
    box-sizing: border-box;
    .image {
      width: 126px;
      height: 126px;
      margin-bottom: 9px;
      border-radius: 10px;
    }
    .titles {
      font-weight: 500;
      font-size: 18px;
      height: 50px;
      color: #333333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 11px;
      box-sizing: border-box;
    }
    .numqut {
      width: 100%;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #ee7115;
      margin: 7px 0;
      padding: 0 11px;
      box-sizing: border-box;
    }

    .num-weight-bocak {
      margin: 0 7px;

      // height: 36rpx;
      background: #f4f5f6;
      padding: 5px;
      box-sizing: border-box;
      background: #f4f5f6;
      border-radius: 5px;
      font-weight: 400;
      font-size: 13px;
      color: #333333;
    }
    .add {
      display: flex;
      align-items: center;
      margin-top: 6px;
      //   flex: 1;
      .addjia {
        width: 31px;
        height: 31px;
      }
    }
    .num-price {
      margin: 0 5px;
      margin-right: 0;
      font-weight: Bold;
      font-size: 22px;
      color: #f35314;
      flex: 1;
      .left {
        font-size: 12px;
      }
      .last {
        font-size: 22px;
      }
    }
  }

}
</style>
