var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showInfo
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "身份信息",
            visible: _vm.showInfo,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInfo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "infoWrap" }, [
            _c("div", { staticClass: "itemWrap" }, [
              _c("div", { staticClass: "cellWrap" }, [_vm._v("身份证正面")]),
              _c(
                "div",
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "imgWrap",
                      attrs: {
                        src:
                          _vm.type == 1
                            ? _vm.detailInfo.idcard_a
                            : _vm.detailInfo.old_idcard_a,
                        "preview-src-list": [
                          _vm.type == 1
                            ? _vm.detailInfo.idcard_a
                            : _vm.detailInfo.old_idcard_a,
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "cellWrap handWrap",
                  on: {
                    click: function ($event) {
                      return _vm.downLoad(
                        _vm.type == 1
                          ? _vm.detailInfo.idcard_a_download
                          : _vm.detailInfo.old_idcard_a_download
                      )
                    },
                  },
                },
                [_vm._v(" 点击下载 ")]
              ),
            ]),
            _c("div", { staticClass: "itemWrap" }, [
              _c("div", { staticClass: "cellWrap" }, [_vm._v("身份证反面")]),
              _c(
                "div",
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "imgWrap",
                      attrs: {
                        src:
                          _vm.type == 1
                            ? _vm.detailInfo.idcard_b
                            : _vm.detailInfo.old_idcard_b,
                        "preview-src-list": [
                          _vm.type == 1
                            ? _vm.detailInfo.idcard_b
                            : _vm.detailInfo.old_idcard_b,
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.detailInfo.idcard_b_download
                ? _c(
                    "div",
                    {
                      staticClass: "cellWrap handWrap",
                      on: {
                        click: function ($event) {
                          return _vm.downLoad(
                            _vm.type == 1
                              ? _vm.detailInfo.idcard_b_download
                              : _vm.detailInfo.old_idcard_b_download
                          )
                        },
                      },
                    },
                    [_vm._v(" 点击下载 ")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }