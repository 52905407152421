<template>
  <div class="recommended-products bj">
    <!-- 表单搜索 start -->
    <div class="title">
      <div class="form jn-form-style">
        <div class="form-item btn-wrap">
          <el-button
            type="primary"
            style="margin-left: 5px"
            icon="el-icon-refresh"
            class="refresh"
            @click="Refresh"
          ></el-button>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.share_member_mobile"
            placeholder="请输入推荐人手机号"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-input
            v-model="formData.share_member_name"
            placeholder="请输入推荐人姓名"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.product_name"
            placeholder="请输入推荐商品名称"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.sku_code"
            placeholder="请输入推荐商品货号"
            clearable
          ></el-input>
        </div>

        <div class="form-item">
          <el-select
            v-model="formData.source"
            clearable
            placeholder="请选择分享方式"
          >
            <!-- <el-option label="全部" value=""></el-option> -->
            <el-option label="分享" value="1"></el-option>
            <el-option label="海报" value="2"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.status"
            clearable
            placeholder="请选择购买状态"
          >
            <!-- <el-option label="全部" value=""></el-option> -->
            <el-option label="未购买" value="0"></el-option>
            <el-option label="已购买" value="1"></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.logistics_id"
            clearable
            filterable
            placeholder="请选择购买城市仓"
          >
            <el-option
              v-for="item of storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <div class="inputs">
          <el-select
            v-model="formData.share_logistics_id"
            multiple
            collapse-tags
            filterable
            clearable
            placeholder="选择推荐人所在仓"
          >
            <el-option
              v-for="item in memberlist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.purchase_username"
            placeholder="请输入购买用户姓名"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.purchase_mobile"
            placeholder="请输入购买用户电话"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.order_no"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :clearable="false"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="推荐时间起"
            end-placeholder="推荐时间止"
          >
          </el-date-picker>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.first_cid"
            clearable
            filterable
            placeholder="请选择一级品类"
          >
            <el-option
              v-for="item of CategoriesList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.cid"
            clearable
            filterable
            placeholder="请选择二级品类"
          >
            <el-option
              v-for="item of subList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-button
            @click="onHandleExport"
            type="warning"
            icon="el-icon-download"
            >导 出</el-button
          >
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column
          align="center"
          label="推荐商品名称"
          prop="product_name"
        >
        </el-table-column>
        <el-table-column align="center" label="一级品类" prop="first_cid_name">
        </el-table-column>
        <el-table-column align="center" label="二级品类" prop="categories_name">
        </el-table-column>
        <el-table-column align="center" label="推荐方式" prop="source">
          <template slot-scope="scope">
            {{ scope.row.source == 2 ? "海报" : "分享" }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="推荐时间" prop="share_time">
        </el-table-column>
        <el-table-column
          align="center"
          label="推荐人手机号"
          prop="share_member_mobile"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="推荐人姓名"
          prop="share_member_name"
        >
        </el-table-column>
        <el-table-column
          prop="share_logistics_name"
          align="center"
          label="推荐人所在仓"
        >
        </el-table-column>
        <el-table-column align="center" label="推荐结果" prop="title">
          <template slot-scope="scope">
            {{ scope.row.status == 0 ? "未购买" : "已购买" }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="购买用户"
          prop=""
        >
        <template slot-scope="scope">
            {{ scope.row.purchase_username }}({{  scope.row.purchase_mobile}})
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="订单号"
          prop="order_no"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="城市仓"
          prop="logistics_name"
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->
  </div>
</template>
<script>
import { sharelistexport } from "@/api/export/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { getSubList } from "@/api/generalControl/index.js";
import { formatDate } from "@/utils/tools/date.js";
import { getCategoriesList } from "@/api/generalControl/category";
export default {
  name: "recommended-products",
  components: {},
  data() {
    return {
      loading: false,
      pay_at: [formatDate(), formatDate()],
      formData: {
        share_member_mobile: "", //	string	分享人手机号
        share_member_name: "", //	string	分享人姓名
        product_name: "", //	string	产品名称
        sku_code: "", //	string	非必须
        source: "", //	string	来源 1分享 2海报
        status: "", //	string	状态 0未购买 1已购买
        cid: "", //	number	二级品类id
        first_cid: "", //	number	一级品类id 以下新增字段
        order_no: "", //	string	订单号
        purchase_username: "", //	string	购买人姓名
        purchase_mobile: "", //	string	购买人手机号
        logistics_id: "", //	number	城市仓id
        share_time_start: formatDate(), //	string	分享开始时间
        share_time_end: formatDate(), //	string	分享结束时间
        share_logistics_id:[],
        page: 1,
        pageSize: 50,
      }, // 表单参数
      tableData: [], // 城市仓列表
      total: 0,
      subList: [],
      CategoriesList:[],
      storelist:[],
      memberlist: [],
    };
  },
  mounted() {
    this.onInitData();
    this.citylist()
    this.getAjaxCategoriesList()
    this.getlogisticsselect()
  },
  methods: {
    getlogisticsselect() {
      this.$api.Followup.logisticsselect().then((res) => {
        this.memberlist = res.data;
      });
    },

    async getAjaxCategoriesList() {
      let { data } = await getCategoriesList();
      this.CategoriesList = data
    },
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 100000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    // 二级分类列表
    async getAjaxSubList() {
      try {
        const { data } = await getSubList();
        this.subList = data;
        console.log("ajax getAjaxSubList", data);
      } catch (err) {
        console.log("ajax getAjaxSubList err", err);
      }
    },
    payChange(val) {
      if (val) {
        this.formData.share_time_start = val[0];
        this.formData.share_time_end = val[1];
      } else {
        this.pay_at = [formatDate(), formatDate()];
        this.formData.share_time_start = formatDate();
        this.formData.share_time_end = formatDate();
      }
    },
    hqlist() {
      this.loading = true;
      this.$api.share.getsharelist(this.formData).then((res) => {
        console.log(res, "11111111111111");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    /**
     * 初始化
     */
    onInitData() {
      this.getAjaxSubList();
      this.hqlist();
    },
    onHandleSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 刷新重置
     */
    Refresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.pay_at = [formatDate(), formatDate()];
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
    /**
     * 导出
     */
    async onHandleExport() {
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 9999,
      });
      try {
        let params = this.tool.DeepCopy({}, this.formData);
        delete params.page;
        delete params.pageSize;
        const res = await sharelistexport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax err", err);
      } finally {
        this.$message.closeAll();
      }
    },
  },
};
</script>
<style lang="scss">
.recommended-products {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
