/** 是否单独配置平台服务费 */
export const PLATFORM_CONFIG_ENUM = {
  null: {
    label: "未设置",
    value: 0,
  },
  yes: {
    label: "是",
    value: 1,
  },
  no: {
    label: "否",
    value: 2,
  },
};

/** 运算符号枚举 */
export const SYMBOL_ENUM = {
  first: {
    label: "大于",
    value: 1,
  },
  second: {
    label: "大于等于",
    value: 2,
  },
  thirdly: {
    label: "小于",
    value: 3,
  },
  fourthly: {
    label: "小于等于",
    value: 4,
  },
};

/** 商品上架状态 */
export const GOODS_STATUS = {
  pending: {
    value: 1,
    label: "带审核",
  },
  success: {
    value: 2,
    label: "上架",
  },
  failed: {
    value: 3,
    label: "下架",
  },
};

/** 是否爆品 */
export const IS_HOT_SALES = {
  no: {
    value: 2,
    label: "非爆品",
  },
  yes: {
    value: 1,
    label: "爆品",
  },
};

/** 是否付费会员 */
export const MEMBER_VIP_STATUS = {
  no: {
    value: 0,
    label: "否",
  },
  yes: {
    value: 1,
    label: "是",
  },
};

/** 提现状态 */
export const IS_WITHDRAWAL = {
  yes: {
    value: 1,
    label: "正常提现",
  },
  no: {
    value: 0,
    label: "禁止提现",
  },
};

/** 打款申请状态 */
export const PAY_STATUS = {
  todo: {
    value: 1,
    label: "待审批",
  },
  pass: {
    value: 2,
    label: "通过",
  },
  fail: {
    value: 3,
    label: "拒绝",
  },
  finally: {
    value: 4,
    label: "已退回",
  },
};

/** 商品规格单位 */
export const UNIT_TYPE_ENUM = {
  piece: {
    value: 1,
    label: "件",
  },
  box: {
    value: 2,
    label: "箱",
  },
  sack: {
    value: 3,
    label: "袋",
  },
};

/** 是否有售后 */
export const IS_AFTER_SALE = {
  yes: {
    value: 1,
    label: "是",
  },
  no: {
    value: 0,
    label: "否",
  },
};

/** 售后审核状态 */
export const AUDIT_STATUS = {
  pending: {
    value: 20,
    label: "审核中",
  },
  success: {
    value: 30,
    label: "三方责任",
  },
  fail: {
    value: 40,
    label: "驳回",
  },
};

/** 采购类型 */
export const BUY_WAY_ENUM = {
  ALL: {
    value: 0,
    label: "全部",
  },
  ORIGIN: {
    value: 1,
    label: "地采",
  },
  CITY: {
    value: 2,
    label: "基采",
  },
};
/** 地采采购类型2 */
export const BUY_WAY_ENUM3 = {
  ORIGIN: {
    value: 1,
    label: "地采",
  },
};
/** 基采采购类型2 */
export const BUY_WAY_ENUM2 = {
  ORIGIN: {
    value: 2,
    label: "招商",
  },
  CITY: {
    value: 3,
    label: "买手",
  },
};

/** 品控状态 未品控 已品控 */
export const REVIEW_STATUS_ENUM = {
  default: {
    key: "default",
    value: 0,
    label: "未品控",
  },
  no: {
    key: "no",
    value: 1,
    label: "未品控",
  },
  yes: {
    key: "yes",
    value: 2,
    label: "已品控",
  },
  timeOut: {
    key: "timeOut",
    value: 3,
    label: "品控超时",
  },
  notYetPassed: {
    key: "notYetPassed",
    value: 4,
    label: "未过品控",
  },
};

/** 供应商状态枚举 */
export const SUPPLIER_STATE_ENUM = {
  0: {
    key: "0",
    value: 0,
    label: "审核中", //下拉搜索name
    tableName: "审核中", //状态字段展示
    buttonName: "审核", // 操作按钮name
  },
  1: {
    key: "1",
    value: 1,
    label: "启用",
    tableName: "启用中",
    buttonName: "禁用",
  },
  2: {
    key: "2",
    value: 2,
    label: "禁用",
    tableName: "禁用中",
    buttonName: "启用",
  },
};

/** 采购类型 */
export const AFTER_SHOW_TYPE_ENUM = {
  1: {
    value: 1,
    label: "用户",
  },
  2: {
    value: 2,
    label: "供应商",
  },
  3: {
    value: 3,
    label: "商品",
  },
};

/** 供应商/城市仓动态数据 收支类型 枚举 */
export const DYNAMIC_INCOME_TYPE_ENUM = {
  1: {
    key: "1",
    value: 1,
    label: "支出", //下拉搜索name
  },
  2: {
    key: "2",
    value: 2,
    label: "收入",
  },
};
/** 供应商营业执照认证 */
export const SUPPLIER_AUTHENTICATION_ENUM = [
  //   {
  //   value: '',
  //   label: "全部", //下拉搜索name
  // },
  {
    value: 0,
    label: "未认证", //下拉搜索name
  },
  {
    value: 1,
    label: "已认证", //下拉搜索name
  },
];
/** 供应商是否限制坑位 */
export const SUPPLIER_WHETHER = [
  {
    value: 0,
    label: "否", //下拉搜索name
  },
  {
    value: 1,
    label: "是", //下拉搜索name
  },
];
/** 采购类型 */
export const SUPPLIER_BUY_WAY_ENUM = [
  {
    value: 0,
    label: "全部",
  },
  {
    value: 1,
    label: "地采",
  },
  {
    value: 2,
    label: "招商",
  },
  {
    value: 3,
    label: "买手",
  },
];

/** 是否会员卡上传 */
export const MEMBER_CARD_TYPE = [
  {
    value: 0,
    label: "全部",
  },
  {
    value: 1,
    label: "是",
  },
  {
    value: -1,
    label: "否",
  },
];
