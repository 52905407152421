var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navbar",
      style: {
        backgroundImage: `url(${
          _vm.foundationitem.bg_url ? _vm.foundationitem.bg_url : _vm.main_url
        })`,
      },
    },
    [
      _c("i", { staticClass: "el-icon-arrow-left" }),
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }