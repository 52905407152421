/**  类型  */
export const STATE_ENUM = {
  refuse: {
    label: "拒绝",
    value: -1,
  },
  pass: {
    label: "通过",
    value: "2",
  },
};
