<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    ref="multipleTable"
    tooltip-effect="dark"
    style="width: 100%"
  >
    <el-table-column type="index" width="80" align="center" label="序号">
    </el-table-column>

    <el-table-column
      prop="logistics_name"
      align="center"
      label="城市仓"
      min-width="140"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span
          >{{ scope.row.logistics_name || "-" }}【{{
            scope.row.logistics_bank.username || "-"
          }}】</span
        >
      </template>
    </el-table-column>

    <el-table-column
      prop="delivery_name"
      align="center"
      label="自提点"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.delivery_name || "-" }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="start_date"
      align="center"
      label="扣款周期"
      min-width="100"
    >
      <template slot-scope="scope">
        <span>{{ `${scope.row.start_date} 至 ${scope.row.end_date}` }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="deduction_type_txt"
      align="center"
      label="扣款类型"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="deduction_amt"
      align="center"
      label="扣款金额"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="deduction_state_txt"
      align="center"
      label="扣款状态"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="as_no"
      align="center"
      label="售后单号"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.as_no || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="trade_no"
      align="center"
      label="退款流水号"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row?.order_refund?.trade_no || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="check_state_txt"
      align="center"
      label="审核状态"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="operate_name"
      align="center"
      label="添加人"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.operate_name || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="check_operate_name"
      align="center"
      label="审核人"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.check_operate_name || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="deduction_time"
      align="center"
      label="扣款时间"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.deduction_time || "-" }}
      </template>
    </el-table-column>

    <el-table-column label="操作" min-width="120" align="center">
      <template slot-scope="scope">
        <el-button
          @click="onHandleEdit(scope.row, 'view')"
          type="text"
          size="small"
          >查看</el-button
        >
        <!-- 有审核权限，且未审核 start -->
        <el-button
          v-if="isAudit && scope.row.check_state === CHECK_STATUS.NO.value"
          @click="onHandleEdit(scope.row, 'audit')"
          type="text"
          size="small"
          >审核</el-button
        >
        <!-- 有审核权限，且未审核 end -->

        <!-- 有审核权限，且未审核 start -->
        <el-button
          v-if="scope.row.check_state === CHECK_STATUS.NO.value"
          @click="onHandleEdit(scope.row, 'delete')"
          type="text"
          size="small"
          >作废</el-button
        >
        <!-- 有审核权限，且未审核 end -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { CHECK_STATUS } from "../../utils/enum/index";
import {
  postLogisticsDeductionAudit,
  postLogisticsDeductionCancel,
} from "@/api/general/operation-center/index";

export default {
  name: "TableList",
  props: {
    /**
     * 是否有审核权限
     */
    isAudit: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      CHECK_STATUS,
    };
  },
  methods: {
    /**
     * 操作了数据
     */
    onHandleEdit(row, type) {
      // 查看
      if (type === "view") {
        const params = {
          type,
          row,
        };
        this.$emit("on-edit", params);
      }
      const params = {
        id: row.id,
      };
      // 审核
      if (type === "audit") {
        this.$confirm("审核后立即生效，确认操作？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
          customClass: "jn-confirm-message-style",
          iconClass: "el-icon-warning",
        }).then(() => {
          this.ajaxPostLogisticsDeductionAudit(params);
        });
      }
      // 作废
      if (type === "delete") {
        this.$confirm("确定作废这条数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
          customClass: "jn-confirm-message-style",
          iconClass: "el-icon-warning",
        }).then(() => {
          this.ajaxPostLogisticsDeductionCancel(params);
        });
      }
    },
    /**
     * 审核
     */
    async ajaxPostLogisticsDeductionAudit(params) {
      try {
        await postLogisticsDeductionAudit(params);
        this.$message.success("操作成功！");
        this.$emit("on-change");
      } catch (error) {
        console.log("ajax postLogisticsDeductionAudit err", error);
      }
    },
    /**
     * 作废
     */
    async ajaxPostLogisticsDeductionCancel(params) {
      try {
        await postLogisticsDeductionCancel(params);
        this.$message.success("操作成功！");
        this.$emit("on-change");
      } catch (error) {
        console.log("ajax postLogisticsDeductionCancel err", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
