import { render, staticRenderFns } from "./template1.vue?vue&type=template&id=74944938&"
import script from "./template1.vue?vue&type=script&lang=js&"
export * from "./template1.vue?vue&type=script&lang=js&"
import style0 from "./template1.vue?vue&type=style&index=0&id=74944938&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/manage.pc.frontend-purchase-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74944938')) {
      api.createRecord('74944938', component.options)
    } else {
      api.reload('74944938', component.options)
    }
    module.hot.accept("./template1.vue?vue&type=template&id=74944938&", function () {
      api.rerender('74944938', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/attribute/template1.vue"
export default component.exports