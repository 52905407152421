var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      ref: "multipleTable",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        "tooltip-effect": "dark",
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", width: "80", align: "center", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "logistics_name",
          align: "center",
          label: "城市仓",
          "min-width": "140",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(scope.row.logistics_name || "-") +
                      "【" +
                      _vm._s(scope.row.logistics_bank.username || "-") +
                      "】"
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "delivery_name",
          align: "center",
          label: "自提点",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.delivery_name || "-"))]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "start_date",
          align: "center",
          label: "扣款周期",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(`${scope.row.start_date} 至 ${scope.row.end_date}`)
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deduction_type_txt",
          align: "center",
          label: "扣款类型",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deduction_amt",
          align: "center",
          label: "扣款金额",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deduction_state_txt",
          align: "center",
          label: "扣款状态",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "as_no",
          align: "center",
          label: "售后单号",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.as_no || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "trade_no",
          align: "center",
          label: "退款流水号",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " + _vm._s(scope.row?.order_refund?.trade_no || "-") + " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "check_state_txt",
          align: "center",
          label: "审核状态",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "operate_name",
          align: "center",
          label: "添加人",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.operate_name || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "check_operate_name",
          align: "center",
          label: "审核人",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.check_operate_name || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "deduction_time",
          align: "center",
          label: "扣款时间",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.deduction_time || "-") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "操作", "min-width": "120", align: "center" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEdit(scope.row, "view")
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
                _vm.isAudit &&
                scope.row.check_state === _vm.CHECK_STATUS.NO.value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleEdit(scope.row, "audit")
                          },
                        },
                      },
                      [_vm._v("审核")]
                    )
                  : _vm._e(),
                scope.row.check_state === _vm.CHECK_STATUS.NO.value
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleEdit(scope.row, "delete")
                          },
                        },
                      },
                      [_vm._v("作废")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }