var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.additem
    ? _c("div", { staticClass: "goodsItem" }, [
        _c("div", [
          _c("div", { staticClass: "image" }, [
            _c("img", {
              staticStyle: {
                width: "100%",
                height: "100%",
                "object-fit": "cover",
              },
              attrs: { src: _vm.additem.kv, mode: "scaleToFill", alt: "" },
            }),
          ]),
          _c("div", { staticClass: "titles" }, [
            _vm._v(" " + _vm._s(_vm.additem.title)),
          ]),
          _c("div", { staticClass: "numqut" }, [
            _vm._v(" " + _vm._s(_vm.additem.min_num) + "件起售 "),
          ]),
          _c("div", { staticClass: "num-weight-bocak" }, [
            _vm._v(
              " 净重约" +
                _vm._s(_vm.additem.weight) +
                "斤丨约￥" +
                _vm._s(_vm.additem.weight_price) +
                "/斤"
            ),
          ]),
        ]),
        _c("div", { staticClass: "add" }, [
          _c("div", { staticClass: "num-price" }, [
            _c("span", { staticClass: "num-price-left" }, [_vm._v("￥")]),
            _vm._v(" " + _vm._s(_vm.additem.price.split(".")[0])),
            _c("span", { staticClass: "num-price-last" }, [
              _vm._v("." + _vm._s(_vm.additem.price.split(".")[1])),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }