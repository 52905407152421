var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template1" }, [
    _c(
      "div",
      {
        staticClass: "template1-title",
        style: {
          backgroundImage: `url(${
            _vm.objitem.head_url
              ? _vm.objitem.head_url
              : _vm.objitemcopy.head_url
          })`,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "template1-title-btn",
            style: {
              backgroundImage: `url(${
                _vm.objitem.main_url
                  ? _vm.objitem.main_url
                  : _vm.objitemcopy.main_url
              })`,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "text",
                style: {
                  color: `${
                    _vm.objitem.btn_color
                      ? _vm.objitem.btn_color
                      : _vm.objitemcopy.btn_color
                  }`,
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.objitem.region_name
                      ? _vm.objitem.region_name
                      : "专区名称"
                  )
                ),
              ]
            ),
          ]
        ),
        _vm.objitem.more_url
          ? _c("img", {
              staticClass: "infobtn",
              attrs: { src: _vm.objitem.more_url, alt: "" },
            })
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "template1-list-wrap" }, [
      _c(
        "div",
        { staticStyle: { display: "flex", gap: "8px" } },
        _vm._l(_vm.listleft, function (item, childIndex) {
          return _c("div", { key: childIndex, staticClass: "template1-item" }, [
            _c("div", { staticClass: "image" }, [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  "object-fit": "cover",
                },
                attrs: { src: item.kv, alt: "" },
              }),
            ]),
            _c("div", { staticClass: "titles" }, [
              _vm._v(" " + _vm._s(item.title)),
            ]),
            _c("div", { staticClass: "numqut" }, [
              _vm._v(" " + _vm._s(item.min_num) + "件起售 "),
            ]),
            _c("div", { staticClass: "num-weight-bocak" }, [
              _vm._v("约￥" + _vm._s(item.gross_weight) + "/斤"),
            ]),
            _c("div", { staticClass: "add" }, [
              _c("div", { staticClass: "num-price" }, [
                _c("span", { staticClass: "left" }, [_vm._v("￥")]),
                _vm._v(" " + _vm._s(item.price.split(".")[0])),
                _c("span", { staticClass: "last" }, [
                  _vm._v("." + _vm._s(item.price.split(".")[1])),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }