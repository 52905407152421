<template>
  <div class="accountmanagement bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="onRefresh"
      ></el-button>

      <el-button type="primary" @click="addclick">新增</el-button>

      <div class="seach">
        <div class="inputs">
          <el-input
            placeholder="输入电话"
            v-model="from.mobile"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            placeholder="输入姓名"
            v-model="from.fullname"
            clearable
          ></el-input>
        </div>

        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <!-- v-loading="loading" -->
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="fullname" align="center" label="姓名">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="手机号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="login_type" align="center" label="角色">
          <template slot-scope="scope">
            {{ scope.row.login_type == 20 ? "销售" : "" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          align="center"
          label="接收售后短信通知"
          v-if="this.manageIsdentity == 'delivery'"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_sms_send"
              :width="32"
              :active-value="1"
              active-text="接收"
              active-color="#409EFF"
              :inactive-value="0"
              inactive-text="不接收"
              inactive-color="#C0CCDA"
              @change="changeSwitch(scope.row, scope.row.is_sms_send)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleDel(scope.row)"
              >删除</el-button
            >
            <el-button type="text" @click="onHandleAuth(scope.row)"
              >编辑权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          label-width="110px"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label="姓名" prop="fullname">
            <div class="inputs">
              <el-input
                v-model="ruleForm.fullname"
                placeholder="请输入姓名"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <div class="inputs">
              <el-input
                v-model="ruleForm.mobile"
                placeholder="请输入电话"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="角色">
            <div class="inputs">
              <el-input
                v-model="ruleForm.loginTypeName"
                disabled
                placeholder=""
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <div class="inputs">
              <el-radio-group v-model="ruleForm.state">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="2">禁用</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确认</el-button>
      </span>
    </el-dialog>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑权限 start -->
    <!-- @on-change="onRefresh" -->
    <edit-auth-dialog
      ref="refEditAuthDialog"
      :type="manageIsdentity"
    ></edit-auth-dialog>
    <!-- 编辑权限 end -->
  </div>
</template>
<script>
import {
  postTdcUserdel,
  postDeliveryUserdel,
  postUserSmsStatus,
} from "@/api/urbanWarehouse/index.js";
import EditAuthDialog from "./modules/edit-auth-dialog/index.vue";

export default {
  name: "accountmanagement",
  components: {
    EditAuthDialog,
  },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      centerDialogVisible: false,
      title: "新增账号",
      from: {
        fullname: "",
        mobile: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        fullname: "",
        mobile: "",
        page: 1,
        pageSize: 10,
      },
      ruleForm: {
        fullname: "", // 姓名
        mobile: "", // 手机号
        state: 1, //  状态 1:启用 2:禁用
      },
      rules: {
        fullname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        state: [{ required: true, message: "请选择状态", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { required: true, message: "请输入手机号" },
          {
            pattern:
              /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号格式",
          },
        ],
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      manageIsdentity: "",
    };
  },

  created() {
    this.manageIsdentity = sessionStorage.getItem("manageIsdentity");
    console.log(this.$api.delivery);
    this.hqlist();
  },
  methods: {
    // 修改售后短信通知
    async changeSwitch(row, state) {
      try {
        if (this.manageIsdentity == "tdc") {
          // const { data } = await postUserSmsStatus({ id });
        } else {
          const { data } = await postUserSmsStatus({
            id: row.id,
            status: state,
          });
        }
        this.onRefresh();
        this.$message({
          message: state == 1 ? "售后短信通知已开启！" : "售后短信通知已关闭",
          type: "success",
        });
        console.log("ajax postUserSmsStatus", data);
      } catch (err) {
        console.log("ajax postUserSmsStatus err", err);
      }
    },
    search() {
      this.from.page = 1;
      this.currentPage = 1;
      this.from1 = this.from;
      this.hqlist();
    },

    onRefresh() {
      this.from = {
        fullname: "",
        mobile: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        fullname: "",
        mobile: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    addclick() {
      this.ruleForm = {
        fullname: "", // 姓名
        mobile: "", // 手机号
        state: 1, //  状态 1:启用 2:禁用
      };
      this.centerDialogVisible = true;
      this.title = "新增账号";
    },
    handleEdit(row) {
      console.log(row, "row==");
      if (row.login_type == 20) {
        row.loginTypeName = "销售";
      } else {
        row.loginTypeName = "";
      }
      this.ruleForm = {
        ...row,
      };
      this.centerDialogVisible = true;
      this.title = "编辑账号";
    },
    /**
     * 编辑权限
     */
    onHandleAuth(row) {
      this.$refs.refEditAuthDialog.onInitData(row);
    },
    // 删除账号确认
    handleDel(row) {
      this.$confirm("确定要删除该账号么", "提示", {
        center: true,
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      })
        .then(() => {
          this.ajaxPostUserDel(row.id);
        })
        .catch(() => {
          this.$message.error("取消删除!");
        });
    },
    // 删除账号接口
    async ajaxPostUserDel(id) {
      try {
        if (this.manageIsdentity == "tdc") {
          const { data } = await postTdcUserdel({ id });
        } else {
          const { data } = await postDeliveryUserdel({ id });
        }
        this.onRefresh();
        this.$message.success("删除成功！");
        console.log("ajax ajaxPostUserDel", data);
      } catch (err) {
        console.log("ajax ajaxPostUserDel err", err);
      }
    },
    determine() {
      console.log(this.ruleForm);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.manageIsdentity == "tdc") {
            this.$api.tdc.usersave(this.ruleForm).then((res) => {
              if (this.title == "编辑账号") {
                this.$message({
                  type: "success",
                  message: "编辑成功",
                });
              } else {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
              }
              this.centerDialogVisible = false;
              this.onRefresh();
            });
          } else {
            this.$api.delivery.usersave(this.ruleForm).then((res) => {
              if (this.title == "编辑账号") {
                this.$message({
                  type: "success",
                  message: "编辑成功",
                });
              } else {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
              }
              this.centerDialogVisible = false;
              this.onRefresh();
            });
          }
        }
      });
    },
    hqlist() {
      this.loading = true;
      if (this.manageIsdentity == "tdc") {
        this.$api.tdc.userlist(this.from1).then((res) => {
          console.log(res, "获取数据");
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        });
      } else {
        this.$api.delivery.userlist(this.from1).then((res) => {
          console.log(res, "获取数据");
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style lang="scss">
.accountmanagement {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
</style>
