import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-供应商管理-修改提现状态
 * @param {*} params
 * @returns
 */
export function postSupplierWithdrawalStatus(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/withdrawalStatus`,
    data: params,
  });
}

/**
 * 总控-供应商管理-启用/禁用供应商
 * @param {*} params
 * @returns
 */
export function postSupplierUpdateState(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/supplierUpdateState`,
    data: params,
  });
}

/**
 * 总控-供应商管理-设置店铺
 * @param {*} params
 * @returns
 */
export function postSupplierShopName(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/shop`,
    data: params,
  });
}

/**
 * 总控-供应商管理-操作日志
 * @param {*} params
 * @returns
 */
export function getSupplierOperateLog(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/supplier/operate/log`,
    params,
  });
}

/**
 * 【供应商认证】持卡人变更列表
 * @param {*} params
 * @returns
 */
export function postSupplierBankChangeList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/bankChangeList`,
    data: params,
  });
}

/**
 * 【供应商认证】
 * @param {*} params
 * @returns
 */
export function postSupplierBankChange(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/bankChange`,
    data: params,
  });
}

/**
 * 【供应商认证】-持卡人列表导出
 * @param {*} params
 * @returns
 */
export function postSupplierBankChangeListExport(params = {}) {
  return axios({
    responseType: "blob",
    method: "POST",
    url: `${BASE_URL}/supplier/bankChangeListExport`,
    data: params,
  });
}
