var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "page-wrap" }, [
    _c("div", { staticClass: "search" }, [
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入供货单号", clearable: "" },
            model: {
              value: _vm.fromData.supplierOrderNo,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "supplierOrderNo", $$v)
              },
              expression: "fromData.supplierOrderNo",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-date-picker", {
            attrs: {
              type: "date",
              placeholder: "选择供货日期",
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
            },
            model: {
              value: _vm.fromData.deliveryTime,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "deliveryTime", $$v)
              },
              expression: "fromData.deliveryTime",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入复核单号", clearable: "" },
            model: {
              value: _vm.fromData.qcCode,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "qcCode", $$v)
              },
              expression: "fromData.qcCode",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                multiple: "",
                clearable: "",
                placeholder: "选择城市仓",
              },
              on: { change: _vm.onChangeCityStore },
              model: {
                value: _vm.fromData.storeLogistics,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "storeLogistics", $$v)
                },
                expression: "fromData.storeLogistics",
              },
            },
            _vm._l(_vm.cityStoreList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                multiple: "",
                clearable: "",
                placeholder: "选择自提点",
              },
              model: {
                value: _vm.fromData.storeDelivery,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "storeDelivery", $$v)
                },
                expression: "fromData.storeDelivery",
              },
            },
            _vm._l(_vm.selfTakeList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "选择品控员" },
              model: {
                value: _vm.fromData.reviewUserId,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "reviewUserId", $$v)
                },
                expression: "fromData.reviewUserId",
              },
            },
            _vm._l(_vm.qualityList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.fullname, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "选择品控状态" },
              model: {
                value: _vm.fromData.reviewStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "reviewStatus", $$v)
                },
                expression: "fromData.reviewStatus",
              },
            },
            _vm._l(_vm.qualityControlList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "是否品控退回" },
              model: {
                value: _vm.fromData.isReturn,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "isReturn", $$v)
                },
                expression: "fromData.isReturn",
              },
            },
            _vm._l(_vm.isReturnList, function (rItem, rIndex) {
              return _c("el-option", {
                key: rIndex,
                attrs: { label: rItem.name, value: rItem.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-date-picker", {
            attrs: {
              type: "date",
              placeholder: "选择品控日期",
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
            },
            on: { input: _vm.changeReviewTime },
            model: {
              value: _vm.fromData.reviewTime,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "reviewTime", $$v)
              },
              expression: "fromData.reviewTime",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入品名", clearable: "" },
            model: {
              value: _vm.fromData.productName,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "productName", $$v)
              },
              expression: "fromData.productName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入货号", clearable: "" },
            model: {
              value: _vm.fromData.productSkuCode,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "productSkuCode", $$v)
              },
              expression: "fromData.productSkuCode",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入供货商", clearable: "" },
            model: {
              value: _vm.fromData.supplierName,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "supplierName", $$v)
              },
              expression: "fromData.supplierName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入复核员", clearable: "" },
            model: {
              value: _vm.fromData.qcUserName,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "qcUserName", $$v)
              },
              expression: "fromData.qcUserName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-input", {
            attrs: { placeholder: "输入采购员", clearable: "" },
            model: {
              value: _vm.fromData.buyerName,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "buyerName", $$v)
              },
              expression: "fromData.buyerName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "选择是否缺货" },
              model: {
                value: _vm.fromData.outStockStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "outStockStatus", $$v)
                },
                expression: "fromData.outStockStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "全部", value: "0" } }),
              _c("el-option", { attrs: { label: "缺货", value: "1" } }),
              _c("el-option", { attrs: { label: "不缺货", value: "2" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "选择复核状态" },
              model: {
                value: _vm.fromData.qcStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.fromData, "qcStatus", $$v)
                },
                expression: "fromData.qcStatus",
              },
            },
            [
              _c("el-option", { attrs: { label: "全部", value: "0" } }),
              _c("el-option", { attrs: { label: "未复核", value: "1" } }),
              _c("el-option", { attrs: { label: "复核中", value: "3" } }),
              _c("el-option", { attrs: { label: "已复核", value: "2" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-date-picker", {
            attrs: {
              type: "date",
              placeholder: "选择复核完成日期",
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
            },
            on: { input: _vm.changeQcTime },
            model: {
              value: _vm.fromData.qcTime,
              callback: function ($$v) {
                _vm.$set(_vm.fromData, "qcTime", $$v)
              },
              expression: "fromData.qcTime",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.onHandleRefresh },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.onHandleSearch },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "warning", icon: "el-icon-upload2" },
              on: { click: _vm.onHandleExport },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-item" },
        [
          _c(
            "el-button",
            {
              staticClass: "checkButton",
              attrs: { type: "warning", icon: "el-icon-s-check" },
              on: { click: _vm.onClickReview },
            },
            [_vm._v("一键复核")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "tipText" }, [
        _vm._v(
          " 一键复核必须为品控状态为超时未送货的状态，操作一键复核之后五分钟之后复核退款完成 "
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "table-wrap" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              data: _vm.dataList,
              height: "100%",
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                width: "80",
                align: "center",
                label: "序号",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "整单复核码",
                prop: "entruckQcCode",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "单品复核码",
                prop: "skuQcCode",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "供货日期",
                prop: "deliveryDate",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "采购数量",
                prop: "purchaseNum",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "复核数量",
                prop: "qcReviewNum",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "是否缺货", prop: "isOutStock", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            Object.values(_vm.OUT_STOCK_ENUM).find(
                              (item) => item.value == scope.row.isOutStock
                            ).label
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "缺货数量", prop: "qcNum", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "缺货原因", prop: "qcReason", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "供应商", prop: "supplierName", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "品名", prop: "productName", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "品类",
                prop: "productCategoryName",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "货号", prop: "productSkuCode", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "采购员", prop: "buyerName", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "城市仓",
                prop: "storeLogistics",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "自提点",
                prop: "storeDelivery",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "品控状态",
                prop: "reviewStatus",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            Object.values(_vm.REVIEW_STATUS_ENUM).find(
                              (item) => item.value == scope.row.reviewStatus
                            ).label
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "是否品控退回",
                prop: "reviewStatus",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            Object.values(_vm.IS_RETURN_ENUM).find(
                              (item) => item.value == scope.row.isReturn
                            ).label
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "品控时间", prop: "reviewTime", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "品控员", prop: "reviewUser", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "复核状态", prop: "qcStatus", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            Object.values(_vm.IS_CHECK_ENUM).find(
                              (item) => item.value == scope.row.qcStatus
                            ).label
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "复核完成时间", prop: "qcTime", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { label: "复核员", prop: "qcUser", align: "center" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            align: "right",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.fromData.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.onHandleSizeChange,
            "current-change": _vm.onHandleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }