var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PageConfiguration" },
    [
      _c(
        "el-form",
        {
          ref: "childForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.childForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择集配中心:", prop: "logistic_business_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择集配中心" },
                  model: {
                    value: _vm.childForm.logistic_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.childForm, "logistic_business_id", $$v)
                    },
                    expression: "childForm.logistic_business_id",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模板名称:", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入模板名称",
                  maxlength: "20",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.childForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.childForm, "name", $$v)
                  },
                  expression: "childForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "整体背景色:", prop: "global_color" } },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.childForm.global_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.childForm, "global_color", $$v)
                  },
                  expression: "childForm.global_color",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题背景图：", prop: "bg_url" } },
            [
              _c("b-file-upload", {
                key: "bg_url",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.detailPicList,
                  callback: function ($$v) {
                    _vm.detailPicList = $$v
                  },
                  expression: "detailPicList",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模板主图：", prop: "main_image" } },
            [
              _c("b-file-upload", {
                key: "main_image",
                attrs: { accepts: ["jpg", "jpeg", "png"], limit: 1 },
                model: {
                  value: _vm.main_image_list,
                  callback: function ($$v) {
                    _vm.main_image_list = $$v
                  },
                  expression: "main_image_list",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }