var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "moduleView" }, [
    _c(
      "div",
      { staticClass: "moduleView-right" },
      [
        _c(
          "draggable",
          {
            staticClass: "listcnt",
            attrs: {
              options: {
                group: {
                  name: "itxst", // 可拖拽列组，相同表名可相互推拽
                  pull: "clone", // 拖拽模板物料，复制到目标列表
                  put: false,
                },
                sort: false, // 是否可推拽排序
              },
              clone: _vm.handleClone,
              animation: "300",
            },
            model: {
              value: _vm.modulelist,
              callback: function ($$v) {
                _vm.modulelist = $$v
              },
              expression: "modulelist",
            },
          },
          _vm._l(_vm.modulelist, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "itemcss",
                attrs: { "data-id": item.id },
              },
              [_vm._v(" " + _vm._s(item.title) + " ")]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }