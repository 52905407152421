<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents">
      <div v-for="(aItem, aIndex) in authList" :key="aIndex" class="mb-10">
        <div class="font-18 font-weight">{{ aItem.name }}</div>
        <el-table :data="aItem.child">
          <el-table-column prop="name" label="模块" min-width="150">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.isSelected"
                :label="scope.row.name"
                @change="onChangeModule(scope.$index, aIndex)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="child" label="权限" min-width="200">
            <template slot-scope="scope">
              <el-checkbox
                v-for="child of scope.row.child"
                :key="child.id"
                :disabled="!scope.row.isSelected"
                :label="child.name"
                v-model="child.isSelected"
                @click.native="onChangeChild(child, scope.$index, aIndex)"
              ></el-checkbox>
              <!-- <el-radio-group
              class="child-wrap"
              v-if="scope.row.child.length"
              v-model="scope.row.childId"
              :disabled="!scope.row.isSelected"
            >
              <el-radio
                v-for="child of scope.row.child"
                :label="child.id"
                :key="child.id"
                @click.native="onChangeChild(scope.row, child, scope.$index)"
              >
                {{ child.name }}
              </el-radio>
            </el-radio-group> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import {
  getTdcUserAuthorityList,
  getDeliveryUserAuthorityList,
  postTdcUserAuthoritySave,
  postDeliveryUserAuthoritySave,
} from "@/api/logisticsControl/mini-app/index.js";

export default {
  name: "EditAuthDialog",
  props: {
    /**
     * 当前操作类型
     * 城市仓 tdc，自提点 delivery
     */
    type: {
      type: String,
      default: "tdc",
    },
  },
  data() {
    return {
      dialogVisible: false,
      titleDialog: "编辑权限",
      formData: {
        id: "", // 当前用户ID
        // auth_id: "", // 当前选中的ID
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      authList: [], // 权限表
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      data && this.setFormData(data);
    },
    /**
     * 回显信息
     */
    setFormData(data) {
      this.formData.id = data.id;
      if (this.type === "tdc") {
        this.ajaxGetTdcUserAuthorityList();
      }
      if (this.type === "delivery") {
        this.ajaxGetDeliveryUserAuthorityList();
      }
    },
    /**
     * 修改了模块
     */
    onChangeModule(index, index1) {
      // 清空子项
      const list = this.authList[index1].child[index].child.map((child) => {
        return {
          ...child,
          isSelected: false,
        };
      });
      this.authList[index1].child[index].child = list;
      // console.log("🆒 onChange", index);
    },
    /**
     * 选中了子类（单选）
     */
    onChangeChild(data, index, index1) {
      // console.log("🆒 onChangeChild", data, index);
      const childList = this.authList[index1].child[index].child;
      const list = childList.map((child) => {
        // 如果不是当前选项，则重置勾选
        const isSelected = data.id === child.id ? data.isSelected : false;
        return {
          ...child,
          isSelected,
        };
      });
      this.authList[index1].child[index].child = list;
    },
    /**
     * 获取权限列表（城市仓）
     */
    async ajaxGetTdcUserAuthorityList() {
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await getTdcUserAuthorityList(params);
        this.onConvertData(data);
        console.log("ajax getTdcUserAuthorityList data", data);
      } catch (err) {
        console.log("ajax getTdcUserAuthorityList err", err);
      } finally {
        // this.onConvertData();
      }
    },
    /**
     * 获取权限列表（自提点）
     */
    async ajaxGetDeliveryUserAuthorityList() {
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await getDeliveryUserAuthorityList(params);
        this.onConvertData(data);
        console.log("ajax getDeliveryUserAuthorityList data", data);
      } catch (err) {
        console.log("ajax getDeliveryUserAuthorityList err", err);
      } finally {
        // this.onConvertData();
      }
    },
    /**
     * 转换数据
     */
    onConvertData(data = []) {
      // 模拟数据
      // const data = [
      //   {
      //     id: 1,
      //     name: "订单管理",
      //     is_selected: 1,
      //     child: [
      //       {
      //         id: 11,
      //         name: "隐藏金额信息",
      //         is_selected: 1,
      //       },
      //       {
      //         id: 12,
      //         name: "隐藏金额信息和用户信息",
      //         is_selected: 0,
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     name: "提货单",
      //     is_selected: 0,
      //     child: [
      //       {
      //         id: 21,
      //         name: "隐藏金额信息",
      //         is_selected: 0,
      //       },
      //       {
      //         id: 22,
      //         name: "隐藏金额信息和用户信息",
      //         is_selected: 0,
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     name: "分货助手",
      //     is_selected: 1,
      //     child: [],
      //   },
      //   {
      //     id: 4,
      //     name: "用户订单统计",
      //     is_selected: 1,
      //     child: [
      //       {
      //         id: 41,
      //         name: "隐藏金额信息",
      //         is_selected: 1,
      //       },
      //     ],
      //   },
      // ];
      const list = data.map((cell) => {
        // 模块勾选值转换
        // const isSelected = (cell.is_selected && true) || false;
        // 子模块单选值
        // const childFind = cell.child.find((child) => child.is_selected);
        const childList = cell.child.map((child) => {
          child.child.map((cItem) => {
            cItem.isSelected = (cItem.is_selected && true) || false;
          });
          return {
            ...child,
            isSelected: (child.is_selected && true) || false,
          };
        });
        const cellData = {
          ...cell,
          // isSelected,
          child: childList,
        };
        return cellData;
      });
      console.log(list, "111");
      this.authList = list;
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      const params = {
        ...this.formData,
      };
      // 当前选中的集合
      const selectId = [];
      this.authList.map((unit) => {
        unit.child.map((uChild) => {
          if (uChild.isSelected) {
            selectId.push(uChild.id);
          }

          const childFind = uChild.child.find((child) => child.isSelected);
          console.log(childFind, "childFind==");
          if (childFind?.id) {
            selectId.push(childFind?.id);
          }
        });
      });
      params.auth_id = selectId.join(",");
      // console.log("🆒 onHandleSubmit", params);
      if (this.type === "tdc") {
        this.ajaxPostTdcUserAuthoritySave(params);
      }
      if (this.type === "delivery") {
        this.ajaxPostDeliveryUserAuthoritySave(params);
      }
    },
    /**
     * 城市仓-编辑权限
     */
    async ajaxPostTdcUserAuthoritySave(params) {
      try {
        await postTdcUserAuthoritySave(params);
        this.$message.success("操作成功！");
        // this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postTdcUserAuthoritySave err", err);
      }
    },
    /**
     * 自提点-编辑权限
     */
    async ajaxPostDeliveryUserAuthoritySave(params) {
      try {
        await postDeliveryUserAuthoritySave(params);
        this.$message.success("操作成功！");
        // this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postDeliveryUserAuthoritySave err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  // .child-wrap {
  //   display: flex;
  //   flex-wrap: wrap;
  //   /deep/ .el-radio {
  //     // flex: 1;
  //     &__inner {
  //       border-radius: 2px;
  //     }
  //     &__input.is-checked,
  //     &__inner {
  //       &:after {
  //         position: absolute;
  //         top: 2px;
  //         left: 0px;
  //         content: "";
  //         width: 10px;
  //         height: 5px;
  //         display: block;
  //         border: 1px solid white;
  //         border-top: transparent;
  //         border-right: transparent;
  //         text-align: center;
  //         // vertical-align: middle;
  //         transform: rotate(-45deg);
  //         border-radius: 0px;
  //         background: none;
  //       }
  //     }
  //   }
  // }
}
</style>
