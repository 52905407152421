var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "decorationlist" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "div",
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addTemplate } },
            [_vm._v("添加模版")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "auto-table-flex" }, [
      _c(
        "div",
        { staticClass: "body_box" },
        _vm._l(_vm.tableData, function (item, index) {
          return _c("div", { key: index, staticClass: "body_box_item" }, [
            _c("img", {
              staticClass: "img",
              attrs: { src: item.main_image, alt: "" },
            }),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))]),
            _c("div", { staticClass: "bottom" }, [
              _c("div", { staticClass: "left" }, [
                _vm._v(_vm._s(item.create_time)),
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.editclcik(item)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteclcik(item)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }