var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualityView" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", disabled: _vm.btndisable },
          on: { click: _vm.save },
        },
        [_vm._v("保存")]
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "页面配置", name: "1" } },
            [_c("PageConfiguration", { attrs: { obj_item: _vm.obj_item } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "组件配置", name: "2" } },
            [
              _c(_vm.componentname, {
                tag: "component",
                attrs: { configuration: _vm.configuration },
                on: { quality: _vm.qualitychange },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }